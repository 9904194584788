// Material Dashboard 2 PRO React TS Base Styles
import borders from "app/assets/theme/base/borders";

const { borderRadius } = borders;
// types
type Types = any;

const dialog: Types = {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
