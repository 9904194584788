import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "app/App";
import './index.css';
import './translations/index';
import "@fontsource/open-sans";

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

i18next.init({
  interpolation: { escapeValue: false}
});

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <App />
      </I18nextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
