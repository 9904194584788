import i18n from 'i18next';


import common_en from './en/common.json';

i18n
    .init({
        react: {
            useSuspense: false
        },
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en               // 'common' is our custom namespace
            },
    },
});

export default i18n;