import { notifyToProfileStore } from "app/subscribers/SubscriberToGetPubmeds";
import { Profile } from "app/types";


const saveProfile = (profile: Profile, _state: boolean) => {
    try {
        
        if(localStorage.getItem(profile.id+ '')) {
            const {item,state} = JSON.parse(localStorage.getItem(profile.id+ ''));
            localStorage.clear();
            localStorage.setItem(profile.id + '', JSON.stringify({item: Object.assign(item,profile), state: state}));
        }
        else {
            localStorage.clear();
            localStorage.setItem(profile.id+'',  JSON.stringify({
                item: profile, state: _state}));
            }
    }
    catch (error) {
        //console.error(error);
    }
    finally {
        //notify
        const {item,state} = JSON.parse(localStorage.getItem(profile.id+ ''));
        notifyToProfileStore(item)
    }
};

const setState = (id: number, _state: boolean) => {
    try {
        const {item,state} = JSON.parse(localStorage.getItem(id + ''));
        if (item) {
            localStorage.setItem(id + '', JSON.stringify(
                { item , _state }
                ));
        }
    }
    catch (error) {
        //console.error(error);
    }
};


const storePubmeds = async (profile: Profile[]) => {
    try {
        const {item,state} = JSON.parse(localStorage.getItem(profile[0].id+ ''));
        if (item) {
            const newPubmeds = profile.flatMap((it: Profile) => it.pubmeds)
            const merge = {
                ...item,
                pubmeds: item.pubmeds? [...newPubmeds,...item.pubmeds].filter((it , index, self) => 
                self.findIndex((t) => t.publication_id === it.publication_id) === index) : newPubmeds
            }
            const state = item.pubmed_meta.all_years <= merge.pubmeds.length ;
            localStorage.setItem(profile[0].id + '', JSON.stringify(
                { item: merge, state }
                ));
        }
    }  
    catch (error) {
        //console.error(error);
    }
    finally {
        //notify
        const {item,state} = JSON.parse(localStorage.getItem(profile[0].id+ ''));
        notifyToProfileStore(item)
    }
};

const loadProfile = (id: number): {item: Profile, state:boolean} => {
    try {
        if(localStorage.getItem(id+ ''))
            return JSON.parse(localStorage.getItem(id+ ''))
        else
            return null
    }
    catch (error) {
        //console.error(error);
    }
}

const removeProfile = (id: number) => {
    try {
        localStorage.removeItem(id+ '');
    }
    catch (error) {
        //console.error(error);
    }
}


export class ProfileStore{
    static save = saveProfile;
    static setState = setState;
    static storePubmeds = storePubmeds;
    static load = loadProfile;
    static remove = removeProfile;
}


