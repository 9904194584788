import { Region } from "app/types";
import { TherapeuticArea } from "app/types/TherapeuticArea";

const SelectableParser = (
  props: Region[] | TherapeuticArea[]
): {
  name: string;
  id: number;
  order: number;
  children: { name: string; id: number }[];
}[] => {
    if(!props || props.length == 0) return null;
    if ((props[0] as Region).countries) {
        return props.map((region) => ({
            name: region.name,
            id: region.id,
            order: region.order,
            children: (region as Region).countries.map((country) => ({
                order: country.order,
                name: country.name,
                id: country.id,
            })),
        }))
    }
    if ((props[0] as TherapeuticArea).therapeuticSubAreas) {
        return props.map(therapeuticArea => ({
            name: therapeuticArea.name,
            id: therapeuticArea.id,
            order: therapeuticArea.order,
            children: (therapeuticArea as TherapeuticArea).therapeuticSubAreas.map(therapeuticSubArea => ({
                name: therapeuticSubArea.name,
                id: therapeuticSubArea.id
            }))
        }));
    }
    return null;
};

export default SelectableParser;
