import { Box, Divider } from "@mui/material";
import MDInput from "app/assets/theme/components/MDInput";
import SimpleBlogCard from "./SimpleBlogCard";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

class Cards extends React.Component<
  WithTranslation & {
    data: { columns: { [key: string]: any }[]; rows: { [key: string]: any }[] };
  },
  {
    search: string;
  }
> {
  constructor(props: any) {
    super(props);

    this.state = { search: "" };
  }

  divElement: any;

  componentDidMount(): void {}

  render() {
    return (
      <Box
        color="transparent"
        sx={{
          boxShadow: "none",

          backgroundColor: `var (---back)`,
          background: "transparent",
          marginLeft: "0",
          marginRight: "0",
          paddingRight: "0",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={0}
          sx={{
            backgroundColor: "var(--back)",
          }}
        >
          <Box
            sx={{
              fontWeight: 400,
              lineHeight: "140%",
              fontSize: "14px",
              color: "var(--gray---2)",
              marginRight: "auto",
              marginLeft: "2.2rem",
              width: "fit-content",
              //height: window.innerHeight -400,
            }}
          >
            <span>
              {this.props.t("dashboard.others.showing")}{" "}
              {this.props.data.rows.length}{" "}
            </span>
          </Box>

          <Box
            width="18.8rem"
            sx={{
              marginLeft: "auto",
              marginRight: "4rem",
            }}
          >
            <MDInput
              autoComplete="off"
              placeholder={this.props.t("dashboard.others.searchInTheTable")}
              value={this.state.search}
              sx={{
                width: "18.8rem",
                marginLeft: "-1rem",
                backgroundColor: "var(--gray----2)",
              }}
              onChange={(event: any) => {
                this.setState({ search: event.target.value });
              }}
            />
          </Box>
        </Box>
        <Divider
          sx={{
            position: "sticky",
            width: "99%",
            backgroundColor: "var(--gray---2)",
            zIndex: 2,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: "3rem",
            zIndex: 1,
            overflowX: "auto",
          }}
          ref={(divElement) => {
            this.divElement = divElement;
          }}
        >
          {this.props.data.rows?.map((row: any, index: number) => {
            if (
              this.state.search === "" ||
              row.title
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase()) !== -1 ||
              (row.conferences &&
                row.conferences.length > 0 &&
                row.conferences.find(
                  (item: any) =>
                    item.name
                      .toLowerCase()
                      .indexOf(this.state.search.toLowerCase()) !== -1
                )) ||
              row.location
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase()) !== -1
            ) {
              return (
                <Box
                  sx={{
                    //display: "flex",
                    margin: "1rem",
                    width: "18.75rem",
                    height: "400px",

                    //maxHeight: "30rem",
                  }}
                  key={index}
                >
                  <SimpleBlogCard
                    title={row.title}
                    conferences={row.conferences}
                    date={row.date}
                    location={row.location}
                    url={row.url}
                    image={"/evangeline.jpg"}
                  />
                </Box>
              );
            }
            return null;
          })}
        </Box>
      </Box>
    );
  }
}

export default withTranslation("common")(Cards);
