import { useEffect, useMemo, useRef, useState } from "react";
// react-table components
import {
  useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React TS components
import { Box, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import DataTableBodyCell from "./DataTableBodyCell";
import DataTableHeadCell from "./DataTableHeadCell";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Declaring props types for DataTable
interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  canSearch?: boolean;
  link?: boolean;
  showTotalEntries?: boolean;
  infiniteScroll?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  pagination?: {
    variant: "contained" | "gradient";
    color:
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "error"
      | "dark"
      | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  linkKey?: string;
  contacts?: boolean;
  moreRows?: Function;
  sortBy?: Function;
  totalEntries?: number;
  scrollTop?: boolean;
  tableWidth?: string;
  metricsPanel?: JSX.Element;
}

function DataTable({
  entriesPerPage,
  link,
  linkKey,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  contacts,
  infiniteScroll,
  moreRows,
  sortBy,
  totalEntries,
  scrollTop,
  canSearch,
  tableWidth,
  metricsPanel,
}: Props): JSX.Element {
  let defaultValue: any;
  let entries: any[];

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue
      ? entriesPerPage.defaultValue
      : 30;
    entries = entriesPerPage.entries
      ? entriesPerPage.entries
      : ["10", "25", "50", "100"];
  }

  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: {  } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = tableInstance;
  const ref = useRef(null);
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 30), [defaultValue]);
  useEffect(() => setPageSize(Math.max(table.rows.length, 1)), [table]);
  const navigate = useNavigate();

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => setPageSize(value);

  // Render the paginations

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
      sortBy(column.id, sortedValue);
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  // if scrolling to the end row emit loadmore event
  const loadMore = (event: any) => {
    if (infiniteScroll) {
      const scrollPosition = event.target.scrollTop;
      const reactTableHeight = event.target.scrollHeight;
      if (scrollPosition + 2 * window.innerHeight >= reactTableHeight) {
        moreRows();
      }
    }
  };

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const { t } = useTranslation("common");
  const element = useRef<HTMLTableElement>(null);
  const [size, setSize] = useState(0);
  const maxHeightPossible = () => {
    setSize(
      (Number(window.screen.height) - Number(element.current?.offsetTop)) * 0.85
    );
  };
  window.addEventListener("resize", maxHeightPossible);
  return (
    <TableContainer
      color="transparent"
      sx={{
        //backgroundColor: `var(---back)`,
        background: "transparent",
        marginLeft: "-15px",
        marginRight: "0",
        paddingRight: "0",
        width: "95%",
      }}
    >
      {showTotalEntries ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={0}
          sx={{
            //backgroundColor: "var(--back)",
          }}
        >
          <Box
            sx={{
              fontWeight: 400,
              lineHeight: "140%",
              fontSize: "14px",
              color: "var(--primary--600) !important",
              marginRight: "auto",
              marginLeft: "2.2rem",
            }}
          >
            <span>
              {t("dashboard.others.showing")} {totalEntries ?? rows.length}{" "}
              {contacts ? t("dashboard.others.contacts") : ""}
            </span>
          </Box>

          {canSearch ? (
            <Box
              width="18.75rem"
              sx={{
                marginLeft: "auto",
                marginRight: "4rem",
              }}
            >
              <TextField
              InputProps={{
                startAdornment: (
                  <img
                    src="./icons/search-icon.svg"
                    style={{
                      color: "var(--primary--600)",
                      marginRight: '0.5rem'
                    }}
                  />
                ),
              }}
                placeholder={t("dashboard.others.searchInTheTable")}
                value={search}
                sx={{ 
                  width: "18.75rem",
                  background: 'var(--white)',
                  color: 'var(--neutral--500)'
              }}
                onChange={({ currentTarget }: any) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </Box>
          ) : null}
        </Box>
      ) : null}
      
      <Box
        sx={{
          height:
            size > 0
              ? size
              : (Number(window.screen.height) -
                  Number(element.current?.offsetTop)) *
                0.85,
          marginTop: "1rem",
          overflowY: "auto",
          overflowX: "hidden",
          maxWidth: "99%",
          width: "98%",
          marginLeft: "1rem",
          display: "flex",
          flexDirection: "row",
          boxShadow: 'none',
          "&::-webkit-scrollbar:horizontal": {
            height: 0,
            width: 0,
            display: "none",
          },
          "&::-webkit-scrollbar-thumb:horizontal": {
            display: "none",
          },
        }}
        onScroll={(event) => loadMore(event)}
        ref={element}
      >
        <Table
          {...getTableProps()}
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0px 12px",
            marginX: "1rem",
            width: "97%",
            marginTop: "0rem",
            height: "min-content",
            paddingBottom: "2rem",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: "0",

              borderTop: "1px solid var(--gray----2)",
              backgroundColor: "var(--back)",
              height: "4rem",
              //marginTop: '-4rem',
              border: "4px solid var(--back)",
              zIndex: "1",
            }}
            component="thead"
          >
            {headerGroups.map((headerGroup: any) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                sx={{
                  backgroundColor: "var(--back)",
                  top: "1rem",
                  marginTop: "auto",
                  padding: "0.5rem",
                  margin: "0.5rem",
                  //display: 'contents'
                }}
              >
                {headerGroup.headers.map((column: any) => (
                  <DataTableHeadCell
                    sx={{
                      //borderBottom: "1px solid #e0e0e0",
                      //borderRight: "1px solid #e0e0e0",
                      //borderLeft: "1px solid #e0e0e0",
                      //display: "block",
                      width: "1rem",
                    }}
                    {...column.getHeaderProps(
                      isSorted && column.getSortByToggleProps()
                    )}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </Box>

          <TableBody {...getTableBodyProps()} sx={{}}>
            {page.map((row: any, key: any) => {
              prepareRow(row);
              return link ? (
                linkKey ? (
                  <TableRow
                    {...row.getRowProps()}
                    onClick={() => {
                      window.open(`${row.original[linkKey]}`,'_blank');
                    }}
                    ref={row.id == 1 ? ref : null}
                    sx={{
                      margin: "1rem",
                      //borderBottom: "1px solid #e0e0e0",
                      paddingLeft: "0.5rem",
                      cursor: "pointer",
                      color: "var(--gray---1)",
                      boxShadow:
                        "0px 0px 10px rgba(84, 26, 195, 0.05), 0px 0px 9px rgba(84, 26, 195, 0.01), 0px 0px 8px rgba(84, 26, 195, 0.04), 0px 0px 6px rgba(84, 26, 195, 0.06), 0px 0px 3px rgba(84, 26, 195, 0.07), 0px 0px 0px rgba(84, 26, 195, 0.07)",
                      borderRadius: "0.6rem",
                      "&:hover": {
                        boxShadow:
                        "0px 0px 10px rgba(84, 26, 195, 0.1), 0px 0px 9px rgba(84, 26, 195, 0.1), 0px 0px 8px rgba(84, 26, 195, 0.1), 0px 0px 6px rgba(84, 26, 195, 0.1), 0px 0px 3px rgba(84, 26, 195, 0.1), 0px 0px 0px rgba(84, 26, 195, 0.1)",
          
                      },
                    }}
                  >

                      {row.cells.map((cell: any, index: number) => (
                        <DataTableBodyCell
                          className="label"
                          sx={{
                            borderBottom: "1px solid #e0e0e0",
                            borderRadius: "0.5rem",
                          }}
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          left={index === 0}
                          right={index === row.cells.length - 1}
                          rows={row.cells.length}
                          row_width={cell.column.width}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </DataTableBodyCell>
                      ))}

                  </TableRow>
                ) : (
                  <TableRow
                    {...row.getRowProps()}
                    ref={row.id == 1 ? ref : null}
                    onClick={() => {
                      window.open(`${row.original.id}`,'_blank');
                    }}
                    sx={{
                      margin: "1rem",
                      boxShadow:
                        "0px 0px 10px rgba(84, 26, 195, 0.05), 0px 0px 9px rgba(84, 26, 195, 0.01), 0px 0px 8px rgba(84, 26, 195, 0.04), 0px 0px 6px rgba(84, 26, 195, 0.06), 0px 0px 3px rgba(84, 26, 195, 0.07), 0px 0px 0px rgba(84, 26, 195, 0.07)",
                      borderRadius: "0.6rem",
                      //border: '5px solid var(--back)',
                      cursor: "pointer",
                      paddingLeft: "0.5rem",
                      color: "var(--gray---1)",
                      width: "80%",
                      padding: "2rem",
                      marginX: "-1rem",
                      "&:hover": {
                        boxShadow:
                        "0px 0px 10px rgba(84, 26, 195, 0.1), 0px 0px 9px rgba(84, 26, 195, 0.1), 0px 0px 8px rgba(84, 26, 195, 0.1), 0px 0px 6px rgba(84, 26, 195, 0.1), 0px 0px 3px rgba(84, 26, 195, 0.1), 0px 0px 0px rgba(84, 26, 195, 0.1)",
          
                      },
                    }}
                  >

                    
                    {row.cells.map((cell: any, index: number) => (
                      <DataTableBodyCell
                        className="label"
                        sx={{
                          borderBottom: "1px solid #e0e0e0",
                        }}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        left={index === 0}
                        right={index === row.cells.length - 1}
                        rows={row.cells.length}
                        row_width={cell.column.width}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                )
              ) : (
                <TableRow
                  {...row.getRowProps()}
                  sx={{
                    margin: "1rem",
                    boxShadow:
                      "0px 0px 10px rgba(84, 26, 195, 0.05), 0px 0px 9px rgba(84, 26, 195, 0.01), 0px 0px 8px rgba(84, 26, 195, 0.04), 0px 0px 6px rgba(84, 26, 195, 0.06), 0px 0px 3px rgba(84, 26, 195, 0.07), 0px 0px 0px rgba(84, 26, 195, 0.07)",
                    borderRadius: "0.6rem",
                    borderBottom: "1px solid #e0e0e0",
                    padding: "0.5rem",
                    color: "var(--gray---1)",
                    "&:hover": {
                      boxShadow:
                      "0px 0px 10px rgba(84, 26, 195, 0.1), 0px 0px 9px rgba(84, 26, 195, 0.1), 0px 0px 8px rgba(84, 26, 195, 0.1), 0px 0px 6px rgba(84, 26, 195, 0.1), 0px 0px 3px rgba(84, 26, 195, 0.1), 0px 0px 0px rgba(84, 26, 195, 0.1)",
        
                    },
                  }}
                >
                  {row.cells.map((cell: any, index: number) => (
                    <DataTableBodyCell
                      ref={index == 0 ? ref : null}
                      className="label"
                      sx={{
                        borderBottom: "1px solid #e0e0e0",
                        borderRadius: "5px",
                      }}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      left={index === 0}
                      right={index === row.cells.length - 1}
                      rows={row.cells.length}
                      row_width={cell.column.width}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {metricsPanel ? metricsPanel : null}
      </Box>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  totalEntries: null,
  pagination: { variant: "gradient", color: "info" },
  isSorted: false,
  noEndBorder: false,
  link: false,
  tableWidth: "99%",
  moreRows: () => {},
  sortBy: () => {},
};

export default DataTable;
function useHistory() {
  throw new Error("Function not implemented.");
}
