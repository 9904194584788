import { useRef, useEffect, useState, useMemo, ReactNode } from "react";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 PRO React TS Helper Functions
import gradientChartLine from "app/assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
import configs from "./configs";

// Material Dashboard 2 PRO React TS Base Styles
import colors from "app/assets/theme/base/colors";

// Declaring props types for GradientLineChart
interface Props {
  icon?: {
    color?: string;
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  width?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      color: string;
      data: number[];
      backgroundColor: string;
    }[];
  };
  [key: string]: any;
}

function GradientLineChartFollowers({
  icon,
  title,
  description,
  height,
  chart,
  width
}: Props): JSX.Element {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const { data, options }: any = chartData;

  useEffect(() => {
    const chartDatasets = chart.datasets
      ? chart.datasets.map((dataset) => ({
          ...dataset,
          tension: 0,
          pointRadius: 1,
          pointColor: dataset.color,
          fillColor: dataset.color,
          strokeColor: dataset.color,
          pointStrokeColor: dataset.color,
          pointHighlightFill: dataset.color,
          pointHighlightStroke: dataset.color,
          pointBackgroundColor: dataset.color,
          borderWidth: 1,
          borderColor: dataset.color,
          fill: true,
          maxBarThickness: 6,
          backgroundColor: gradientChartLine(
            chartRef.current.children[0],
            dataset.backgroundColor
          ),
        }))
      : [];

    setChartData(configs(chart.labels || [], chartDatasets));
  }, [chart]);

  const renderChart = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: height || "100%",
      }}
    >
      {title || description ? (
        <Box display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <Box
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </Box>
          )}
          <Box mt={icon.component ? -2 : 0}>
            {title && <Typography variant="h6">{title}</Typography>}
            <Box mb={2}>
              <Typography component="div" variant="button" color="text">
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {useMemo(
        () => (
          <Box
            ref={chartRef}
            sx={{
              height,
              width,
              marginX: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Line data={data} options={options} />
          </Box>
        ),
        [chartData, height, width]
      )}
    </Box>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Declaring default props for GradientLineChart
GradientLineChartFollowers.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  width: "100%",
};

export default GradientLineChartFollowers;
