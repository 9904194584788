import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box, FormControl, MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import VerticalBarChart from "./BarCharts/VerticalBarChart";


class OverviewPanel extends React.Component<
  WithTranslation & {
    data: {
        clinicalData: { [key: string]: any }[]
    };
  },
  {
    selected: string;
    open: boolean;
    data: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            color: string;
            status: string;
        }[];
    }
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected: "10",
      open: false,
      data: this.parseClinicalData(props.data.clinicalData),
    };
  }

  parseClinicalData = (data: { [key: string]: any }[]) => {
    const labels = data.map((row) => new Date(row.date.match(/(\d+)/g)[2], row.date.match(/(\d+)/g)[1]-1, row.date.match(/(\d+)/g)[0]).getFullYear()+ "").filter((item,index, arr)  => arr.indexOf(item) === index ).sort();
    let datasets = [
        {
            label: this.props.t("profile.clinicalTrials.chart.notYetRecruiting"),
            data: labels.map(item => 0),
            color: "#33BE69",
            status: this.props.t("profile.clinicalTrials.chart.notYetRecruiting")
        },
        {
            label: this.props.t("profile.clinicalTrials.chart.recruiting"),
            data: labels.map(item => 0),
            color: "#713F93",
            status: `${this.props.t("profile.clinicalTrials.chart.recruiting")} 
              ${this.props.t("profile.clinicalTrials.chart.enrollingByInvitation")} 
              ${this.props.t("profile.clinicalTrials.chart.activeNotRecruiting")}` 
        },
        {
            label: this.props.t("profile.clinicalTrials.chart.completed"),
            data: labels.map(item => 0),
            color: "#00AEEF",
            status: this.props.t("profile.clinicalTrials.chart.completed")
        },
        {
            label: this.props.t("profile.clinicalTrials.chart.failed"),
            data: labels.map(item => 0),
            color: "#FF9E18",
            status: `${this.props.t("profile.clinicalTrials.chart.suspended")}
              ${this.props.t("profile.clinicalTrials.chart.terminated")}
              ${this.props.t("profile.clinicalTrials.chart.withdrawn")}`
        }
    ]
    data.map((row) => {
        const year = new Date(row.date.match(/(\d+)/g)[2], row.date.match(/(\d+)/g)[1]-1, row.date.match(/(\d+)/g)[0]).getFullYear();
        const index = labels.indexOf(year+ "");
        if(row.status_id != -1) {
            datasets[row.status_id].data[index]++;
        }
    });
    return { labels , datasets };
}

 
  handleChange = (event: SelectChangeEvent) => {
    this.setState({ selected: event.target.value });
  };

  renderMenu = () => (
    <FormControl sx={{ m: 1, width: "5.45rem" }}>
      <Select
        sx={{
          height: "2rem",
          backgroundColor: "var(--light-gray---1)",
          justifyContent: "flex-end",
          padding: "0.2rem",
        }}
        input={<OutlinedInput />}
        renderValue={(event) => {
          return (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {event} YRS
              {this.state.open ? (
                <KeyboardArrowUpIcon sx={{ cursor: "" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} />
              )}{" "}
            </Box>
          );
        }}
        displayEmpty
        value={this.state.selected}
        onChange={this.handleChange}
      >
        <MenuItem value={10}>10 YRS</MenuItem>
        {/* <MenuItem value={5}>5 YRS</MenuItem>
        <MenuItem value={3}>3 YRS</MenuItem> */}
      </Select>
    </FormControl>
  );

  render() {
    const { t } = this.props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "max-content",
          maxWidth: "30rem",
          marginLeft: "2rem",
        }}
      >
          {/* <Typography sx={{
              marginTop: "1rem",
              fontWeight: 400,
              lineHeight: '140%',
              fontSize: '14px',
              color: 'var(--gray---2) !important',
              marginRight: 'auto',
              marginLeft: '1rem',
              marginBottom: '0.5rem',
          }}>
                {t("profile.overview.metrics")}
            </Typography> */}
         {/* <Divider sx={{
           marignLeft: "0",
          marginTop: "3.74rem",
            width: "100%",
            backgroundColor: 'var(--gray---2)',
         }}/>   */}
        <Box
          sx={{
            marginTop: "5rem",
            display: "flex",
            flexDirection: "column",
            width: "19rem",
            height: "18.75rem",
            color: "var(--white)",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.15)",
            borderRadius: "0.5rem",
            padding: "1rem",
          }}
        >
            
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "fit-content",
            }}
          >
            <Typography
              sx={{
                color: "var(--gray---2)",
                fontSize: "0.75rem",
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              {t("profile.overview.clinicalTrials")}
            </Typography>
            {this.renderMenu()}
          </Box>
          <Box sx={{}}>
            <VerticalBarChart
            height={'10rem'}
            wight={'13rem'}
              chart={
                this.state.data
              }
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withTranslation("common")(OverviewPanel);
