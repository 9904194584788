import {  url,  checkStatus, header_options } from "./ProviderHelpers";
const axios  = require('axios');

const getFile = async (source: string) => {
    try {
        const response = await axios.get(`${url}${source}`, {
            responseType: "blob",
            ...header_options,
        });
        return response.data;
    }
    catch (error: any) {
        await checkStatus(error.response);
    }
}

const updateFile = async (source: string, file: any) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const options = {
            ...header_options,
        }
        const response = await axios.post(`${url}/user/upload`, {data: formData}, {
            ...header_options,
            'Content-Type': 'multipart/form-data',
        });
        return response.data;
    }
    catch (error: any) {
        //await checkStatus(error.response);
    }
}

class FileProvider {
    public static getFile = getFile;
    public static updateFile = updateFile;
}

export default FileProvider;