import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Box, maxWidth } from "@mui/system";
import { Company } from "app/types/Company";
import { Keyword } from "app/types/Keyword";
import { TwitterProfile } from "app/types/TwitterProfile";
import GradientLineChart from "app/components/Profile/GradientLineChart";
import PieChart from "./PieChart";
import { wrap } from "module";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Timeline } from "react-twitter-widgets";
import NoData from "../Load/NoData";
import { Linkedin } from "app/types/Linkedin";
import { LinkedinPosts } from "app/types/LinkedinPosts";
import { LinkedInEmbed } from "react-social-media-embed";
import { LinkedinKeyword } from "app/types/LinkedinKeyword";
import GradientLineChartFollowers from "./GradientLineChartFollowers";

class SocialTab extends React.Component<
  WithTranslation & {
    data: Linkedin;
  },
  {
    followersData: {
      labels: string[];
      data: number[];
    };
    keywordData: { [key: string]: number };
    colors: string[];
    selectedYear: number;
    selectedFollowsYear: number;
    engagementData: {
      labels: string[];
      data: number[];
      extradata: {
        [key: string]: {
          likes: number;
          reposts: number;
          comments: number;
          newfollowers: number;
          engagement: number;
        };
      };
    };
    size: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      followersData: {
        labels: [],
        data: [],
      },
      engagementData: {
        labels: [],
        data: [],
        extradata: {},
      },
      size: window.screen.height,
      selectedYear: new Date().getFullYear(),
      selectedFollowsYear: new Date().getFullYear(),
      keywordData: {} as { [key: string]: number },
      colors: [
        "rgba(51, 190, 105, 1)",
        "rgba(113, 63, 147, 1)",
        "rgba(0, 174, 239, 1)",
        "rgba(255, 158, 24, 1)",
        "rgba(51, 144, 156, 1)",
        "rgb(156, 51, 60)",
        "rgb(147, 76, 63)",
        "rgb(228, 24, 255)",
        "rgba(0, 53, 149, 1)",
        "rgba(155, 26, 47, 1)",
      ],
    };
  }

  componentDidMount() {
    const keys = this.props.data.posts
      .map((post) => post.keywords ?? null)
      .flat()
      .filter((item) => item !== null);
    this.keywordParser(keys);
    this.followersEvolutionParserDefault(this.props.data.followers);
    this.engagementDataParser(this.props.data.engagement);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.data.followers !== this.props.data.followers) {
      this.followersEvolutionParserDefault(this.props.data.followers);
    }

    if (prevProps.data.posts !== this.props.data.posts) {
      const keys = this.props.data.posts
        .map((post) => post.keywords ?? null)
        .flat()
        .filter((item) => item !== null);
      this.keywordParser(keys);
    }

    if (prevProps.data.engagement !== this.props.data.engagement) {
      this.engagementDataParser(this.props.data.engagement);
    }

    if (prevState.selectedFollowsYear !== this.state.selectedFollowsYear) {
      if (this.state.selectedFollowsYear !== new Date().getFullYear()) {
        this.followersEvolutionParserYearDefault(this.props.data.followers);
      } else {
        this.followersEvolutionParserDefault(this.props.data.followers);
      }
    }
  }

  handleChangeYear = (event: any) => {
    this.setState({
      selectedYear: event.target.value,
    });
  };

  renderMenuYear = () => (
    <FormControl sx={{ m: 1, minWidth: 80, height: "2rem" }} size="small">
      <Select
        sx={{
          height: "100%",
          color: "var(--primary----blue)",
        }}
        input={<OutlinedInput />}
        value={this.state.selectedYear}
        label="Year"
        onChange={this.handleChangeYear}
        displayEmpty
        renderValue={() =>
          this.state.selectedYear == new Date().getFullYear()
            ? this.props.t("profile.social.currentYear")
            : this.state.selectedYear
        }
      >
        <MenuItem value={new Date().getFullYear()}>
          {this.props.t("profile.social.currentYear")}
        </MenuItem>
        {Array.apply(0, Array(2)).map((_: any, index: number) => {
          const year = new Date();
          year.setFullYear(year.getFullYear() - 1 - index);
          return (
            <MenuItem value={year.getFullYear()}>{year.getFullYear()}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  engagementDataParser = (data: {
    [key: string]: {
      likes: number;
      reposts: number;
      comments: number;
      newfollowers: number;
      engagement: number;
    };
  }) => {
    //get last label with data and get the month
    const lastLabel = Object.keys(data).length ? Object.keys(data).reduce((a, b) => {
      const aMonth = Number(a.split("-")[1]);
      const bMonth = Number(b.split("-")[1]);
      return aMonth > bMonth ? a : b;
    }) : "0-0";
    const lastMonth =  Number(lastLabel.split("-")[1]);

    const labels: string[] = Array.apply(0, Array(lastMonth))
      .map((_: any, index: number) => {
        const month = new Date()
        month.setMonth(lastMonth - index);
        return month.toLocaleString("en-US", { month: "short" });
      })
      .reverse();
    const dataEngagement: number[] = labels
      .map((label) => {
        const month = new Date();
        month.setMonth(lastMonth - labels.indexOf(label));
        const key = `${month.getFullYear()}-${month.getMonth()}`;
        return data[key] ? data[key].engagement * 100 : 0;
      })
      .reverse();

    this.setState({
      engagementData: {
        labels: labels,
        data: dataEngagement,
        extradata: data,
      },
    });
  };

  followersEvolutionParserDefault = (
    followers_evolution: {
      id: number;
      nbr_followers: number;
      date: string;
    }[]
  ) => {

    const lastMonth = followers_evolution.length > 0 ? new Date(followers_evolution?.reduce((a, b) => {
      const aMonth = Date.parse(a.date);
      const bMonth = Date.parse(b.date);
      return aMonth > bMonth ? a : b;
    }).date) : new Date("2023-01-01") ;

    const dates: { [key: string]: string }[] = Array.apply(
      0,
      Array(lastMonth.getMonth())
    )
      .map((_: any, index: number) => {
        const month = new Date();
        month.setMonth(lastMonth.getMonth() - index );
        return {
          [month.toLocaleString("en-US", { month: "short" })]:
            Number(month.getMonth()) + "-" + month.getFullYear(),
        };
      })
      .reverse();
    const labels: string[] = dates.map(
      (date: { [key: string]: string }) => Object.keys(date)[0]
    );
    //first value not null
    let lastValue = 0;
    const data: number[] = dates.map((date) => {
      const yearMonth = Object.values(date)[0];
      lastValue =
        followers_evolution?.find((it) => {
          const ev = new Date(it.date);
          return ev.getMonth()  + "-" + ev.getFullYear() === yearMonth;
        })?.nbr_followers || lastValue;
      return lastValue;
    });

    this.setState({
      followersData: {
        labels: labels,
        data: data,
      },
    });
  };

  followersEvolutionParserYearDefault = (
    followers_evolution: {
      id: number;
      nbr_followers: number;
      date: string;
    }[]
  ) => {
    const dates: { [key: string]: string }[] = Array.apply(0, Array(12))
      .map((_: any, index: number) => {
        const month = new Date(this.state.selectedFollowsYear + "");
        month.setMonth(month.getMonth() - index);
        return {
          [month.toLocaleString("en-US", { month: "short" })]:
            Number(month.getMonth()) + 1 + "-" + month.getFullYear(),
        };
      })
      .reverse();
    const labels: string[] = dates.map(
      (date: { [key: string]: string }) => Object.keys(date)[0]
    );
    let lastValue = 0;
    const data: number[] = dates.map((date: { [key: string]: string }) => {
      lastValue =
        followers_evolution?.find((it) => it.date === date[0])?.nbr_followers ||
        lastValue;
      return (
        followers_evolution?.find((it) => it.date === date[0])?.nbr_followers ||
        lastValue
      );
    });
    this.setState({
      followersData: {
        labels: labels,
        data: data,
      },
    });
  };

  handleChangeFollowsYear = (event: any) => {
    this.setState({
      selectedFollowsYear: event.target.value,
    });
  };

  renderMenuFollowsYear = () => (
    <FormControl sx={{ m: 1, minWidth: 80, height: "2rem" }} size="small">
      <Select
        sx={{
          height: "100%",
          color: "var(--primary----blue)",
        }}
        input={<OutlinedInput />}
        value={this.state.selectedFollowsYear}
        label="Year"
        onChange={this.handleChangeFollowsYear}
        displayEmpty
        renderValue={() =>
          this.state.selectedFollowsYear == new Date().getFullYear()
            ? this.props.t("profile.social.currentYear")
            : this.state.selectedFollowsYear
        }
      >
        <MenuItem value={new Date().getFullYear()}>
          {this.props.t("profile.social.currentYear")}
        </MenuItem>
        {Array.apply(0, Array(2)).map((_: any, index: number) => {
          const year = new Date();
          year.setFullYear(year.getFullYear() - 1 - index);
          return (
            <MenuItem value={year.getFullYear()}>{year.getFullYear()}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  keywordParser = (keywords: LinkedinKeyword[]) => {
    let data: any = {};
    keywords.forEach((keyword: LinkedinKeyword) => {
      if (!data[keyword.value]) {
        data[keyword.value] = 1;
      } else {
        data[keyword.value] += 1;
      }
      let items: [string, number][] = Object.keys(data).map(function (key) {
        return [key, data[key]];
      });
      items
        .sort(function (first, second) {
          return second[1] - first[1];
        })
        .slice(0, 20)
        .map((item: [string, number]) => {
          this.state.keywordData[item[0]] = item[1];
        });
    });
  };

  render() {
    return (
      <Box
        margin={0}
        height={"inherit"}
        //maxHeight={"800px"}

        sx={{
          overflowY: "auto",
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
          //height: "fit-content",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "28px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "auto",
            height: "auto",
            marginTop: "0rem",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: "var(--white)",
              boxShadow: "1px 1px 4px rgba(0,0,0,0.15)",
              borderRadius: "0.5rem",
              width: "646px",
              //marginTop: "1rem",
              flexDirection: "column",
              paddingX: "20px",
              paddingY: "28px",
              height: "628px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                color: "var(--primary----dark-blue)",
                fontSize: "14px",
                textTransform: "uppercase",
                fontWeight: "600",
                lineHeight: "20px",
              }}
            >
              {this.props.t("profile.social.publicationsSocialNetworks")}
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                display: "flex",
                color: "var(--gray---1)",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              {this.props.t("profile.social.engagementExplanation")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: "50px",
              }}
            >
              <GradientLineChart
                height={"480px"}
                width={"580px"}
                chart={{
                  labels: this.state.engagementData.labels,
                  datasets: [
                    {
                      label: "",
                      data: this.state.engagementData.data,
                      extradata: this.state.engagementData.extradata,
                      yearSelected: this.state.selectedYear,
                      color: "rgba(51, 190, 105, 1)",
                      backgroundColor: "rgba(51, 190, 105, 0.27)",
                    },
                  ],
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "auto",
            gap: "28px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: "var(--white)",
              boxShadow: "1px 1px 4px rgba(0,0,0,0.15)",
              width: "310px",
              height: "300px",
              borderRadius: "8px",
              flexDirection: "column",
              overflow: "hidden",
              paddingY: "23px",
              paddingX: "20px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                color: "var(--primary----dark-blue)",
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {this.props.t("profile.social.keywords")}
            </Typography>
            <Box
              sx={{
                marginTop: "32px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "baseline",
                flexWrap: "wrap",
                lineHeight: 1,
                overflow: "hidden",
              }}
            >
              {this.state.keywordData ? (
                Object.keys(this.state.keywordData).map(
                  (keyword: string, index: number) => {
                    const count = this.state.keywordData[keyword];
                    if (
                      Object.keys(this.state.keywordData).findIndex(
                        (key: string) => key === keyword
                      ) !== index
                    )
                      return null;
                    if (!count) return null;
                    return (
                      <Typography
                        sx={{
                          margin: "0rem",
                          lineHeight: 1.3,
                          color:
                            index % 2 === 0
                              ? "var(--primary----blue)"
                              : "var(--secondary-1)",
                          fontSize:
                            Math.max(Math.min(8 * count, 24), 12) + "px",
                        }}
                      >
                        {keyword}
                      </Typography>
                    );
                  }
                )
              ) : (
                <NoData />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              backgroundColor: "var(--white)",
              boxShadow: "1px 1px 4px rgba(0,0,0,0.15)",
              width: "310px",
              height: "300px",
              borderRadius: "0.5rem",
              flexDirection: "column",
              marginBottom: "auto",
              paddingX: "20px",
              paddingY: "23px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                heigth: "auto",
                flexDirection: "row",
                marginBottom: "36px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  color: "var(--primary----dark-blue)",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {this.props.t("profile.social.followers")}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                height: "160px",
                marginY: "auto",
              }}
            >
              <GradientLineChartFollowers
                height={"220px"}
                width={"260px"}
                chart={{
                  labels: this.state.followersData.labels,
                  datasets: [
                    {
                      label: "",
                      color: "rgba(255, 168, 0, 1)",
                      backgroundColor: "rgba(255, 168, 0, 0.04)",
                      data: this.state.followersData.data,
                    },
                  ],
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withTranslation("common")(SocialTab);
