function configs(labels: any, datasets: any) {
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: {
      animation: {
        duration: 0
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      hover: {
        mode: 'none'
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "none",
      },
      scales: {
        y: {
          min: 0,
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [2, 2],
          },
          ticks: {
            display: true,
            padding: 8,
            color: "#3E454F",
            font: {
              size: 12,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [0, 0],
          },
          ticks: {
            display: true,
            padding: 8,
            color: "#3E454F",
            font: {
              size: 12,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
