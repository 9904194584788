import {
  Box, CircularProgress,
  debounce,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import ProfileParser from "app/helpers/ProfileParser";
import ParametersProvider from "app/services/ParametersProvider";
import { ProfileProvider } from "app/services/ProfileProvider";
import { updateMetadata } from "app/store/metadataStore";
import { FilterOptions } from "app/types/FilterOptions";
import { InterMainTable } from "app/types/InterMainProfile";
import { Paginated } from "app/types/Paginated";
import { Status } from "app/types/Status";
import async from "async";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Calculating from "../Load/Calculating";
import Loading from "../Load/Loading";
import ProfileGrid from "./ProfileGrid";

class Overview extends React.Component<
  WithTranslation & { setFilters: any; score_tab: string },
  {
    loading: boolean;
    data: { columns: { [key: string]: any }[]; rows: { [key: string]: any }[] };
    page: Paginated<InterMainTable>;
    options: FilterOptions;
    openMenu: boolean | Element; //
    search: string;
    dataAvailable: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    const options = JSON.parse(sessionStorage.getItem("options"));
    this.state = {
      loading: false,
      data: null,
      page: null,
      options: options ?? null,
      openMenu: false,
      search: options?.search || "",
      dataAvailable: true,
    };
  }
  ref = React.createRef();

  dataQueue = async.queue((task: any, callback: any) => {
      try {
        document.body.classList.add("waiting");
        task().then(() => callback());
      } catch (error) {
        console.log(error);
      }
  });

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.options != this.state.options) {
      const options = JSON.parse(sessionStorage.getItem("options"));
      sessionStorage.setItem(
        "options",
        JSON.stringify({ ...options, ...this.state.options })
      );
      this.dataQueue.kill();
      this.dataQueue.push(this.initialData);
    }
    if (prevState.options?.favourite != this.state.options?.favourite) {
      this.setState({
        data: null,
      });
    }
  }

  componentDidMount() {
    this.handleSearch = debounce(this.handleSearch, 1000);
    this.dataQueue.push(this.initialData);
  }

  getStatus = async () => {
    return new Promise((resolve, reject) =>
      ParametersProvider.getStatus().then((status) => {
        if (status.findIndex((s: Status) => !s.score_available) < 0) {
          this.setState({ dataAvailable: true });
          resolve(true);
        } else {
          this.setState({ dataAvailable: false });
          setTimeout(() => {
            this.getStatus().then(() => resolve(true));
          }, 5000);
        }
      })
    );
  };

  initialData = async () => {
    const options = JSON.parse(sessionStorage.getItem("options")) || {};
    document.body.classList.add("waiting");
    await this.getStatus();
    this.setState({ loading: true });
    await ProfileProvider.getAllProfilesData(
      0,
      20,
      this.props.score_tab,
      options
    ).then((page) => {
      const options_new = JSON.parse(sessionStorage.getItem("options")) || {};
      if (page.metadata.favourites !== (options_new.favourite ?? false)) {
        if (this.dataQueue.length() === 0 || this.dataQueue.length() === 1) {
          document.body.classList.remove("waiting");
        }
        return;
      }
      updateMetadata(page.metadata);
      this.getData(page.items)
        .then((data: any) => {
          delete page.items;
          this.setState({
            loading: false,
            data: data,
            page: page,
          });
          if (this.dataQueue.length() === 0 || this.dataQueue.length() === 1) {
            document.body.classList.remove("waiting");
          }
          
        })
        .catch((_err) => {
          this.setState({ loading: false });
          document.body.classList.remove("waiting");
        });
        
    });
  };

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.setFilters !== this.props.setFilters) {
      const options = {
        ...this.state.options,
        ...nextProps.setFilters,
      };
      this.setState({ options: options });
    }
  }

  loadMoreData = async () => {
    const page = this.state.page as Paginated<InterMainTable>;
    const options = JSON.parse(sessionStorage.getItem("options")) || {};
    if (
      this.state.loading ||
      Number(page.skip) + Number(page.limit) >= page.total
    )
      return;
    this.setState({ loading: true });

    const skip = Number(page.skip) + Number(page.limit);

    const newPage = await ProfileProvider.getAllProfilesData(
      skip,
      page.limit,
      this.props.score_tab,
      options
    );
    const options_new = JSON.parse(sessionStorage.getItem("options")) || {};
    if (newPage.metadata.favourites !== options_new.favourite) {
      if (this.dataQueue.length() === 0 || this.dataQueue.length() === 1) {
        document.body.classList.remove("waiting");
      }
      return;
    }
    this.getData(newPage.items).then((data: any) => {
      delete newPage.items;

      if (this.dataQueue.length() === 0 || this.dataQueue.length() === 1) {
        document.body.classList.remove("waiting");
      }
      
      this.setState({
        loading: false,
        data: {
          ...this.state.data,
          rows: [...this.state.data?.rows, ...data.rows],
        },
        page: newPage,
      });
    });
  };

  deleteRow = async (event: any) => {
    const data = this.state.data;
    const newData = data.rows.filter((row: any) => row.id !== event);
    this.setState({
      page: {
        ...this.state.page,
        total: this.state.page.total - 1,
      },
      data: {
        ...data,
        rows: newData,
      },
    });
  };

  getData = async (data: InterMainTable[]) => {
    const rows = await ProfileParser(
      data,
      this.deleteRow,
      this.props.setFilters?.rising ?? false
    );
    if (!rows) {
      return undefined;
    }
    const columns = Object.keys(rows[0])
      .filter((key) => key !== "id")
      .map((key) => {
        return {
          Header:
            this.props.t(`dashboard.planOverview.table.${key}`) ===
            `dashboard.planOverview.table.${key}`
              ? ""
              : this.props.t(`dashboard.planOverview.table.${key}`),
          accessor: key,
          width:
            key === "name" || key === "affiliation"
              ? "10vw"
              : key === "therapeuticAreaAndSubArea"
              ? "17.4rem"
              : undefined,
        };
      });
    return {
      rows,
      ...{
        columns,
        headerStyle: {
          color: `var("--gray---2")`,
          fontSize: "11px",
        },
      },
    };
  };

  handleSortChange = (event: SelectChangeEvent<string>) => {
    const [column, key] = event.target.value.split(".");

    const orderKeysTypes = [
      "name",
      "country",
      "affiliation",
      "numberOfPublications",
    ];
    if (
      !this.state.loading &&
      orderKeysTypes.findIndex((item) => item == column) >= 0
    ) {
      let options;
      switch (key) {
        case "asce":
          options = {
            ...this.state.options,
            orderKey: column as FilterOptions["orderKey"],
            order: "ASC" as FilterOptions["order"],
          };
          break;
        case "desc":
          options = {
            ...this.state.options,
            orderKey: column as FilterOptions["orderKey"],
            order: "DESC" as FilterOptions["order"],
          };

          break;
        default:
          options = {
            ...this.state.options,
          };
          break;
      }
    }
  };

  handleSearch = (event: any) => {
    const options = {
      ...this.state.options,
      search: event.target.value as string,
    };
    this.setState({ options: options });
  };

  handleOpenMenu = (event: any) =>
    this.setState({ openMenu: event.currentTarget });
  handleCloseMenu = () => this.setState({ openMenu: false });

  renderMenu = () => (
    <FormControl sx={{ height: "100%" }} fullWidth>
      <InputLabel sx={{ height: "100%" }} id="demo-simple-select-label">
        {this.props.t("dashboard.others.sortBy")}
      </InputLabel>
      <Select
        sx={{ height: "100%" }}
        label={this.props.t("dashboard.others.sortBy")}
        onChange={this.handleSortChange}
      >
        <MenuItem value={"name.asce"}>
          {this.props.t("dashboard.others.sortOptions.name")} ASC{" "}
        </MenuItem>
        <MenuItem value={"name.desc"}>
          {this.props.t("dashboard.others.sortOptions.name")} DSC{" "}
        </MenuItem>
        <MenuItem value={"country.asce"}>
          {this.props.t("dashboard.others.sortOptions.country")} ASC
        </MenuItem>
        <MenuItem value={"country.desc"}>
          {this.props.t("dashboard.others.sortOptions.country")} DSC
        </MenuItem>
        <MenuItem value={"affiliation.asce"}>
          {this.props.t("dashboard.others.sortOptions.affiliation")} ASC
        </MenuItem>
        <MenuItem value={"affiliation.desc"}>
          {this.props.t("dashboard.others.sortOptions.affiliation")} DSC
        </MenuItem>
        <MenuItem value={"numberOfPublications.asce"}>
          {this.props.t("dashboard.others.sortOptions.numberOfPublications")}{" "}
          ASC
        </MenuItem>
        <MenuItem value={"numberOfPublications.desc"}>
          {this.props.t("dashboard.others.sortOptions.numberOfPublications")}{" "}
          DSC
        </MenuItem>
      </Select>
    </FormControl>
  );

  public render() {
    return (
      <div className="overview" id="react-table">
        <Loading
          {...{ active: this.state.loading && this.state.data === null }}
        />
        <Box
          sx={{
            width: "100%",
            minWidth: "70rem",
            justifyContent: "space-around",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "60px",
            paddingRight: "60px",
          }}
          display="flex"
          className="overview__header"
        >
          <Box
            width="18.75rem"
            sx={{
              marginLeft: "auto",
              marginRight: "4rem",
              display: "flex",
            }}
          ></Box>

          {/* <Box sx={{
           width:'10rem',
           height:'2.5rem',
           marginRight: '1rem',
           marginLeft: 'auto'
           }}>{this.renderMenu()}</Box> */}
        </Box>
        {!this.state.dataAvailable || this.state.data === null ? (
          !this.state.dataAvailable ? (
            <Calculating active={!this.state.dataAvailable} />
          ) : null
        ) : (
          <>
            <ProfileGrid
              moreRows={() => this.dataQueue.push(this.loadMoreData)}
              table={this.state.data}
              totalEntries={this.state.page.total}
            />
          </>
        )}
        {this.state.loading ? (
          <CircularProgress
            sx={{
              position: "absolute",
              bottom: "3rem",
              left: "50%",
              width: "1rem!important",
              height: "1rem!important",
              zIndex: "1",
              color: "var(--gray---2)",
            }}
            className="loader"
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation("common")(Overview);
