import { Button } from "antd";
import UserProvider from "app/services/UserProvider";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

class Following extends React.Component<
  WithTranslation & { id: number; following: boolean , onChange: (following: boolean) => void},
  { text: string; class: string; id: number; following: boolean; loading: boolean, hover: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      text: this.props.following ? "following" : "follow",
      class: this.props.following ? "following" : "follow",
      loading: false,
      id: this.props.id,
      following: this.props.following,
      hover: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<
      WithTranslation<"translation"> & { id: number; following: boolean }
    >,
    prevState: Readonly<{ text: string; class: string; id: number; following: boolean; loading: boolean, hover: boolean }>
  ) {
    if (prevProps.following !== this.props.following) {
      this.setState({
        text: !this.props.following ? "follow" : this.state.hover ? "unfollow" : "following",
        class: this.props.following ? "following" : "follow",
        loading: false,
        following: this.props.following,
      });
    }
    if (prevProps.id !== this.props.id) {
      this.setState({
        id: this.props.id,
        loading: false,
        text: !this.props.following ? "follow" : this.state.hover ? "unfollow" : "following",
        class: this.props.following ? "following" : "follow",
        following: this.props.following,
      });
    }
    if (prevState.hover !== this.state.hover) {
      this.setState({
        text: !this.state.following ? "follow" : this.state.hover ? "unfollow" : "following",
        class: this.state.following ? "following" : "follow",
      });
    }
  }

  handleUpdateFollows = async (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    this.setState({ loading: true });
    const { t } = this.props;
    const { id, following, hover } = this.state;
    UserProvider.updateFollows(id, !following).then((response) => {
      if (response) {
        this.setState({
          text: following ? "follow" : hover ? "unfollow" : "following",
          class: following ? "follow" : "following",
          following: !following,
          loading: false,
        });
        this.props.onChange(!following);
      }
    });
  };

  render(): React.ReactNode {
    return (
      <Button
        className={`${this.state.class} ${
          this.state.loading ? "following-waiting" : ""
        }`}
        disabled={this.state.loading}
        onClick={this.handleUpdateFollows}
        
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
      >
        {this.props.t(`${this.state.text}`)}
      </Button>
    );
  }
}

export default withTranslation()(Following);
