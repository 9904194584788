import { Region } from "app/types";
import { axios, url,  checkStatus, header_options } from "./ProviderHelpers";

const getAllSourcesData = async (): Promise<string[]> => {
    try {
        const response = await axios.get(`${url}/conferences/sources`,
            {
                ...header_options,
            }
        );
        return response.data.data;
    }
    catch (error:any) {
        await checkStatus(error.response);
    }
}

class CongressProvider {
    public static getAllSourcesData = getAllSourcesData;
}

export default CongressProvider;