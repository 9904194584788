import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";

function TabPanel(props: {
  value: number;
  index: number;
  children: React.ReactNode;
}) {
  const { children, value, index, ...other } = props;
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);
  const [divElement, setDivElement] = React.useState(null);
  const [sizeSetted, setSizeSetted] = React.useState(false);
  const maxWidthPossible = () => {
    const size = divElement?.offsetParent?.offsetWidth - divElement?.offsetLeft;
    setWidth(size);
  };
  const maxHeightPossible = () => {
    //size = max width of the screen minus the offset left
    const size = divElement?.offsetParent?.offsetHeight - divElement?.offsetTop;
    setHeight(size);
  };

  window.addEventListener("resize", maxWidthPossible);
  window.addEventListener("resize", maxHeightPossible);
  useEffect(() => {
    if (
      !sizeSetted &&
      divElement?.offsetParent?.offsetWidth &&
      divElement?.offsetParent?.offsetHeight
    ) {
      maxWidthPossible();
      maxHeightPossible();
      setSizeSetted(true);
    }
  }, []);

  return (
    <Box
      id="tab-panel"
      sx={{
        width: width || "100%",
        height: height || "100%",
        display: value === index ? "flex" : "none",
        justifyContent: "flex-start",
        overflow: "auto",
        //height: window.innerHeight,

        //overflowY: "auto",
      }}
      className="tab-panel"
      ref={(divElement) => {
        setDivElement(divElement);
        maxHeightPossible();
        maxWidthPossible();
      }}
    >
      <Box sx={{
        marginTop: "1rem",
      }}>{children}</Box>
    </Box>
  );
}

export default TabPanel;
