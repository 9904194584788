function configs_simple(labels: any, datasets: any) {
  const backgroundColors: string[] = [];

  datasets.backgroundColors.forEach((color: string) =>
    backgroundColors.push(color)
  );

  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout: 0,
          tension: 0,
          pointRadius: 0,
          borderWidth: 0,
          backgroundColor: backgroundColors,
          fill: true,
          data: datasets.data,
        },
      ],
    },
    options: {
      borderAlign: "center",
      aspectRatio: 1,
      animation: {
        duration: 0,
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        mode: "none",
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
          filter: () => false,

          callbacks: {},
        },
      },

      interaction: {
        intersect: false,
        mode: "none",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs_simple;
