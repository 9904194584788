import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import theme from "app/assets/theme";
import { GuardedRoute } from "app/auth";
import routes from "app/routes";
import { RoutesDefinitions } from "app/types";
import { Route, Routes } from "react-router-dom";
import "./App.css";



function App() {

  const getRoutes = (_routes: RoutesDefinitions[] ): JSX.Element[] | null => {
    return _routes.map((route, index) => {
      const { path, main, auth ,  ...rest } = route;
      if(rest.children){
        getRoutes(rest.children.map((child) => {
          child.path = `${path}/${child.path}`;
          return child;
        }));
      }
      return auth?
      <Route key={index} path={path} element={<GuardedRoute />}>
        <Route key={index} path={path} element={main} />
      </Route> :
      <Route key={index} path={path}  element={main} />;
    });
  };
  return (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    
    <Routes>
      {getRoutes(routes)}
    </Routes>
  </ThemeProvider>
  );
}

export default App;
