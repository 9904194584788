/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "app/assets/theme/base/colors";
import borders from "app/assets/theme/base/borders";
import boxShadows from "app/assets/theme/base/boxShadows";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "app/assets/theme/functions/pxToRem";
import linearGradient from "app/assets/theme/functions/linearGradient";

const { white, gradients, grey, transparent } = colors;
const { borderWidth } = borders;
const { md } = boxShadows;

// types
type Types = any;

const switchButton: Types = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    padding: '0',
    switchBase: {
      color: 'var(--primary----blue)',
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
      },
      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-checked": {
        color: 'var(--primary----blue)',

        "&:hover": {
          backgroundColor: transparent.main,
        },

        "& .MuiSwitch-thumb": {
          borderColor: `${gradients.dark.main} !important`,
        },

        "& + .MuiSwitch-track": {
          backgroundColor: 'var(--primary----blue)',
          borderColor: `${gradients.dark.main} !important`,
          opacity: 1,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      },
    },

    thumb: {
      backgroundColor: white.main,
      marginTop: '4.5px',
      marginLeft: '5px',
      width: '16px',
      height: '16px',
    },

    track: {
      width: '41px',
      height: '20px',
      backgroundColor: 'var(--primary----blue)',
      opacity: 0.4,
      borderRadius: '16px',
    },

    checked: {},
  },
};

export default switchButton;
