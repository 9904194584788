import {
  Box,
  Checkbox,
  Chip,
  debounce,
  FormControlLabel,
  FormGroup,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import MDInput from "app/assets/theme/components/MDInput";
import SelectableCongressParser from "app/helpers/SelectableCongressParser";
import SelectableParser from "app/helpers/SelectableParser";
import CongressProvider from "app/services/CongressProvider";
import CountryProvider from "app/services/CountryProvider";
import TherapeuticProvider from "app/services/TherapeuticAreaProvider";
import { loadMetadata, subscribeMetadata } from "app/store/metadataStore";
import { subscribeTableSize } from "app/subscribers/SubscriberToTableSize";
import { Region } from "app/types";
import { Company } from "app/types/Company";
import { Metadata } from "app/types/Metadata";
import { TherapeuticArea } from "app/types/TherapeuticArea";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectableFilter from "./SelectableFilter";

const affiliation_icon_default = "/globe.svg";
const search_icon_default = "/akar-icons_search.svg";

let subscribesToRenderChip: Function[] = [];
export const subscribeToRenderChip = (
  callback: (render: JSX.Element) => void
) => {
  subscribesToRenderChip = [...subscribesToRenderChip, callback];
};

let subscribesToRenderSearch: Function[] = [];
export const subscribeToRenderSearch = (
  callback: (render: JSX.Element) => void
) => {
  subscribesToRenderSearch = [...subscribesToRenderSearch, callback];
};

class OverviewFilters extends React.Component<
  WithTranslation & {
    applyFilters: Function;
    tab: number;
    hideFilters: boolean;
  },
  {
    openFilters: boolean;
    openMenu: boolean | JSX.Element;
    regionsData: Region[];
    oldRegionsData: Region[];
    countriesSelected: string[];
    countriesDataSelected: { name: string; children: string[] }[];
    areasData: TherapeuticArea[];
    oldAreasData: TherapeuticArea[];
    areasSelected: string[];
    areasDataSelected: { name: string; children: string[] }[];
    congressesData: string[];
    congressesSelected: string[];
    congressesDataSelected: { name: string; children: string[] }[];
    companyData: Company[];
    companySelected: string[];
    companyDataSelected: { name: string; children: string[] }[];
    favourite: boolean;
    emitDebounce: boolean;
    waitingForMetadata: boolean;
    experience: { start: number; end: number }[] | [];
    range: number[];
    countryRef: number;
    areasRef: number;
    congressesRef: number;
    search: string;
    searchByAffiliation: string;
    affiliationIcon: string;
    affiliationFocus: boolean;
    searchIcon: string;
    searchFocus: boolean;
    yearsHover: boolean;
    yearsFocus: boolean;
    total: number;
    filterSize: number;
    moreFilters: boolean;
    n_citeds: { min: number; max: number }[];
    moreChips: boolean;
    filterHeight: number;
    linkedin: boolean;
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      openFilters: true,
      openMenu: false,
      favourite: false,
      countriesSelected: [""],
      countriesDataSelected: [],
      areasSelected: [""],
      areasDataSelected: [],
      companySelected: [""],
      companyDataSelected: [],
      congressesSelected: [""],
      congressesDataSelected: [],
      emitDebounce: true,
      waitingForMetadata: true,
      experience: [],
      range: [0, 30],
      regionsData: null,
      oldRegionsData: null,
      areasData: null,
      oldAreasData: null,
      countryRef: 0,
      congressesRef: 0,
      companyData: null,
      congressesData: null,
      areasRef: 0,
      affiliationIcon: affiliation_icon_default,
      affiliationFocus: false,
      searchIcon: search_icon_default,
      searchFocus: false,
      yearsFocus: false,
      yearsHover: false,
      search: "",
      searchByAffiliation: "",
      total: 0,
      filterSize: 1300,
      moreFilters: false,
      n_citeds: [],
      moreChips: false,
      filterHeight: 0,
      linkedin: false,
    };
    debounce(this.emitApplyFilters, 1500);
    //debounce(this.handleMetadataChange, 2000);
    subscribeMetadata(this.handleMetadataChange);
    subscribeTableSize(this.handleTableSize);
  }

  resetFilters = () => {
    this.setState({
      openFilters: true,
      openMenu: false,

      countriesSelected: [""],
      emitDebounce: true,
      waitingForMetadata: true,
      experience: [],
      countryRef: this.state.countryRef + 1,
      areasRef: this.state.areasRef + 1,
      searchByAffiliation: "",
      search: "",
      countriesDataSelected: [],
      areasDataSelected: [],
      areasSelected: [],
      congressesSelected: [],
      congressesDataSelected: [],
      n_citeds: [],
      range: [0, 30],
      linkedin: false,
    });
    sessionStorage.removeItem("overviewFilters");
    sessionStorage.removeItem("options");
    this.emitApplyFilters();
  };

  componentDidMount() {
    const olderState = sessionStorage.getItem("overviewFilters");

    if (olderState) {
      const state = JSON.parse(olderState);
      this.setState(state);
    } else {
      this.getRegions();
      this.getAreas();
      this.getCongresses();
      subscribesToRenderChip.forEach((f) => f(this.renderChip()));
      subscribesToRenderSearch.forEach((f) => f(this.renderSearch()));
      this.setState({
        emitDebounce: false,
        waitingForMetadata: false,
        total: loadMetadata()?.total || 0,
      });
    }
  }

  handleTableSize = (size: number) => {
    //sum children of this.divElement, so if bigger than size height = 200 else height = 48
    const childs = [].slice.call(
      this.divElement ? this.divElement?.children : []
    );
    const filterWidthNecessary =
      childs.reduce((a: any, b: any) => {
        return a + b.clientWidth;
      }, 0) || 0;

    this.setState({
      filterSize: size,
      filterHeight: filterWidthNecessary > size ? 200 : 48,
    });
  };

  handleMetadataChange = (metadata: Metadata) => {
    const anyFilterSelected =
      this.state.countriesDataSelected.length > 0 ||
      this.state.areasDataSelected.length > 0 ||
      this.state.companyDataSelected.length > 0 ||
      this.state.congressesDataSelected.length > 0 ||
      this.state.experience.length > 0 ||
      this.state.searchByAffiliation.length > 0 ||
      this.state.search.length > 0 ||
      this.state.linkedin ||
      this.state.n_citeds.length > 0;
    if (anyFilterSelected) {
      const { total, regions, areas } = metadata;

      this.setState({
        total,
        regionsData: regions,
        areasData: areas,
        emitDebounce: false,
        waitingForMetadata: false,
      });
    } else {
      this.setState({
        total: metadata.total,
        regionsData: this.state.oldRegionsData,
        areasData: this.state.oldAreasData,
        emitDebounce: false,
        waitingForMetadata: false,
      });
    }
  };

  getRegions = async () => {
    const regions = await CountryProvider.getAllCountriesData();
    this.setState({ regionsData: regions, oldRegionsData: regions });
  };

  getAreas = async () => {
    const areas = await TherapeuticProvider.getAllTherapeuticData();
    this.setState({ areasData: areas, oldAreasData: areas });
  };

  getCongresses = async () => {
    const congresses = await CongressProvider.getAllSourcesData();
    this.setState({ congressesData: congresses });
  };

  emitApplyFilters = async () => {
    this.setState({
      emitDebounce: true,
      waitingForMetadata: true,
    });
    const {
      favourite,
      countriesSelected,
      experience,
      areasSelected,
      n_citeds,
      congressesSelected,
      search,
      searchByAffiliation,
      companySelected,
      linkedin,
    } = this.state;

    this.props.applyFilters({
      countries: countriesSelected,
      favourite: favourite,
      experienceYears: experience,
      areas: areasSelected,
      citedBy: n_citeds,
      congresses: congressesSelected,
      search,
      searchByAffiliation,
      companies: companySelected,
      linkedin,
    });
    sessionStorage.setItem("overviewFilters", JSON.stringify(this.state));
    this.setState({ emitDebounce: false });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !this.state.emitDebounce &&
      (this.state.countriesSelected.filter(
        (item) => !prevState.countriesSelected.includes(item)
      ).length > 0 ||
        prevState.countriesSelected.filter(
          (item: string) => !this.state.countriesSelected.includes(item)
        ).length > 0 ||
        this.state.areasSelected.filter(
          (item) => !prevState.areasSelected.includes(item)
        ).length > 0 ||
        prevState.areasSelected.filter(
          (item: string) => !this.state.areasSelected.includes(item)
        ).length > 0 ||
        this.state.congressesSelected.filter(
          (item) => !prevState.congressesSelected.includes(item)
        ).length > 0 ||
        prevState.congressesSelected.filter(
          (item: string) => !this.state.congressesSelected.includes(item)
        ).length > 0 ||
        prevState.favourite !== this.state.favourite ||
        prevState.experience !== this.state.experience ||
        prevState.search !== this.state.search ||
        prevState.searchByAffiliation !== this.state.searchByAffiliation ||
        prevState.linkedin !== this.state.linkedin ||
        prevState.n_citeds.length !== this.state.n_citeds.length)
    ) {
      this.emitApplyFilters();
    }

    subscribesToRenderChip.forEach((f) => f(this.renderChip()));
    subscribesToRenderSearch.forEach((f) => f(this.renderSearch()));

    if (
      prevState.range[0] !== this.state.range[0] ||
      prevState.range[1] !== this.state.range[1]
    ) {
      this.handleYearsChange();
    }
    if (
      prevState.emitDebounce !== this.state.emitDebounce &&
      this.state.emitDebounce
    ) {
      document.body.classList.add("waiting");
    }
    if (
      prevProps.hideFilters !== this.props.hideFilters &&
      this.props.hideFilters
    ) {
      this.resetFilters();
      this.setState({
        openFilters: false,
      });
    }
  }

  handleLinkedinChange = (event: any) => {
    const checked = event.target.checked;
    this.setState({
      linkedin: checked,
    });
  };

  handleChangeFavourite = (event: any) => {
    const checked = event.target.checked;
    this.setState({
      favourite: checked,
    });
  };

  handleOpenFilters = (event: any) => {
    this.setState({
      openFilters: true,
    });
  };

  handleCloseFilters = (event: any) => {
    this.setState({
      openFilters: false,
    });
  };

  handleOpenMenu = (event: any) =>
    this.setState({ openMenu: event.currentTarget });
  handleCloseMenu = () => this.setState({ openMenu: false });

  handleYearsChange = async () => {
    const checked = this.state.range[0] !== 0 || this.state.range[1] !== 30;

    if (checked) {
      this.setState({
        experience: [
          {
            start: this.state.range[0],
            end:
              this.state.range[1] != this.state.range[0]
                ? this.state.range[1]
                : this.state.range[1] + 5,
          },
        ],
      });
    } else {
      this.setState({
        experience: [],
      });
    }
  };

  handleDeleteExperience = () => {
    this.setState({
      experience: [],
      range: [0, 30],
    });
  };

  handleChangeCountry = (
    event: [string[], { name: string; children: string[] }[]]
  ) => {
    this.setState({
      countriesSelected: event[0],
      countriesDataSelected: event[1],
    });
  };

  handleChangeArea = (
    event: [string[], { name: string; children: string[] }[]]
  ) => {
    this.setState({
      areasSelected: event[0],
      areasDataSelected: event[1],
    });
  };

  handleCitedChange = (event: any) => {
    const checked = event.target.checked;
    const value = JSON.parse(event.target.value);
    this.setState({
      n_citeds: checked
        ? this.state.n_citeds.concat([value])
        : this.state.n_citeds.filter(
            (item) => item.min !== value.min && item.max !== value.max
          ),
    });
  };

  handleSearch = () => {
    this.emitApplyFilters();
  };

  divElement: any;

  handleSelectDelete = (event: string) => {
    const newcountriesSelected = this.state.countriesSelected?.filter(
      (item) => item !== event
    );
    // delete from children, delete parent if no children
    const newcountriesDataSelected = this.state.countriesDataSelected?.filter(
      (item) => {
        if (item.children) {
          item.children = item.children.filter((child) => child !== event);
          if (item.children.length === 0) {
            return false;
          }
        }
        return true;
      }
    );
    const newareasDataSelected = this.state.areasDataSelected?.filter(
      (item) => {
        if (item.children) {
          item.children = item.children.filter((child) => child !== event);
          if (item.children.length === 0) {
            return false;
          }
        }
        return true;
      }
    );
    const newareasSelected = this.state.areasSelected?.filter(
      (item) => item !== event
    );
    const newcongressesSelected = this.state.congressesSelected?.filter(
      (item) => item !== event
    );
    const newcongressesDataSelected = this.state.congressesDataSelected?.filter(
      (item) => {
        if (item.name === event) {
          return false;
        }
        if (item.children) {
          item.children = item.children.filter((child) => child !== event);
          if (item.children.length === 0) {
            return false;
          }
        }
        return true;
      }
    );

    this.setState({
      countriesSelected: newcountriesSelected,
      countriesDataSelected: newcountriesDataSelected,
      areasDataSelected: newareasDataSelected,
      areasSelected: newareasSelected,
      congressesSelected: newcongressesSelected,
      congressesDataSelected: newcongressesDataSelected,
    });
  };

  handleDeleteQuotes = (cited: { min: number; max: number }) => {
    this.setState({
      n_citeds: this.state.n_citeds.filter(
        (item) => item.min !== cited.min && item.max !== cited.max
      ),
    });
  };

  handleDeleteLinkedin = () => {
    this.setState({
      linkedin: false,
    });
  };

  handleFilters = () => {
    this.setState({
      moreFilters: !this.state.moreFilters,
    });
  };

  handleRemoveFilters = () => {
    this.setState({
      countriesSelected: [""],
      countriesDataSelected: [],
      areasDataSelected: [],
      areasSelected: [""],
      experience: [],
      congressesSelected: [],
      congressesDataSelected: [],
      n_citeds: [],
      range: [0, 30],
      linkedin: false,
    });
  };

  handleShow = (_event: any) => {
    this.setState({
      moreChips: !this.state.moreChips,
    });
  };

  chipStyle = {
    backgroundColor: "var(--primary----blue)",
    color: "var(--white)",
    "&:hover": {
      backgroundColor: "var(--primary----blue-1)",
    },
  };

  renderSearch = () => (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <MDInput
        autoComplete="off"
        InputProps={{
          startAdornment: <img src={this.state.searchIcon} alt="search" />,
          endAdornment: (
            <img
              style={{
                display:
                  this.state.search || this.state.searchFocus
                    ? "block"
                    : "none",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({
                  search: "",
                });
              }}
              src={"/icons/x.svg"}
            />
          ),
        }}
        onMouseEnter={() => {
          this.setState({
            searchIcon: search_icon_default.replace(".svg", "-marked.svg"),
          });
        }}
        onMouseLeave={() => {
          if (!this.state.searchFocus) {
            this.setState({
              searchIcon: search_icon_default,
            });
          }
        }}
        onFocus={() => {
          this.setState({
            searchFocus: true,
            searchIcon: search_icon_default.replace(".svg", "-marked.svg"),
          });
        }}
        onBlur={() => {
          this.setState({
            searchFocus: false,
            searchIcon: search_icon_default,
          });
        }}
        placeholder={this.props.t("dashboard.filters.searchByName")}
        value={this.state.search}
        sx={{
          width: "200px",
          backgroundColor: "var(--white)",
          input: {
            paddingLeft: "10px",
            height: "17px",
            color: "var(--primary----blue)",
            borderColor: "var(--primary----blue)",
            fontWeight: 600,
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              color: "var(--gray---2)",
              marginLeft: "0rem",
              opacity: 1,
            },
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "var(--gray---4)",
            },
            "&:hover fieldset": {
              borderColor: "var(--primary----blue-2)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "var(--primary----blue-2)",
            },
          },
        }}
        onChange={(event: any) => {
          this.setState({ search: event.target.value });
          this.handleSearch();
        }}
      />
    </Box>
  );

  renderChip = () => {
    const totalChips =
      (this.state.countriesSelected[0] != ""
        ? this.state.countriesSelected?.length
        : 0) +
      (this.state.areasSelected[0] != ""
        ? this.state.areasSelected?.length
        : 0) +
      (this.state.range[0] != 0 || this.state.range[1] != 30 ? 1 : 0) +
      (this.state.n_citeds[0] != undefined ? 1 : 0) +
      (this.state.linkedin ? 1 : 0) +
      (this.state.congressesSelected[0] != ""
        ? this.state.congressesSelected?.length
        : 0);
    const chipsRender = [
      ...this.state.countriesSelected.map((value) =>
        value != "" && value.at(0) != "_" ? (
          <Chip
            sx={this.chipStyle}
            key={value}
            label={value}
            deleteIcon={
              <Box
                sx={{
                  padding: "0.15rem",
                  cursor: "pointer",
                }}
              >
                <img src={"/close.svg"} />{" "}
              </Box>
            }
            onDelete={() => this.handleSelectDelete(value)}
          />
        ) : (
          ""
        )
      ),
      ...this.state.areasSelected.map((value) =>
        value != "" && value.at(0) != "_" ? (
          <Chip
            sx={this.chipStyle}
            key={value}
            label={<span style={{ textTransform: "capitalize" }}>{value}</span>}
            deleteIcon={
              <Box
                sx={{
                  padding: "0.15rem",
                  cursor: "pointer",
                }}
              >
                <img src={"/close.svg"} />{" "}
              </Box>
            }
            onDelete={() => this.handleSelectDelete(value)}
          />
        ) : (
          ""
        )
      ),
      this.state.experience.length > 0 ? (
        <Chip
          sx={this.chipStyle}
          key={"experience"}
          label={`${this.state.range[0]}-${this.state.range[1]} Yrs`}
          deleteIcon={
            <Box
              sx={{
                padding: "0.15rem",
                cursor: "pointer",
              }}
            >
              <img src={"/close.svg"} />{" "}
            </Box>
          }
          onDelete={this.handleDeleteExperience}
        />
      ) : (
        ""
      ),

      ...this.state.congressesSelected.map((value) =>
        value != "" && value.at(0) != "_" ? (
          <Chip
            sx={this.chipStyle}
            key={value}
            label={<span style={{ textTransform: "capitalize" }}>{value}</span>}
            deleteIcon={
              <Box
                sx={{
                  padding: "0.15rem",
                  cursor: "pointer",
                }}
              >
                <img src={"/close.svg"} />{" "}
              </Box>
            }
            onDelete={() => this.handleSelectDelete(value)}
          />
        ) : (
          ""
        )
      ),
      this.state.n_citeds.length > 0
        ? this.state.n_citeds.map((cited) => (
            <Chip
              sx={this.chipStyle}
              key={"experience"}
              label={
                cited.max == 99999
                  ? `+${cited.min} citations`
                  : `${cited.min}-${cited.max} citations`
              }
              deleteIcon={
                <Box
                  sx={{
                    padding: "0.15rem",
                    cursor: "pointer",
                  }}
                >
                  <img src={"/close.svg"} />{" "}
                </Box>
              }
              onDelete={() => this.handleDeleteQuotes(cited)}
            />
          ))
        : "",
      this.state.linkedin ? (
        <Chip
          sx={this.chipStyle}
          key={"linkedin"}
          label={this.props.t("dashboard.filters.linkedinChip")}
          deleteIcon={
            <Box
              sx={{
                padding: "0.15rem",
                cursor: "pointer",
              }}
            >
              <img src={"/close.svg"} />{" "}
            </Box>
          }
          onDelete={this.handleDeleteLinkedin}
        />
      ) : (
        ""
      ),
    ].filter((value) => value != "");
    let chips = [];
    if (this.state.moreChips) {
      chips = chipsRender;
    } else {
      chips = chipsRender.slice(0, 5);
    }
    if (totalChips > 5) {
      chips.push(
        this.state.moreChips ? (
          <Chip
            key={"remove"}
            label={this.props.t("dashboard.filters.showLess")}
            sx={{
              background: "transparent",
              color: "var(--primary----blue)",
              borderRadius: "4px",
              "&:hover": {
                background: "var(--primary----blue-ultra-light)",
                borderRadius: "4px",
              },
            }}
            avatar={
              <Box
                sx={{
                  padding: "0.15rem",
                  paddingTop: "0.25rem",
                  paddingRight: "0",
                  cursor: "pointer",
                }}
              >
                <img src={"/minus.svg"} />{" "}
              </Box>
            }
            onClick={this.handleShow}
          />
        ) : (
          <Chip
            key={"remove"}
            label={this.props.t("dashboard.filters.showAll")}
            sx={{
              background: "transparent",
              color: "var(--primary----blue)",
              borderRadius: "4px",
              "&:hover ": {
                background: "var(--primary----blue-ultra-light)",
                borderRadius: "4px",
              },
            }}
            avatar={
              <Box
                sx={{
                  padding: "0.15rem",
                  paddingTop: "0.25rem",
                  paddingRight: "0",
                  cursor: "pointer",
                }}
              >
                <img src={"/plus.svg"} />{" "}
              </Box>
            }
            onClick={this.handleShow}
          />
        )
      );
    }
    if (totalChips > 0) {
      return (
        <Box
          sx={{
            display: "flex",
            fontWeight: 400,
            lineHeight: "140%",
            fontSize: "14px",
            color: "var(--gray----2)",
            marginRight: "auto",
            marginLeft: "1rem",
            width: "100%",
            height: "fil-content",
            marginX: "auto",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              lineWeight: "18px",
              color: "var(--gray---1)",
              textTransform: "uppercase",
              width: "fit-content",
              whiteSpace: "nowrap",
            }}
          >
            {this.props.t("dashboard.filters.filtersApplied")}
          </Typography>
          <Box
            sx={{
              marginLeft: "8px",
              display: "flex",
              color: "var(--primary---blue-1)",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "18px",
              width: "100%",
              height: "auto",
              flexDirection: "row",
            }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {chips}
              <Chip
                key={"remove"}
                label={this.props.t("dashboard.filters.remove")}
                sx={{
                  background: "transparent",
                  color: "var(--primary----blue)",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "var(--primary----blue-ultra-light)",
                    borderRadius: "4px",
                  },
                }}
                avatar={
                  <Box
                    sx={{
                      padding: "0.15rem",
                      paddingTop: "0.25rem",
                      paddingRight: "0",
                      cursor: "pointer",
                    }}
                  >
                    <img src={"/trash.svg"} />{" "}
                  </Box>
                }
                onClick={this.handleRemoveFilters}
              />
            </Box>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  handleChangeCongress = (
    event: [string[], { name: string; children: string[] }[]]
  ) => {
    this.setState({
      congressesSelected: event[0],
      congressesDataSelected: event[1] ?? [],
    });
  };

  render() {
    if (!this.props.hideFilters) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "130px",
            width: "100vw",
            justifyContent: "flex-start",
            background: "var(--white)",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              marginX: "auto",
              height: "min-content",
              width: this.state.filterSize,
              marginTop: "32px",
              fontSize: "24px",
              fontWeight: 600,
              color: "var(--primary----dark-blue)",
              display: "flex",
            }}
          >
            <Box>{this.props.t("dashboard.filters.title")}</Box>
            {this.state.filterHeight > 60 ? (
              <Chip
                key={"remove"}
                label={
                  this.state.moreFilters
                    ? this.props.t("dashboard.filters.showLess")
                    : this.props.t("dashboard.filters.showMore")
                }
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontSize: "12px",
                  lineWeight: "18px",
                  fontWeight: 600,
                  color: "var(--primary----blue)",
                  background: "transparent",
                  "&:hover": {
                    background: "var(--primary----blue-ultra-light)",
                    borderRadius: "4px",
                  },
                }}
                onClick={this.handleFilters}
                avatar={
                  <Box
                    sx={{
                      padding: "0.15rem",
                      paddingTop: "0.25rem",
                      paddingRight: "0",
                      cursor: "pointer",
                    }}
                  >
                    <img src={"/filter.svg"} />{" "}
                  </Box>
                }
              />
            ) : (
              ""
            )}
          </Box>

          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              background: "var(--white)",
              transition: "width 0.2s, visibility 0.4s",
              marginX: "auto",
              width: this.state.filterSize,
              height: this.state.moreFilters ? "auto" : "48px",
              overflowX: "none",
              overflowY: "hidden",
              flexWrap: "wrap",
              rowGap: "16px",
              columnGap: "18px",
            }}
            ref={(divElement) => (this.divElement = divElement)}
          >
            <Box
              width="316px"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MDInput
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <img
                      style={{
                        color: "var(--gray---1)",
                        marginRight: "-1rem",
                      }}
                      src={this.state.affiliationIcon}
                    />
                  ),
                  endAdornment: (
                    <img
                      style={{
                        display:
                          this.state.searchByAffiliation ||
                          this.state.affiliationFocus
                            ? "block"
                            : "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          searchByAffiliation: "",
                        });
                      }}
                      src={"/icons/x.svg"}
                    />
                  ),
                }}
                onMouseEnter={() => {
                  this.setState({
                    affiliationIcon: affiliation_icon_default.replace(
                      ".svg",
                      "-marked.svg"
                    ),
                  });
                }}
                onMouseLeave={() => {
                  if (!this.state.affiliationFocus) {
                    this.setState({
                      affiliationIcon: affiliation_icon_default,
                    });
                  }
                }}
                onFocus={() => {
                  this.setState({
                    affiliationFocus: true,
                    affiliationIcon: affiliation_icon_default.replace(
                      ".svg",
                      "-marked.svg"
                    ),
                  });
                }}
                onBlur={() => {
                  this.setState({
                    affiliationFocus: false,
                    affiliationIcon: affiliation_icon_default,
                  });
                }}
                placeholder={this.props.t(
                  "dashboard.others.searchAffiliationInTheTable"
                )}
                value={this.state.searchByAffiliation}
                sx={{
                  width: "100%",
                  marginLeft: "0rem",
                  backgroundColor: "var(--white)",
                  input: {
                    paddingLeft: "1.5rem",
                    height: "20px",
                    color: "var(--primary----blue)",
                    borderColor: "var(--primary----blue)",
                    fontWeight: 600,
                    "&::placeholder": {
                      textOverflow: "ellipsis !important",
                      color: "var(--gray---2)",
                      marginLeft: "0rem",
                      opacity: 1,
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "var(--gray---4)",
                    },
                    "&:hover fieldset": {
                      borderColor: "var(--primary----blue-2)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--primary----blue-2)",
                    },
                  },
                }}
                onChange={(event: any) => {
                  this.setState({ searchByAffiliation: event.target.value });
                  this.handleSearch();
                }}
              />
            </Box>

            <SelectableFilter
              emitRestore={this.state.countryRef}
              selected={this.state.countriesSelected}
              dataSelected={this.state.countriesDataSelected}
              emitFilter={this.handleChangeCountry}
              dataInput={SelectableParser(this.state.regionsData)}
              mostpopular={true}
              disabled={this.state.waitingForMetadata}
              metaText={{
                icon: "/map-pin.svg",
                placeholder: this.props.t("dashboard.filters.selectCountry"),
                label: this.props.t("dashboard.filters.country"),
              }}
            />
            <SelectableFilter
              emitRestore={this.state.areasRef}
              selected={this.state.areasSelected}
              dataSelected={this.state.areasDataSelected}
              emitFilter={this.handleChangeArea}
              dataInput={SelectableParser(this.state.areasData)}
              dontSimplify={true}
              mostpopular={true}
              disabled={this.state.waitingForMetadata}
              metaText={{
                icon: "/file-text.svg",
                placeholder: this.props.t(
                  "dashboard.filters.selectTherapeuticArea"
                ),
                label: this.props.t("dashboard.filters.therapeuticArea"),
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "316px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "var(--gray---1)",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <FormGroup
                  sx={{
                    display: "flex",
                    color: "var(--gray---2)",
                    fontSize: "12px",
                    lineHeight: "18px",
                    marginRight: "auto",
                    marginLeft: "0.1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span>
                    {this.props.t("dashboard.filters.experienceYears")}
                  </span>
                  <Box
                    sx={{
                      display: "flex",
                      color: "var(--gray---1)",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        width: "20px",
                        fontSize: "14px",
                        color: "var(--primary----dark-blue)",
                      }}
                    >
                      {this.state.range[0]}
                    </Typography>
                    <Slider
                      step={5}
                      max={30}
                      min={0}
                      onChange={(event: any, newValue: any) => {
                        const min = newValue[0] > 25 ? 25 : newValue[0];
                        const max =
                          newValue[1] != newValue[0]
                            ? newValue[1]
                            : newValue[0] >= 25
                            ? 30
                            : newValue[1] + 5;
                        this.setState({ range: [min, max] });
                      }}
                      value={this.state.range}
                      valueLabelDisplay="auto"
                      onMouseEnter={() => {
                        this.setState({
                          yearsHover: true,
                        });
                      }}
                      onMouseLeave={(event) => {
                        this.setState({
                          yearsHover: false,
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          yearsHover: true,
                        });
                      }}
                      sx={{
                        width: "204px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        color: this.state.yearsHover
                          ? "var(--primary----dark-blue)"
                          : "var(--primary----blue)",
                        // on hover or focus or active
                        boxShadow: "0",
                        border: "none",
                        backgroundColor: "transparent !important",
                        // on hover or focus or active
                        "&:hover, &:focus, &:active": {
                          boxShadow: "0",
                          border: "none",
                        },
                        ".MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover":
                          {
                            boxShadow: "none !important",
                          },
                        //label
                        "& .MuiSlider-valueLabel": {
                          zIndex: 100000,
                          color: "var(--white)",
                          background: "transparent",
                          width: "36px",
                          height: "24px",
                          backgroundImage: "url(/Union.svg)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          fontSize: "14px",
                          lineHeight: "20px",
                          top: "0px",
                          fontWeight: 600,
                          paddingBottom: "7px",
                        },
                      }}
                    />
                    <Typography
                      style={{
                        textAlign: "right",
                        width: "20px",
                        fontSize: "14px",
                        color: "var(--primary----dark-blue)",
                      }}
                    >
                      {this.state.range[1]}
                    </Typography>
                  </Box>
                </FormGroup>
              </Box>
            </Box>
            <SelectableFilter
              emitRestore={this.state.congressesRef}
              selected={this.state.congressesSelected}
              dataSelected={this.state.congressesDataSelected}
              emitFilter={this.handleChangeCongress}
              dataInput={SelectableCongressParser(this.state.congressesData)}
              mostpopular={false}
              metaText={{
                icon: "/star.svg",
                placeholder: this.props.t("dashboard.filters.selectCongress"),
                label: this.props.t("dashboard.filters.congress"),
              }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "316px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "var(--gray---2)",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "flex-start",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 600,
                }}
              >
                {this.props.t("dashboard.filters.citations")}
              </Box>
              <FormGroup
                sx={{
                  display: "flex",
                  color: "var(--gray---1)",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                  whiteSpace: "nowrap",
                  width: "250px",
                  marginTop: "4px",
                  paddingX: 0,
                  paddingY: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginRight: "15px",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      display: "flex",
                      height: "20px",
                      minHeight: "20px",
                      margin: 0,
                    }}
                    control={
                      <>
                        <Checkbox
                          value={JSON.stringify({ min: 0, max: 5 })}
                          onChange={this.handleCitedChange}
                          checked={
                            this.state.n_citeds != null &&
                            this.state.n_citeds?.find(
                              (item) => item.min == 0
                            ) != undefined
                          }
                        />
                      </>
                    }
                    label=""
                  />
                  <Box
                    sx={{
                      fontWeight: "400",
                      color: "var(--gray---1)",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    0-5
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginRight: "15px",
                    margin: 0,
                  }}
                >
                  <FormControlLabel
                    sx={{
                      display: "flex",
                      height: "20px",
                      minHeight: "20px",
                    }}
                    control={
                      <>
                        <Checkbox
                          value={JSON.stringify({ min: 5, max: 10 })}
                          onChange={this.handleCitedChange}
                          checked={
                            this.state.n_citeds != null &&
                            this.state.n_citeds?.find(
                              (item) => item.min == 5
                            ) != undefined
                          }
                          sx={{
                            fontWeight: "400",
                            color: "var(--primary----blue)",

                            "&.Mui-checked": {
                              color: "var(--primary----blue)",
                            },
                            "&.MuiCheckbox-indeterminate": {
                              color: "var(--primary----blue)",
                            },
                          }}
                        />
                      </>
                    }
                    label=""
                  />

                  <Box
                    sx={{
                      fontWeight: "400",
                      color: "var(--gray---1)",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    5-10
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginRight: "15px",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      display: "flex",
                      height: "20px",
                      minHeight: "20px",
                      margin: 0,
                    }}
                    control={
                      <>
                        <Checkbox
                          value={JSON.stringify({ min: 10, max: 99999 })}
                          onChange={this.handleCitedChange}
                          checked={
                            this.state.n_citeds != null &&
                            this.state.n_citeds?.find(
                              (item) => item.min == 10
                            ) != undefined
                          }
                        />
                      </>
                    }
                    label=""
                  />
                  <Box
                    sx={{
                      fontWeight: "400",
                      color: "var(--gray---1)",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {" "}
                    +10
                  </Box>
                </Box>
              </FormGroup>
            </Box>
            <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "316px",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                color: "var(--gray---2)",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "flex-start",
                whiteSpace: "nowrap",
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 600,
              }}
            >
              {this.props.t("dashboard.filters.linkedin")}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "24px",
              }}
            >
              <Switch
                sx={{
                  marginLeft: "-12px",
                  height: "42px",
                  width: "auto",
                }}
                disableRipple
                checked={this.state.linkedin}
                onChange={this.handleLinkedinChange}
              />
              <Typography
                sx={{
                  color: "var(--gray---1)",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 400,
                  marginLeft: "-4px",
                }}
              >
                {this.state.linkedin
                  ? this.props.t("dashboard.filters.on")
                  : this.props.t("dashboard.filters.off")}
              </Typography>
            </Box> */}
          </Box>
          </Box>
         
          <Box
            sx={{
              marginTop: "32px",
              width: "100%",
              backgroundColor: "var(--back)",
              borderTop: "1px solid var(--primary----blue-3)",
            }}
          ></Box>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            marginTop: "0px",
            paddingTop: "0px",
            width: "100vw",
            backgroundColor: "var(--back)",
            borderTop: "1px solid var(--primary----blue-3)",
          }}
        ></Box>
      );
    }
  }
}

export default withTranslation("common")(OverviewFilters);
