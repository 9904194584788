import { User } from "app/types/User";
import { axios, url,  checkStatus, header_options } from "./ProviderHelpers";




const updateUserData = async (user: User): Promise<User> => {
    try {
        const response = await axios.put(`${url}/user`,
            user,
            {...header_options}
        )
        return response.data.data as User;
    }
    catch (error: any) {
        await checkStatus(error.response);
    }
}

const getUserData = async (): Promise<any> => {
    try {
        const response = await axios.get(`${url}/user`,
            {
                ...header_options,
            }
        );
        return response.data.data;
    }
    catch (error: any) {
        await checkStatus(error.response);
    }
}

const changePassword = async (old_password: string, new_password: string): Promise<boolean | string> => {
    try {
        const acces_token = document.cookie.split("access_token=")[1].split(";")[0];
        header_options.headers['Authorization'] = `Bearer ${acces_token}`;
        const response = await axios.post(`${url}/user/changepassword`,
            {
                old_password,
                new_password,
            },
            {
                ...header_options,
            }
        );
        if(response.status === 200) {
            return response.data.data.message;
        }
        else {
            return false;
        }
    }
    catch (error: any) {
        return false;
    }
}

const updateFollows = async (profile_id: number, following: boolean): Promise<boolean> => {
    try {
        const response = await axios.put(`${url}/user/interaction`,
            {
                profile_id,
                following,
            },
            {
                ...header_options,
            }
        );
        return response.data.data;
    }
    catch (error: any) {
        await checkStatus(error.response);
    }
}

class UserProvider {
    public static updateUser = updateUserData;
    public static getUser = getUserData;
    public static changePassword = changePassword;
    public static updateFollows = updateFollows;
}

export default UserProvider;