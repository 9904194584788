import {
  Chip,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { color } from "../../helpers/ColorAcent";

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: "var(--primary--800)",
    color: "var(--white)",
  },
});

class TherapeuticSubArea extends React.Component<
  WithTranslation & {
    subareas: {
      id: number;
      name: string;
    }[];
    printAll: boolean;
    printOne: boolean;
  },
  {
    textColor: String[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      textColor: this.props.subareas
        ? this.props.subareas.map((sa) => color(sa.id).textColor)
        : null,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.subareas !== this.props.subareas) {
      this.setState({
        textColor: this.props.subareas
          ? this.props.subareas.map((sa) => color(sa.id).textColor)
          : null,
      });
    }
  }

  render(): React.ReactNode {
    if (this.props.printOne) {
      return ( 
      <NoMaxWidthTooltip
        title={
          <Box
            sx={{
              padding: "0.5rem",
              textTransform: "capitalize",
              backgroundColor: "var(--primary----dark-blue)",
            }}
          >
            {this.props.subareas && this.props.subareas.length > 0 && this.props.subareas[0].id  > -1? 
                this.props.subareas[0].name
              : "" }
          </Box>
        }
      sx={{
        margin: "0.05rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxWidth: "14rem",
        width: "min-content",
        zIndex: 999,
      }}
    >
      {this.props.subareas && this.props.subareas.length > 0 && this.props.subareas[0].id  > -1? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "min-content",
          //marginX: "auto",
          marginRight: '0.2rem',
          flexWrap: "wrap",
          marginY: "0.01rem",
        }}
      >
        <Chip
          sx={{
            background: "transparent",
            color: `var(${this.state.textColor[0]})`,
            border: `1px solid var(${this.state.textColor[0]})`,
            margin: "0.05rem",
            paddingX: "0.1rem",
            height: "2rem",
            fontWeight: "bold",
            textTransform: "capitalize",
            maxWidth: "14rem",
            
          }}
          label={
            <Typography
              sx={{
                
                textTransform: "capitalize",
                fontSize: "0.875rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {this.props.subareas[0].name}
            </Typography>
          }
        />
      </Box>) : null}
    </NoMaxWidthTooltip>)
    } else {
      return (
        <NoMaxWidthTooltip
          sx={{
            display:
              !this.props.printAll && this.props.subareas.slice(2).length > 0
                ? "relative"
                : "none",
          }}
          title={
            <Box
              sx={{
                padding: "0.5rem",
                textTransform: "capitalize",
                backgroundColor: "var(--primary----dark-blue)",
              }}
            >
              {this.props.subareas
                ? this.props.subareas
                    .slice(2)
                    .map((sa, index) => `${sa.name}\n`)
                : ""}
            </Box>
          }
        >
          <Box
            sx={{
              margin: "0.5rem",
            }}
          >
            {this.props.subareas
              ? (this.props.printAll
                  ? this.props.subareas
                  : this.props.subareas.slice(0, 2)
                ).map((sa, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "min-content",
                      //marginX: "auto",
                      //marginRight: '0.2rem',
                      flexWrap: "wrap",
                      marginY: "0.01rem",
                    }}
                  >
                    <Chip
                      sx={{
                        background: "transparent",
                        color: `var(${this.state.textColor[index]})`,
                        border: `1px solid var(${this.state.textColor[index]})`,
                        margin: "0.05rem",
                        maxWidth: "auto",
                        height: "2rem",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                      label={
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.875rem",
                          }}
                        >
                          {sa.name}
                        </Typography>
                      }
                    />
                  </Box>
                ))
              : null}
            {this.props.subareas &&
            !this.props.printAll &&
            this.props.subareas.slice(2).length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "min-content",
                  //marginX: "auto",
                  //marginRight: '0.2rem',
                  flexWrap: "wrap",
                  marginY: "0.01rem",
                }}
              >
                <Chip
                  sx={{
                    background: "transparent",
                    color: `var(${this.state.textColor[2]})`,
                    border: `1px solid var(${this.state.textColor[2]})`,
                    margin: "0.05rem",
                    maxWidth: "auto",
                    height: "2rem",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                  label={
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                      }}
                    >
                      +{this.props.subareas.slice(2).length}
                    </Typography>
                  }
                />
              </Box>
            ) : null}
          </Box>
        </NoMaxWidthTooltip>
      );
    }
  }
}

export default withTranslation("common")(TherapeuticSubArea);
