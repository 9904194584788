/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "app/assets/theme/base/colors";
import borders from "app/assets/theme/base/borders";
import boxShadows from "app/assets/theme/base/boxShadows";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "app/assets/theme/functions/pxToRem";
import boxShadow from "app/assets/theme/functions/boxShadow";

const { grey, white, black, info } = colors;
const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = boxShadows;

// types
type Types = any;

const slider: Types = {
  styleOverrides: {
    root: {
       width: "100%",
       color: 'var(--primary----blue-1)'
       
    },

    // on hover 
    



      "& .MuiSlider-active, & .Mui-focusVisible": {
        boxShadow: "none !important",
        color: 'var(--primary----dark-blue)'
      },

      "& .MuiSlider-valueLabel": {
        //color: black.main,
      },
    },

    rail: {
      height: "2px",
      background: "red",
      opacity: 1,
    },

    track: {
      background: 'var(--primary----blue-1)',
      height: "2px",
      position: "relative",
      border: "none",
      zIndex: 1,
      "&:hover": {
        background: 'var(--primary----blue-1)',
        color: 'var(--primary----dark-blue)'
      },
    },

    thumb: {
      width: "16px",
      height: "16px",
      backgroundImage: "url('/slider-mark-unaligned.svg')",
      //backgroundColor: white.main,
      zIndex: 10,
      //boxShadow: sliderBoxShadow.thumb,
      //border: `${borderWidth[1]} solid ${info.main}`,
      transition: "all 200ms linear",

      "&:hover": {
        color: 'var(--primary----dark-blue)'
      },

      "&:active": {
        color: 'var(--primary----dark-blue)'
      },

      "&.Mui-active": {boxShadow: "none" },
    },
};

export default slider;
