import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";



class NotFound extends React.Component<WithTranslation> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div>
        <h1>{this.props.t('error.notfound.title')}</h1>
        <p>{this.props.t('error.notfound.description')}</p>
      </div>
    );
  }
}

export default withTranslation('common')(NotFound);
