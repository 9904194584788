import { Region } from "app/types";
import { axios, url,  checkStatus, header_options } from "./ProviderHelpers";

const getAllCountriesData = async (): Promise<Region[]> => {
    try {
        const response = await axios.get(`${url}/country`,
            {
                ...header_options,
            }
        );
       
        return response.data.data as Region[];
    }
    catch (error:any) {
        await checkStatus(error.response);
    }
}

class CountryProvider {
    public static getAllCountriesData = getAllCountriesData;
}

export default CountryProvider;