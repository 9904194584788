import { Avatar, Box, Card, Chip, Typography } from "@mui/material";
import { Tooltip } from "antd";
import { loadMetadata } from "app/store/metadataStore";
import { Profile } from "app/types";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Following from "./Following";

class ProfileCard extends React.Component<
  WithTranslation & { profile: Profile; favourite: boolean },
  { profile: Profile; favourite: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      profile: this.props.profile,
      favourite: this.props.favourite,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<
      WithTranslation<"translation"> & { profile: Profile; favourite: boolean }
    >,
    prevState: Readonly<{ profile: Profile; favourite: boolean }>,
    snapshot?: any
  ): void {
    if (prevProps.profile !== this.props.profile) {
      this.setState({
        profile: this.props.profile,
        favourite: this.props.favourite,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { profile } = this.state;
    const section = sessionStorage.getItem("section");
    const rank =
      section === "0" ? profile.academic_row : profile.influencer_row;
    const score =
      section === "0" ? profile.academic_score : profile.influencer_score;
    const total = loadMetadata().count;
    const url = process.env.PUBLIC_URL + "/" + profile.id;
    return (
      <Card
        sx={{
          width: "315px",
          height: "248px",
          borderRadius: "8px",
          background: "var(--white)",
          boxShadow: "var(--shadow--card--default)",
          cursor: "pointer",
          padding: "12px",
          "&:hover": {
            boxShadow: "var(--shadow--card--hover)",
          },
        }}
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            marginX: "8px",
            marginY: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "42px",
                height: "42px",
              }}
            >
              <Avatar
                alt="User"
                src={process.env.PUBLIC_URL + "/card.svg"}
                sx={{
                  fontSize: "1rem",
                  width: "42px",
                  height: "42px",
                }}
              />
              <img
                src={"/following.svg"}
                style={{
                  display: this.state.favourite ? "block" : "none",
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  top: "0px",
                  left: "34px",
                }}
              />
            </Box>
            <Following
              id={profile.id}
              following={this.state.favourite}
              onChange={(fav: any) => this.setState({ favourite: fav })}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px !important",
                lineHeight: "24px",
                fontWeight: 400,
                color: "var(--primary----dark-blue)",
                marginLeft: "0.0rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                marginBottom: "0.2rem",
              }}
            >
              {profile.name || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <img src={process.env.PUBLIC_URL + "/icons/globe.svg"} alt="" />
            <Typography
              sx={{
                fontSize: "14px !important",
                lineHeight: "20px",
                fontWeight: 400,
                color: "var(--gray---1)",
                marginLeft: "0.2rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                marginBottom: "0.2rem",
              }}
            >
              {profile.affiliation || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <img src={process.env.PUBLIC_URL + "/icons/map-pin.svg"} alt="" />
            <Typography
              sx={{
                fontSize: "14px !important",
                lineHeight: "20px",
                fontWeight: 400,
                color: "var(--gray---1)",
                marginLeft: "0.2rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                marginBottom: "0.2rem",
              }}
            >
              {profile.country || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "row",
              width: "100%",
              marginBottom: "0.2rem",
            }}
          >
            <img src={process.env.PUBLIC_URL + "/icons/file-text.svg"} alt="" />
            <Typography
              sx={{
                fontSize: "14px !important",
                lineHeight: "20px",
                fontWeight: 400,
                color: "var(--gray---1)",
                marginLeft: "0.2rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                marginBottom: "0.15rem",
              }}
            >
              {profile.therapeutic_area || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderTop: "2px solid var(--gray---5)",
              //marginTop: "5px",

              paddingTop: "11px",
              width: "100%",
            }}
          >
            {rank && score ? (
               <Tooltip
               className="tools"
                 title={this.props.t("dashboard.rank.rankTooltip_"+section)}
                 placement="top"
               >
              <Chip
                sx={{
                  background: "var(--acent-2-light)",
                  color: "var(--acent---2)",
                  padding: "4px 12px",
                  gap: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  '&&:hover': {
                    background: "var(--acent-2-medium)",
                    color: "var(--acent---2)",
                  }
                }}
                icon={<img src={"/star-pink.svg"} alt="" />}
                label={
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      lineHeight: "18px",
                      fontWeight: 400,
                      color: "var(--acent---2)",
                    }}
                  >
                    <b>{rank}</b>/{total}
                  </Typography>
                }
              /></Tooltip>
            ) : (
              <Tooltip
              className="tools"
                title={this.props.t("dashboard.rank.noRankTooltip_"+section)}
                placement="top"
              >
                <Chip
                  sx={{
                    background: "var(--acent-2-light)",
                    color: "var(--acent---2)",
                    padding: "4px 12px",
                    gap: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    '&&:hover': {
                      background: "var(--acent-2-medium)",
                      color: "var(--acent---2)",
                    }

                  }}
                  icon={<img src={"/star-pink.svg"} alt="" />}
                  label={
                    <Typography
                      sx={{
                        fontSize: "12px !important",
                        lineHeight: "18px",
                        fontWeight: 400,
                        color: "var(--acent---2)",
                      }}
                    >
                      {this.props.t("dashboard.rank.noRank")}
                    </Typography>
                  }
                />{" "}
              </Tooltip>
            )}
          </Box>
        </Box>
      </Card>
    );
  }
}

export default withTranslation("common")(ProfileCard);
