import { Typography } from "@mui/material";
import TherapeuticArea from "app/components/Dashboard/TherapeuticArea";
import TherapeuticProvider from "app/services/TherapeuticAreaProvider";
import { InterMainTable } from "app/types/InterMainProfile";
import { ProfileTable } from "app/types/ProfileTable";

const ProfileParser = async (
  data: InterMainTable[],
  deleteRow?: Function,
  rising?: boolean
): Promise<ProfileTable[]> => {
  const therapeuticCodes = await TherapeuticProvider.getAllTherapeuticData();
  const pool = data;
  if (pool === undefined || pool === null) return null;
  return await pool.map((item: InterMainTable) => {
    const country = item.country ?? "";
    const favouriteprops = { check: item.favourite, id: item.id_profile };
    const therapeuticprops1 = item.therapeutic_area_1
      ? {
          direction: "column",
          id: therapeuticCodes.find(
            (code) => code.name == item.therapeutic_area_1
          ).id,
          therapeuticArea: item.therapeutic_area_1,
        }
      : { id: -1 };
    const therapeuticprops2 = item.therapeutic_area_2
      ? {
          direction_2: "column",
          id_2: therapeuticCodes.find(
            (code) => code.name == item.therapeutic_area_2
          ).id,
          therapeuticArea_2: item.therapeutic_area_2,
        }
      : {};
    const therapeuticprops3 = item.therapeutic_area_3
      ? {
          direction_3: "column",
          id_3: therapeuticCodes.find(
            (code) => code.name == item.therapeutic_area_3
          ).id,
          therapeuticArea_3: item.therapeutic_area_3,
        }
      : {};
    const therapeuticprops = {
      profile_id: item.id_profile,
      ...therapeuticprops1,
      ...therapeuticprops2,
      ...therapeuticprops3,
    };
    return {
      id: item.id_profile,

      //votes : 0,
      score: <span> {item.score} </span>,

      name: (
        <Typography
          sx={{
            fontWeight: "inherit",
            fontSize: "inherit",
            textTransform: "capitalize",
          }}
        >
          {item.name}
        </Typography>
      ),
      country: `${country ?? ""}`,
      therapeutic_area: (
        <Typography
          sx={{
            fontWeight: "inherit",
            fontSize: "inherit",
            textTransform: "capitalize",
          }}
        >
          {" "}
          {item.therapeutic_area_1 || "-"}{" "}
        </Typography>
      ),
      therapeuticAreaAndSubArea: <TherapeuticArea {...therapeuticprops} />,
      affiliation: item.affiliation ?? "-",
      numberOfPublications:
        Number(item.pubmed_number) + Number(item.clinical_trial_number),
      numberOfAct: item.activity_number,
      ntradeJournal: Number(item.trade_number),
      favourite: item.favourite,
      action: (
        null
      ),
      academic_row: item.academic_row,
      influencer_row: item.influencer_row,
      academic_score: item.academic_score,
      influencer_score: item.influencer_score,

      //numberOfAct: 0,
    } as ProfileTable;
  });
};
export default ProfileParser;
