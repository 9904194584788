import { Redirect, SignIn } from "app/auth";
import { Dashboard, Profile } from "app/pages";
import { NotFound } from "app/pages/error";
import { RoutesDefinitions } from "app/types/RoutesDefinitions";
import AdminPanel from "./pages/AdminPanel";


const routes: RoutesDefinitions[] = [
    {
        path: '/',
        main: <Dashboard />,
        auth: true
    },
    {
        path: '/following',
        main: <Dashboard following={true} />,
        auth: true
    },
    {
        path: '/:id',
        main: <Profile />,
        auth: true
    },
    {
        path: '/admin-panel',
        main: <AdminPanel />,
        auth: true
    },
    //#region Auth
    {
        path: '/signin',
        main: <SignIn location={window.location.href} />,
        auth: false
    },
    {
        path: '/redirect',
        main: <Redirect />,
        auth: false
    },
    //#endregion
    //#region Errors
    {
        path: '*',
        main: <NotFound/>
    },
    {
        path: '/404',
        main: <NotFound/>
    }
]

export default routes;
