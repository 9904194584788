import { Congress } from "app/types/Congress";


const SelectableCongressParser = (
    props: string[]
  ): {
    name: string;
    id: number;
    order: number;
    children: { name: string; id: number }[];
  }[] => {
      if(!props || !props[0]) return null;
      return props.map((section,index) => ({
        name: section,
        id: index,
        order: 0,
        children:
        [
            {
                name: section,
                id: index,
            }
        ] 
        }
      ))
  };

export default SelectableCongressParser;