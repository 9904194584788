let subscribesTableSize: Function[] = [];
export const subscribeTableSize = (callback: (size: number) => void) => {
  subscribesTableSize=  [...subscribesTableSize, callback]
};
export const unsubscribeTableSize = (callback: (size: number) => void) => {
    subscribesTableSize = subscribesTableSize.filter((cb) => cb !== callback);
}
export const notifyTableSize = (size: number) => {
    subscribesTableSize.forEach((cb) => cb(size));
}
