import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FeedIcon from "@mui/icons-material/Feed";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { TabPanel, TopNavbar } from "app/components";
import TherapeuticArea from "app/components/Dashboard/TherapeuticArea";
import TherapeuticSubArea from "app/components/Dashboard/TherapeuticSubArea";
import Loading from "app/components/Load/Loading";
import NoData from "app/components/Load/NoData";
import Cards from "app/components/Profile/Cards";
import InfluenceTab from "app/components/Profile/SocialTab";
import OverviewPanel from "app/components/Profile/OverviewPanel";
import ScientificCharts from "app/components/Profile/ScientificCharts";
import ActivitiesEventsParser from "app/helpers/ActivitiesEventsParser";
import ClinicalTrialParser from "app/helpers/ClinicalTrialParser";
import ScientificParser from "app/helpers/ScientificParser";
import { ProfileProvider } from "app/services/ProfileProvider";
import TherapeuticProvider from "app/services/TherapeuticAreaProvider";
import { ProfileStore } from "app/store/profileStore";
import { Author } from "app/types/Author";
import { Profile as ProfileType } from "app/types/Profile";
import { TherapeuticArea as TherapeuticAreaType } from "app/types/TherapeuticArea";

import { TextField } from "@mui/material";
import DataTable from "app/components/Profile/DataTable";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MDInput from "app/assets/theme/components/MDInput";
import SocialTab from "app/components/Profile/SocialTab";
import { subscribeToProfileStore } from "app/subscribers/SubscriberToGetPubmeds";
class Profile extends React.Component<
  WithTranslation,
  {
    id: number;
    value: number;
    loading: boolean;
    edit: boolean;
    clinical_trial: boolean;
    pubmed: boolean;
    influence: boolean;
    social: boolean;
    data: [
      { columns: { [key: string]: any }[]; rows: { [key: string]: any }[] }[],
      ProfileType,
      boolean
    ];
    therapeutic_props: {
      id?: number;
      therapeuticArea?: string;
      summatory?: number;
    }[];
    twitter_evolution: { [key: string]: number }[];
    followers_evolution: { [key: string]: number }[];
    selectedFilter: string;
    therapeuticAreas: TherapeuticAreaType[];
    subarea_props: {
      subareas: {
        id: number;
        name: string;
        summatory: number;
      }[];
      printAll: boolean;
    };
  }
> {
  constructor(props: any) {
    super(props);

    const id = window.location.pathname.split("/").pop();
    const store = ProfileStore.load(Number(id));
    this.state = {
      id: parseInt(id),
      value: 0,
      loading: true,
      edit: false,
      clinical_trial: false,
      pubmed: false,
      influence: false,
      social: false,
      data: [
        [null, null],
        store?.item ??
          ({
            id: Number(id),
            name: "...",
            email: "...",
            phone: "...",
            affiliation: "...",
            city: "...",
            country: "...",
            experience: new Date(),
            therapeutic_area_1: "...",
            therapeutic_area_2: "...",
            therapeutic_area_3: "...",
            twitterProfile: [],
            profileCompany: [],
            linkedin: null,
            academic_row: 0,
            influencer_row: 0,
            influencer_score: 0,
            academic_score: 0,
            n_cited_by: 0,
          } as ProfileType),
        false,
      ],
      therapeutic_props: [
        {
          id: 0,
          therapeuticArea: "...",
          summatory: 0,
        },
      ],
      subarea_props: null,
      twitter_evolution: [],
      followers_evolution: [],
      selectedFilter: "",
      therapeuticAreas: null,
    };
  }

  componentDidMount() {
    this.initialLoad();
    subscribeToProfileStore(this.loadProfile);
  }

  initialLoad = async () => {
    this.setState({
      therapeuticAreas: await TherapeuticProvider.getAllTherapeuticData(),
    });

    ProfileProvider.getProfileData(this.state.id).then(async () => {
      this.loadProfile();
      ProfileProvider.getProfilePubmeds(this.state.id);
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.state.data[0] != prevState.data[0] ||
      this.state.data[1] != prevState.data[1]
    ) {
      const clinical_trial = this.state.data[1].clinicalTrialNumber != 0;
      const pubmed = this.state.data[1].pubmedNumber != 0;
      const influence =
        this.state.data[0][2] != null && this.state.data[0][2].rows.length > 0;
      const social = this.state.data[1].linkedin != null;
      const value =
        prevState.loading != this.state.loading
          ? clinical_trial
            ? 0
            : pubmed
            ? 1
            : influence
            ? 2
            : social
            ? 3
            : 0
          : this.state.value;

      this.setState({
        clinical_trial: clinical_trial,
        pubmed: pubmed,
        influence: influence,
        social: social,
        value: value,
      });
    }
  }

  loadProfile = async () => {
    await this.refreshTherapeuticProps();
    this.getData(this.state.id).then(async (data: any) => {
      const therapeuticCodes = this.state.therapeuticAreas;
      let subareasprops = null;
      if (!this.state.subarea_props) {
        const sub_response = await ProfileProvider.getAllSubAreas(
          this.state.id
        );
        const subareas = sub_response.map((area) => {
          const parent = therapeuticCodes.find(
            (tc) => tc.id == area.therapeutic_area_id
          );
          const result = parent.therapeuticSubAreas.find(
            (tsa) => tsa.id == area.id
          );
          return {
            id: area.therapeutic_area_id,
            name: result.name,
            summatory: area.summatory,
          };
        });
        subareasprops = {
          subareas: subareas,
          printAll: true,
        };
      }
      const academic =
        (data[0][0] && data[0][0].rows.length > 0) ||
        (data[0][1] && data[0][1].rows.length > 0);
      const influence = data[0][2] != null && data[0][2].rows.length > 0;
      this.setState({
        loading: !academic && !influence,
        data: data,
        subarea_props: subareasprops ?? this.state.subarea_props,
      });
    });
  };

  refreshTherapeuticProps = async () => {
    const therapeuticCodes = this.state.therapeuticAreas;
    const therapeuticprops = this.state.therapeutic_props;
    if (therapeuticprops[0].therapeuticArea == "...") {
    ProfileProvider.getAllAreas(this.state.id).then(
      async (response: TherapeuticAreaType[]) => {
        const therapeuticprops = response.map((area, index) => {
          const parent = therapeuticCodes.find((tc) => tc.id == area.id);
          return {
            id: area.id,
            therapeuticArea: parent.name,
            summatory: area.summatory,
            printOne: true,
          };
        });
        this.setState({
          therapeutic_props: therapeuticprops,
        });
      }
    );
    }
  };

  getData = async (id: number) => {
    const { item, state } = ProfileStore.load(id);
    if (!item) {
      return null;
    }
    const rows = [
      {
        rows: await ScientificParser(item, this.props.t),
        title: "scientificPublications",
      },
      {
        rows: ClinicalTrialParser(item),
        title: "clinicalTrials",
      },
      {
        rows: ActivitiesEventsParser(item),
        title: "activitiesAndEvents",
      },
    ];

    const columns = rows.map((dataset: any) =>
      dataset.rows[0]
        ? Object.keys(dataset.rows[0])
            .filter(
              (key) =>
                key !== "url" &&
                key !== "abstract" &&
                key != "publication_id" &&
                key != "nct_id" &&
                key != "status_id"
            )
            .map((key) => {
              return {
                Header: this.props.t(`profile.${dataset.title}.table.${key}`),
                accessor: key,
                width:
                  key === "title"
                    ? "30vw"
                    : key === "journal"
                    ? "10vw"
                    : undefined,
              };
            })
        : null
    );

    const { email, phone } = item.authors
      ? item.authors.reduce(
          (
            acc: { email: string; phone: string; date: Date },
            author: Author
          ) => {
            if (!acc.date || new Date(author.date) > new Date(acc.date)) {
              acc.email = author.email && author.email != '' ? author.email : acc.email;
              acc.phone = author.phone;
              acc.date = author.date;
            }
            return acc;
          },
          { email: "", phone: "", date: null }
        )
      : { email: "", phone: "" };
    delete item.authors;
    return [
      columns.map((column: any, index: number) => {
        return {
          columns: column,
          rows: rows[index].rows as { [key: string]: any }[],
        };
      }),
      { ...item, email: email, phone: phone },
      state,
    ];
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      value: newValue,
    });
  };

  handleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  handleSelectedFilterChange = (event: number) => {
    const selectedFilter = () => {
      switch (event) {
        case 0:
          return "";
        case 1:
          return this.props.t("profile.scientificPublications.chart.first");
        case 2:
          return this.props.t("profile.scientificPublications.chart.last");
        case 3:
          return this.props.t("profile.scientificPublications.chart.others");
      }
    };
    this.setState({ selectedFilter: selectedFilter() });
  };

  componentWillUnmount() {
    ProfileStore.remove(this.state.id);
  }

  render() {
    const personalData = this.state.data[1];
    const address = this.state.data[1].country;
    const date = new Date();
    const experience = this.state.data[1].experience
      ? date.getFullYear() -
        new Date(this.state.data[1].experience).getFullYear()
      : 0;
    const email = this.state.data[1].email && this.state.data[1].email.slice(-1) === '.' 
      ? this.state.data[1].email.slice(0, -1) 
      : this.state.data[1].email;
    return (
      <div>
        <Loading active={this.state.loading} />
        <Backdrop
          open={this.state.loading}
          sx={{
            zIndex: 1000,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TopNavbar></TopNavbar>
        <div
          className="container"
          style={{
            backgroundColor: "var(--back)",
            flexDirection: "row",
          }}
        >
          <Box className="grid-profile">
            <Box className="box-profile">
              <div className="header-profile">
                <h4>{this.props.t("profile.others.personalData")}</h4>
                {/* <ModeEditOutlineOutlinedIcon className={`edit-button ${this.state.edit? 'selected' : ''}`} onClick={() => this.handleEdit()}/> */}
              </div>
              <MDInput
                autoComplete="off"
                disabled={!this.state.edit}
                sx={{
                  background: "transparent !important",
                }}
                className="input-box"
                InputProps={{
                  startAdornment: <PublicOutlinedIcon className="input-icon" />,
                }}
                label="Affiliations"
                multiline
                value={personalData.affiliation}
              />
              <MDInput
                autoComplete="off"
                disabled={!this.state.edit}
                sx={{
                  background: "transparent !important",
                }}
                type="string"
                className="input-box"
                id="filled-textarea"
                InputProps={{
                  startAdornment: (
                    <AccessTimeOutlinedIcon className="input-icon" />
                  ),
                }}
                label={this.props.t("profile.inputs.experience")}
                value={experience + " years"}
              />
              <MDInput
                autoComplete="off"
                disabled
                sx={{
                  background: "transparent !important",
                  zIndex: 100,
                  //overflowY: "auto"
                }}
                className="input-box"
                InputProps={{
                  startAdornment: this.state.therapeutic_props ? (
                    <>
                      <FeedIcon className="input-icon" />
                      <Box
                        sx={{
                          margin: "0.5rem",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        {this.state.therapeutic_props.map((item) => (
                          <Box
                            sx={{
                              width: "100%",
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <TherapeuticArea {...item} />
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "14px",
                                color: "var(--gray---1)",
                                fontWeight: 400,
                                marginLeft: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                              {item.summatory}{" "}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </>
                  ) : null,
                }}
                label={this.props.t("profile.inputs.therapeuticArea")}
              />
              <MDInput
                autoComplete="off"
                disabled
                sx={{
                  background: "transparent !important",
                  zIndex: 100,
                  //overflowY: "auto"
                }}
                className="input-box"
                InputProps={{
                  startAdornment: this.state.subarea_props ? (
                    <>
                      <FeedIcon className="input-icon" />
                      <Box
                        sx={{
                          margin: "0.5rem",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        {this.state.subarea_props.subareas.map((item) => (
                          <Box
                            sx={{
                              width: "100%",
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <TherapeuticSubArea
                              subareas={[item]}
                              printOne={true}
                            />
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "14px",
                                color: "var(--gray---1)",
                                fontWeight: 400,
                                marginLeft: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                              {item.summatory}{" "}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </>
                  ) : null,
                }}
                label={this.props.t("profile.inputs.therapeuticSubArea")}
              />
              <MDInput
                autoComplete="off"
                disabled={!this.state.edit}
                sx={{
                  background: "transparent !important",
                }}
                className="input-box"
                InputProps={{
                  startAdornment: (
                    <LocationOnOutlinedIcon className="input-icon" />
                  ),
                }}
                label={this.props.t("profile.inputs.country")}
                multiline
                value={address}
              />
              { process.env.REACT_APP_SRC_APP_PAGES_PROFILE && process.env.REACT_APP_SRC_APP_PAGES_PROFILE === 'true' && <MDInput
                autoComplete="off"
                sx={{
                  background: "transparent !important",
                }}
                type="string"
                className="input-box"
                InputProps={{
                  startAdornment: (
                    <EmailOutlinedIcon className="input-icon" />
                  ),
                }}
                label={this.props.t("profile.inputs.email")}
                value={email}
              />}
            </Box>
          </Box>
          <Box className="grid-table">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1rem",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              <Link className="link-back" to={"/"}>
                <ArrowBackIcon />{" "}
                <Box sx={{ marginTop: "auto", marginBottom: "auto" }}>
                  {this.props.t("profile.others.backToTable")}
                </Box>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Avatar
                className="account-icon"
                alt="User"
                src={process.env.PUBLIC_URL + "/user.png"}
                sx={{
                  fontSize: "1rem",
                  marginTop: "auto",
                  marginBottom: "auto",
                  width: "4rem",
                  height: "4rem",
                }}
              ></Avatar>
              <h1 className="title-profile">{personalData.name}</h1>
              {this.state.social ? (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      this.state.data[1].linkedin.account_url,
                      "_blank"
                    );
                  }}
                >
                  <img src={process.env.PUBLIC_URL + "/linkedin.svg"} />
                </Box>
              ) : null}
            </Box>

            <Box className="tab-options-profile" width={"70%"}>
              <Tabs
                sx={{
                  display:
                    this.state.clinical_trial ||
                    this.state.pubmed ||
                    this.state.influence
                      ? "flex"
                      : "none",
                }}
                className="tab-labels"
                value={this.state.value}
                onChange={this.handleChange}
              >
                {/* <Tab
                    className="tab-button"
                    label={this.props.t("profile.overview.title")}
                  />{" "}
                  * */}
                <Tab
                  sx={{
                    display: this.state.clinical_trial ? "flex" : "none",
                  }}
                  className="tab-button"
                  label={this.props.t("profile.clinicalTrials.title")}
                />
                <Tab
                  sx={{
                    display: this.state.pubmed ? "flex" : "none",
                  }}
                  className="tab-button"
                  label={this.props.t("profile.scientificPublications.title")}
                />

                <Tab
                  sx={{
                    display: this.state.influence ? "flex" : "none",
                  }}
                  className="tab-button"
                  label={this.props.t("profile.activitiesEvents.title")}
                />
                {/* <Tab
                  sx={{
                    display: this.state.social ? "flex" : "none",
                  }}
                  className="tab-button"
                  label={this.props.t("profile.social.title")}
                /> */}
              </Tabs>
            </Box>
            <Box className="tabs">
              {/* <TabPanel value={this.state.value} index={0}>
                  <OverviewPanel
                    data={{
                      clinicalData: this.state.data[0][1].rows,
                  }}
                  />
                </TabPanel> */}
              <TabPanel value={this.state.value} index={1}>
                {this.state.data[1]?.pubmed_meta &&
                this.state.data[1].pubmedNumber > 0 ? (
                  <ScientificCharts
                    data={{
                      first: this.state.data[1].pubmed_meta.role_first,
                      last: this.state.data[1].pubmed_meta.role_last,
                      others: this.state.data[1].pubmed_meta.role_intermediate,
                    }}
                    selectedChange={this.handleSelectedFilterChange}
                  />
                ) : null}
                {this.state.data[0][0] && this.state.data[0][0].columns ? (
                  <>
                    <DataTable
                      link
                      linkKey="url"
                      table={
                        this.state.selectedFilter === ""
                          ? this.state.data[0][0]
                          : {
                              columns: this.state.data[0][0].columns,
                              rows: this.state.data[0][0].rows.filter(
                                (row) => row.role === this.state.selectedFilter
                              ),
                            }
                      }
                      canSearch
                      showTotalEntries={true}
                      entriesPerPage={false}
                      tableWidth="72vw"
                    />
                    {!this.state.data[2] ? (
                      <CircularProgress
                        sx={{
                          position: "absolute",
                          bottom: "3rem",
                          left: "50%",
                          width: "1rem!important",
                          height: "1rem!important",
                          zIndex: "1",
                          color: "var(--gray---2)",
                        }}
                        className="loader"
                      />
                    ) : null}
                  </>
                ) : !this.state.data[2] &&
                  this.state.data[1].pubmedNumber > 0 ? (
                  this.state.loading ? null : (
                    <Box sx={{ width: "80%", display: "flex" }}>
                      <CircularProgress
                        color="inherit"
                        sx={{ margin: "auto" }}
                      />
                    </Box>
                  )
                ) : this.state.loading ? null : (
                  <NoData />
                )}
              </TabPanel>
              <TabPanel value={this.state.value} index={0}>
                {this.state.data[0][1] && this.state.data[0][1].columns ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DataTable
                      link
                      linkKey="url"
                      table={this.state.data[0][1]}
                      canSearch
                      showTotalEntries={true}
                      entriesPerPage={false}
                      tableWidth="72vw"
                      metricsPanel={
                        <OverviewPanel
                          data={{
                            clinicalData: this.state.data[0][1].rows,
                          }}
                        />
                      }
                    />
                  </Box>
                ) : (
                  <NoData />
                )}
              </TabPanel>
              <TabPanel value={this.state.value} index={2}>
                {this.state.data[0][2] && this.state.data[0][2].columns ? (
                  <Cards {...{ data: this.state.data[0][2] }} />
                ) : (
                  <NoData />
                )}
              </TabPanel>
              <TabPanel value={this.state.value} index={3}>
                {this.state.social ? (
                  <SocialTab data={this.state.data[1].linkedin} />
                ) : (
                  <NoData />
                )}
              </TabPanel>
            </Box>
          </Box>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Profile);
