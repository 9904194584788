
import { JSXElementConstructor, useRef, useState } from "react";

// @mui material components

// Material Dashboard 2 PRO React TS components
import { Box, Tooltip } from "@mui/material";

type Key = string | number

interface ReactElement<P = any, T extends string | JSXElementConstructor<any> = string | JSXElementConstructor<any>> {
   type: T;
   props: P;
   key: Key | null;
}
// Declaring prop types for DataTableBodyCell
interface Props {
  children: ReactElement;
  noBorder?: boolean;
  align?: "left" | "right" | "center";
  left?: boolean;
  right?: boolean;
  rows?: number;
  row_width?: string | number;
}

function DataTableBodyCell({ noBorder, align, children, left, right, rows, row_width }: Props): JSX.Element {
  const childrentype = typeof children.props?.value;
  const element = useRef<HTMLTableCellElement>(null);
  const width = (() =>{
    switch (childrentype) {
      case "object":
        return "fit-object";
      case "boolean":
        return "1.5vw";
      case "number":
        return "3vw";
      case "string":

        if(new Date(children.props.value).toString() !== "Invalid Date"){

          return "3.5vw";
        }
        return "7vw";
      default:
        return "3.5vw";
    }
  })
  const [size, setSize] = useState(0)
  const maxWidthPossible = () => 
  {
    setSize(Number(element.current?.clientWidth)* 0.95)
  }
  window.addEventListener('resize', maxWidthPossible);
  
  return (
    <Box
      component="td"
      textAlign={"left"}
      py={0}
      height={'4rem'}
      pl={1.5}
      ref={element}

      sx={{
        fontSize: "12px",
        backgroundColor: "var(--white)",
        //boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.15)',
        //minWidth: row_width?? width,
        maxWidth: row_width?? width,
        //maxWidth: '100%',
        marginX: "auto",
        boderRadius: left? '30px': right? '0.5vw' :  '0 0 0 0',
        width:  'min-content',
        
      }}
    > {childrentype === "object" ? children :
      <Box
       
        //width="min-content"
        color='var(--gray---1)' 
        className="overflow"
        sx={{ 
          display:"inline-block",
          verticalAlign: "middle",
          justifyContent: "start",
         
          width: size == 0? '100%' :  size, 
          zIndex: 1,
          boderRadius: left? '30px': right? '0.5vw' :  '0 0 0 0',
          //border: '5px solid var(--back)',
      }}
      >
        { (/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(children.props?.value)) ? 
        <div dangerouslySetInnerHTML={{ __html:children.props?.value}}></div> 
        : childrentype == "number" ?
         children :
          <Tooltip title={children}>
          <Box sx={{ 
          display:"inline-block",
          verticalAlign: "middle",
          maxWidth: '100%',
          flexWrap: "wrap",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          zIndex: 1,
        }} 
      >{children}</Box>
         </Tooltip>
        
        }
     </Box>}

        
     
    </Box>
  );
}

// Declaring default props for DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

export default DataTableBodyCell;