import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box, Checkbox,
  Chip,
  debounce, FormControl,
  OutlinedInput,
  Radio,
  Select
} from "@mui/material";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
type Source = {
  name: string;
  order: number;
  children: { id: number; name: string; order?: number }[];
};

class CountryFilter extends React.Component<
  WithTranslation & {
    emitFilter: Function;
    dataInput: Source[];
    emitRestore: number;
    metaText: { placeholder: string; label: string; icon: string };
    dontSimplify: boolean;
    childrenstyle: string;
    selected: string[];
    dataSelected: { name: string; children: string[] }[];
    mostpopular: boolean;
    disabled: boolean;
  },
  {
    data_source: Source[];
    openChilds: Source;
    Selected: string[];
    dataSelected: { name: string; children: string[] }[];
    open: boolean;
    icon: string;
    dontChangeSource: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      data_source: this.props.dataInput,
      openChilds: null,
      dataSelected: this.props.dataSelected,
      Selected: [""],
      open: false,
      icon: props.metaText.icon,
      dontChangeSource: false,
    };
  }

  componentDidMount() {
    this.emitFilter = debounce(this.emitFilter, 300);
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.emitRestore !== this.props.emitRestore) {
      this.resetFilters();
    }
  }

  resetFilters = () => {
    this.setState({
      dataSelected: [],
      Selected: [""],
      openChilds: null,
      open: false,
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.Selected !== this.state.Selected && this.state.data_source) {
      this.setState({
        dontChangeSource: this.state.Selected.length > 0 && this.state.Selected[0] !== "" ? true : false,
      });
      this.emitFilter();
    }
    if (
      prevState.dataSelected !== this.state.dataSelected &&
      prevState.Selected === this.state.Selected &&
      this.state.data_source
    ) {
      this.changeSelected();
    }
    if (prevProps.dataInput !== this.props.dataInput && !this.state.dontChangeSource) {
      //filter data that will appear exactly equal, 1 children, 1 same name
      const newdata = this.props.dataInput ? this.props.dataInput.reduce((acc, item) => {
        if (item.children.length === 1) {
          const isExist = acc.find(
            (t) =>
              t.children.length === 1 &&
              t.children[0].name === item.children[0].name
          );
          if (!isExist) {
            acc.push(item);
          }
        } else {
          acc.push(item);
        }
        return acc;
      }, []) : [];
      
      this.setState({
        data_source: newdata,
      });
    }
    if (prevProps.selected !== this.props.selected && this.props.selected) {
      this.setState({
        Selected: this.props.selected,
      });
    }
    if (
      this.props.dataSelected &&
      prevProps.dataSelected !== this.props.dataSelected
    ) {
      this.setState({
        dataSelected:
          this.props.dataSelected.length > 0 ? this.props.dataSelected : [],
      });
    }
  }

  emitFilter = async () => {
    this.props.emitFilter([this.state.Selected, this.state.dataSelected]);
  };

  handleChangeCountry = (checked: boolean, value: string, data: Source, isFather?: boolean) => {
    checked = this.state.Selected[0] === "" ? true : checked;
    const { dataSelected } = this.state;
    if (value === "all") {
      this.setState({
        dataSelected: [],
        Selected: [""],
      });
    } else {
      if (checked) {
        if (isFather) {
          if (dataSelected) {
            this.setState({
              dataSelected: [
                ...dataSelected.filter((item) => item.name !== value),
                {
                  name: value,
                  children: data.children.map((item) => item.name),
                },
              ],
            });
          } else {
            this.setState({
              dataSelected: [
                {
                  name: value,
                  children: data.children.map((item) => item.name),
                },
              ],
            });
          }
        } else {
          if (dataSelected) {
            if (dataSelected.find((item) => item.name === data.name)) {
              this.setState({
                dataSelected: dataSelected.map((item) => {
                  if (item.name === data.name) {
                    return {
                      name: data.name,
                      children: [...item.children, value],
                    };
                  }
                  return item;
                }),
              });
            } else {
              this.setState({
                dataSelected: [
                  ...dataSelected,
                  { name: data.name, children: [value] },
                ],
              });
            }
          } else {
            this.setState({
              dataSelected: [{ name: data.name, children: [value] }],
            });
          }
        }
      }
      if (!checked) {
        if (isFather) {
          const newdataSelected = dataSelected.filter(
            (item) => item.name !== value
          );
          this.setState({
            dataSelected: newdataSelected,
          });
        } else {
          const newdataSelected = dataSelected.map((item) => {
            if (item.children.findIndex((child) => child === value) !== -1) {
              return {
                name: item.name,
                children: item.children.filter((item) => item !== value),
              };
            }
            return item;
          });

          this.setState({
            dataSelected: newdataSelected.filter(
              (item) => item.children.length > 0
            ),
          });
        }
      }
    }
  };

  changeSelected = () => {
    const { dataSelected } = this.state;
    if (dataSelected) {
      const Selected = dataSelected
        .map((item) => item.children)
        .flat()
        .filter((item, index, self) => self.indexOf(item) === index);
      this.setState({
        Selected: Selected.length > 0 ? Selected : [""],
      });
    }
  };

  handleOpenCountries = (event: any) => {
    const countries = this.state.data_source.find(
      (item) => item.name === event
    );
    this.setState({ openChilds: countries });
  };

  handleCountryDelete = (event: string) => {
    const newdataSelected = this.state.dataSelected.map((item) => {
      return {
        name: item.name,
        children: item.children.filter((item) => item !== event),
      };
    });
    this.setState({
      dataSelected: newdataSelected.filter((item) => item.children.length > 0),
      Selected: this.state.Selected.filter((item) => item !== event),
    });
  };

  handleClose = (event: any) => {
    this.setState({
      //openChilds: null,
      open: false,
    });
    setTimeout(() => {
      (document.activeElement as HTMLElement).blur();
    }, 0);
    // transparent 100% react-table
    const table = document.getElementById("react-table");
    if (table) {
      (table as HTMLElement).style.opacity = "1";
    }
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
    // transparent 20% react-table
    const table = document.getElementById("react-table");
    if (table) {
      (table as HTMLElement).style.opacity = "0.2";
    }
  };

  renderChipCountry = () => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {this.state.Selected.map((value) =>
        value != "" && value.at(0) != "_" ? (
          <Chip
            key={value}
            label={value}
            onDelete={() => this.handleCountryDelete(value)}
          />
        ) : (
          ""
        )
      )}
    </Box>
  );

  renderMenu = () => (
    <FormControl sx={{ height: "44px" }} variant="standard" fullWidth>
      <Select
        disabled={this.props.disabled}
        sx={{
          height: "44px",
          color: "var(--primary----blue)",
          cursor: "default",
          position: "relative",
          borderColor: "red",
          "&:disabled": {
            borderColor: "inherit",
            color: "inherit",

          },
        }}
        input={
          <OutlinedInput
            sx={{
              height: "44px",
              color: "var(--primary----blue)",
              cursor: "default",
              position: "relative",
              borderColor: "red",
              // on hover, focus or active
              "&:hover": {
                borderColor: "var(--primary----blue-2)",
              },
              // on disabled
              "&:disabled": {
                borderColor: "inherit",
                color: "inherit",

              },
            }}
          />
        }
        value={[""]}
        multiple
        onMouseEnter={() =>
          this.setState({
            icon: this.props.metaText.icon.replace(".svg", "-blue.svg"),
          })
        }
        onMouseLeave={() =>
          this.setState({
            icon: this.state.open
              ? this.props.metaText.icon.replace(".svg", "-blue.svg")
              : this.props.metaText.icon,
          })
        }
        onClose={(event) => {
          this.handleClose(event);
          this.setState({ icon: this.props.metaText.icon });
        }}
        onOpen={this.handleOpen}
        onClick={(event) => event.stopPropagation()}
        renderValue={() => {
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                justifyContent: "flex-start",
                alignItems: "center",
                color: "var(--primary----blue) !important",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              <img src={this.state.icon} alt="icon" />
              {this.props.metaText.label}{" "}
              {this.state.open ? (
                <KeyboardArrowUpIcon
                  sx={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    color: "var(--primary----blue)",
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    color: "var(--primary----blue)",
                  }}
                />
              )}{" "}
            </Box>
          );
        }}
      >
        <Box
          sx={{
            marginX: "20px",
            cursor: "default",
            color: "var(--primary----dark-blue)",
          }}
        >
          <Radio
            sx={{
              color: "var(--primary----blue)",
              "&$checked": {
                color: "var(--primary----blue)",
              },
              "&.Mui-checked": {
                color: "var(--primary----blue)",
              },
              "&.MuiCheckbox-indeterminate": {
                color: "var(--primary----blue)",
              },
            }}
            checked={
              this.state.Selected[0] == null || this.state.Selected[0] == ""
            }
            onClick={(event: any) => (
              event.stopPropagation(),
              this.handleChangeCountry(event.target.checked, "all", null)
            )}
          />
          {this.props.metaText.placeholder}
        </Box>
        <Box
          sx={{
            display: this.props.mostpopular ?  "block" :"none" ,
            marginX: "20px",
            color: "var(--gray---2)",
            textTransform: "uppercase",
            paddingBottom: "5px",
            marginBottom: "5px",
            borderBottom: "1px solid var(--primary----blue-ultra-light)",
          }}
        >
          {this.props.t("dashboard.filters.mostPopular")}
        </Box>
        <Box
          sx={{
            marginX: "10px",
            display: this.state.open && this.props.mostpopular ? "grid" : "none",
            gap: "10px",
            width: "652px",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {this.state.data_source
            ?.slice(0, 3)
            .flatMap((data: Source, index: number) => {
              const result = [
                <Box
                  sx={{
                    display: "inline-grid",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                      color: "var(--primary----dark-blue)",
                      fontWeight: 600,
                      cursor: "default",
                      "&:hover": {
                        backgroundColor: "var(--primary----blue-ultra-light)",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    <Checkbox
                      sx={{
                        color: "var(--primary----blue)",
                        "&.Mui-checked": {
                          color: "var(--primary----blue)",
                        },
                        "&.MuiCheckbox-indeterminate": {
                          color: "var(--primary----blue)",
                        },
                      }}
                      indeterminate={
                        this.state.Selected[0] != "" &&
                        this.state.dataSelected?.findIndex(
                          (item) => item.name === data.name
                        ) > -1 &&
                        this.state.dataSelected?.find(
                          (item) => item.name === data.name
                        )?.children.length < data.children.length
                      }
                      checked={
                        this.state.Selected[0] != "" &&
                        this.state.dataSelected?.find(
                          (item) => item.name === data.name
                        )?.children.length === data.children.length
                      }
                      onClick={(event: any) => (
                        event.stopPropagation(),
                        this.handleChangeCountry(
                          event.target.checked,
                          data.name,
                          data,
                          true
                        )
                      )}
                    />
                    {data.children.length === 1 && !this.props.dontSimplify
                      ? data.children[0].name
                      : data.name}
                    {data.children.length > 1 || this.props.dontSimplify ? (
                      data?.name == this.state.openChilds?.name ? (
                        <KeyboardArrowUpIcon
                          sx={{ cursor: "pointer", marginLeft: "auto" }}
                          onClick={(event) => this.handleOpenCountries(null)}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ cursor: "pointer", marginLeft: "auto" }}
                          onClick={(event) =>
                            this.handleOpenCountries(data.name)
                          }
                        />
                      )
                    ) : null}
                  </Box>
                  {(this.state.openChilds?.children.length > 1 ||  this.props.dontSimplify )&&
                  data.name == this.state.openChilds?.name
                    ? this.state.openChilds?.children
                        .sort((a, b) => {
                          return (
                            (a.order === 0 ? Infinity : a.order) -
                            (b.order === 0 ? Infinity : b.order)
                          );
                        })
                        .map((item) => (
                          <Box
                            sx={{
                              alignItems: "center",
                              marginLeft: "0",
                              display: "block",
                              textTransform: "capitalize",
                              cursor: "default",
                              color: "var(--grey---1)",
                            }}
                          >
                            <Checkbox
                              sx={{
                                color: "var(--primary----blue)",
                                "&.Mui-checked": {
                                  color: "var(--primary----blue)",
                                },
                                "&.MuiCheckbox-indeterminate": {
                                  color: "var(--primary----blue)",
                                },
                              }}
                              onClick={(event: any) => (
                                event.stopPropagation(),
                                this.handleChangeCountry(
                                  event.target.checked,
                                  item.name.charAt(0) + item.name.slice(1),
                                  data
                                )
                              )}
                              checked={
                                this.state.Selected?.findIndex(
                                  (select) =>
                                    select ===
                                    item.name.charAt(0) + item.name.slice(1)
                                ) > -1
                              }
                            />
                            {item.name.charAt(0) + item.name.slice(1)}
                          </Box>
                        ))
                    : null}
                </Box>,
              ];

              return result;
            })}
        </Box>
        <Box
          sx={{
            display: this.props.mostpopular ?  "block" :"none" ,
            marginTop: "10px",
            marginX: "20px",
            color: "var(--gray---2)",
            textTransform: "uppercase",
            paddingBottom: "5px",
            marginBottom: "5px",
            borderBottom: "1px solid var(--primary----blue-ultra-light)",
          }}
        >
          {this.props.t("dashboard.filters.others")}
        </Box>
        <Box
          sx={{
            marginX: "10px",
            display: this.state.open ? "grid" : "none",
            width: "652px",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {this.state.data_source
            ?.slice(this.props.mostpopular? 3 : 0)
            .flatMap((data: Source, index: number) => {
              const result = [
                <Box
                  sx={{
                    display: "inline-grid",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      textTransform: "capitalize",
                      textAlign: "left",
                      cursor: "default",
                      color: "var(--primary----dark-blue)",
                      padding: "4px 4px",
                      lineHeight: "26px",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "var(--primary----blue-ultra-light)",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    <Checkbox
                      sx={{
                        color: "var(--primary----blue)",
                        "&.Mui-checked": {
                          color: "var(--primary----blue)",
                        },
                        "&.MuiCheckbox-indeterminate": {
                          color: "var(--primary----blue)",
                        },
                      }}
                      indeterminate={
                        this.state.Selected[0] != "" &&
                        this.state.dataSelected?.findIndex(
                          (item) => item.name === data.name
                        ) > -1 &&
                        this.state.dataSelected?.find(
                          (item) => item.name === data.name
                        )?.children.length < data.children.length
                      }
                      checked={
                        this.state.Selected[0] != "" &&
                        this.state.dataSelected?.find(
                          (item) => item.name === data.name
                        )?.children.length === data.children.length
                      }
                      onClick={(event: any) => (
                        event.stopPropagation(),
                        this.handleChangeCountry(
                          event.target.checked,
                          data.name,
                          data,
                          true
                        )
                      )}
                    />
                    {data.children.length === 1 && !this.props.dontSimplify
                      ? data.children[0].name
                      : data.name}
                    {data.children.length > 1 || this.props.dontSimplify? (
                      data?.name == this.state.openChilds?.name ? (
                        <KeyboardArrowUpIcon
                          sx={{ cursor: "pointer", marginLeft: "auto" }}
                          onClick={(event) => this.handleOpenCountries(null)}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ cursor: "pointer", marginLeft: "auto" }}
                          onClick={(event) =>
                            this.handleOpenCountries(data.name)
                          }
                        />
                      )
                    ) : null}
                  </Box>
                  {(this.state.openChilds?.children.length > 1 || this.props.dontSimplify) &&
                  data.name == this.state.openChilds?.name
                    ? this.state.openChilds?.children
                        .sort((a, b) => {
                          return (
                            (a.order === 0 ? Infinity : a.order) -
                            (b.order === 0 ? Infinity : b.order)
                          );
                        })
                        .map((item) => (
                          <Box
                            sx={{
                              alignItems: "center",
                              marginLeft: "0",
                              display: "block",
                              textTransform: "capitalize",
                              cursor: "default",
                              color: "var(--grey---1)",
                            }}
                          >
                            <Checkbox
                              sx={{
                                color: "var(--primary----blue)",
                                "&.Mui-checked": {
                                  color: "var(--primary----blue)",
                                },
                                "&.MuiCheckbox-indeterminate": {
                                  color: "var(--primary----blue)",
                                },
                              }}
                              onClick={(event: any) => (
                                event.stopPropagation(),
                                this.handleChangeCountry(
                                  event.target.checked,
                                  item.name.charAt(0) + item.name.slice(1),
                                  data
                                )
                              )}
                              checked={
                                this.state.Selected?.findIndex(
                                  (select) =>
                                    select ===
                                    item.name.charAt(0) + item.name.slice(1)
                                ) > -1
                              }
                            />
                            {item.name.charAt(0) + item.name.slice(1)}
                          </Box>
                        ))
                    : null}
                </Box>,
              ];

              return result;
            })}
        </Box>
      </Select>
    </FormControl>
  );

  render = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "316px",
        height: "44px",
        //marginRight: "20px",
        borderTop: "1px solid var(--light-gray---1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          color: "var(--primary---blue-3)",
          backgroundColor: "var(--back)",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "18px",
          width: "100%",
          height: "44px",
          minHeight: "44px",
          flexDirection: "column",
        }}
      >
        {this.renderMenu()}
      </Box>
      {/* <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          color: "var(--primary---blue-1)",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "18px",
          width: "100%",
          height: "auto",
          flexDirection: "column",
        }}
      >
        {this.renderChipCountry()}
      </Box> */}
    </Box>
  );
}

export default withTranslation("common")(CountryFilter);
