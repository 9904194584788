/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import borders from "app/assets/theme/base/borders";
import colors from "app/assets/theme/base/colors";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "app/assets/theme/functions/pxToRem";
import linearGradient from "app/assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, info } = colors;

// types
type Types = any;

const checkbox: Types = {
  styleOverrides: {
    root: {
      backgroundImage: `url(/icons/checkbox/inactive/default.svg)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      padding: '13px',
      border: "none",
      "& .MuiSvgIcon-root": {
        display: "none",
        
      },
      "&:disabled": {
        backgroundImage: `url(/icons/checkbox/inactive/disabled.svg)`,
      },

      "&:hover": {
        backgroundImage: `url(/icons/checkbox/inactive/hover.svg)`,
      },

      "&.Mui-focusVisible": {
        backgroundImage: `url(/icons/checkbox/inactive/focus.svg)`,
      },

    "&.Mui-checked": {
      backgroundImage: `url(/icons/checkbox/active/default.svg)`,
      "&:disabled": {
        backgroundImage: `url(/icons/checkbox/active/disabled.svg)`,
      },
      "&:hover": {
        backgroundImage: `url(/icons/checkbox/active/hover.svg)`,
      },
      "&.Mui-focusVisible": {
        backgroundImage: `url(/icons/checkbox/active/focus.svg)`,
      },
    },

    "&.MuiCheckbox-indeterminate": {
      backgroundImage: `url(/icons/checkbox/indeterminate/default.svg)`,
      "&:disabled": {
        backgroundImage: `url(/icons/checkbox/indeterminate/disabled.svg)`,
      },
      "&:hover": {
        backgroundImage: `url(/icons/checkbox/indeterminate/hover.svg)`,
      },
      "&.Mui-focusVisible": {
        backgroundImage: `url(/icons/checkbox/indeterminate/focus.svg)`,
      },
    },
  },
},

};

export default checkbox;
