// subscribe to the profile store path:"app/store/profileStore.tsx"

import { Profile } from "app/types";

let subscribesToProfileStore: Function[] = [];

export const subscribeToProfileStore = (
  callback: (profile: Profile) => void
) => {
  subscribesToProfileStore = [...subscribesToProfileStore, callback];
};

export const unsubscribeToProfileStore = (
  callback: (profile: Profile) => void
) => {
  subscribesToProfileStore = subscribesToProfileStore.filter(
    (cb) => cb !== callback
  );
};

export const notifyToProfileStore = (profile: Profile) => {
  subscribesToProfileStore.forEach((cb) => cb(profile));
};
