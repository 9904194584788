
import { ReactNode, useMemo } from "react";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import { Box, Typography } from "@mui/material";

// DefaultDoughnutChart configurations
import configs from "./configs";
import './doughnut.css';
// Declaring props types for DefaultDoughnutChart
interface Props {
  icon?: {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  width?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColors: string[];
      data: number[];
    };
    cutout?: number;
  };
  [key: string]: any;
}

function DefaultDoughnutChart({ icon, title, description, height,width, chart }: Props): JSX.Element {
  const { data, options } = configs(chart.labels || [], chart.datasets || {}, chart.cutout);

  const renderChart = (
    <Box >
      {title || description ? (
        <Box display="flex" >
          {icon.component && (
            <Box
              width="4rem"
              height="4rem"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </Box>
          )}
          <Box >
            {title && <Typography variant="h6">{title}</Typography>}
            <Box mb={2}>
              <Typography component="div" variant="button" color="text">
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {useMemo(
        () => (
          <Box sx={{
            position: 'relative',
          }} width={width} height={height} marginX={'auto'}>
            <Doughnut data={data} options={options} />
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              fontWeight: 700,
              fontSize: '1rem',
              color: chart.datasets.backgroundColors[0],
              zIndex: 2,
            }}>
              {chart.datasets.data[0]}
            </Box>
          </Box>
        ),
        [chart, height]
      )}
    </Box>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Declaring default props DefaultDoughnutChart
DefaultDoughnutChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  width: "100%",
};

export default DefaultDoughnutChart;
