import React from 'react';
import {   Outlet } from "react-router-dom";
import useAuth from './AuthService';
import SignIn from './SignIn';



const GuardedRoute =  ()  => {
    const { isAuthenticated, isLoading } = useAuth();
    
    return isLoading? 
            <div>Loading...</div> :
        isAuthenticated? 
            <Outlet /> :
            <SignIn location=''/>
}



export default GuardedRoute;

