import { ProfileStore } from "app/store/profileStore";
import { Profile } from "app/types";
import { FilterOptions } from "app/types/FilterOptions";
import { InterMainTable } from "app/types/InterMainProfile";
import { Keyword } from "app/types/Keyword";
import { Metadata } from "app/types/Metadata";
import { Paginated } from "app/types/Paginated";
import { TherapeuticArea } from "app/types/TherapeuticArea";
import { TherapeuticSubAreaRaw } from "app/types/TherapeuticSubAreaRaw";
import { axios, checkStatus, header_options, url } from "./ProviderHelpers";

const getAllProfilesData = async (skip?:number, limit?:number, score_tab?: string , options?: FilterOptions): Promise<Paginated<InterMainTable>& {metadata: Metadata}> => {
    try{
       
        const params = {
            skip: skip || null,
            limit: limit || null,
            score: score_tab || null,
            options: JSON.stringify(options) || null
        };
        const body = {
            
        }

    
        const response = await axios.get(`${url}/profile`,
            {
                ...header_options,
                params: params
            }
        );


        return  response.data.data as Paginated<InterMainTable> & {metadata: Metadata};
        }
    catch(error:any){
       await checkStatus(error.response);
       //console.error(error);
    };     
}

const getProfileData = async (id: number): Promise<Profile> => {
    try{
        const response = await axios.get(`${url}/profile/${id}`,
            {
                ...header_options
            }
        )
        ProfileStore.save(response.data.data as Profile, false);

        return  response.data.data as Profile;
        }
    catch(error: any){
        await checkStatus(error.response);
        
    };
}

const getProfilePubmeds = async (id: number): Promise<string[]> => {
    try{
        const response = await axios.get(`${url}/profile/${id}/pubmeds`,
            {
                ...header_options,
                onDownloadProgress: async (progressEvent) => {
                    const data  = progressEvent.target.response.split("\n")
                    const object =  data.map((item:string) => item!= ""? JSON.parse(item) as Profile : null)
                    ProfileStore.storePubmeds(object.filter((it:any) => it));

                }
            }
        )
        return  response.data.data as string[];
        }
    catch(error: any){
        await checkStatus(error.response);
    };
}   

const getProfileTweetsEvolution = async (id: number): Promise<{[key:string] :number}[]> => {
    try{
        const response = await axios.get(`${url}/profile/${id}/twitter`,
            {
                ...header_options
            }
        )
        return  response.data.data as {[key:string] :number}[];
        }
    catch(error: any){
        await checkStatus(error.response);
    };
}
             
const getProfileFollowersEvolution = async (id: number): Promise<{[key:string] :number}[]> => {
    try{
        const response = await axios.get(`${url}/profile/${id}/followers`,
            {
                ...header_options
            }
        )
        return  response.data.data as {[key:string] :number}[];
        }
    catch(error: any){
        await checkStatus(error.response);
    };
}


const updateProfileData = async (id: number, profile: Profile): Promise<Profile> => {
    try{
        const response = await axios.put(`${url}/profile/${id}`,
            {
                
                profile: profile
            },
            {...header_options}
        )
        


        return  response.data.data as Profile;
        }
    catch(error: any){

        await checkStatus(error.response);
        
    };
}

const getAllAreas = async (id: number): Promise<TherapeuticArea[]> => {
    try {
        const response = await axios.get(`${url}/therapeutic/${id}`,
            {
                ...header_options,
            }
        );
        return response.data.data as TherapeuticArea[];
    }
    catch (error:any) {
        await checkStatus(error.response);
    }
}

const getAllSubAreas = async (id: number): Promise<TherapeuticSubAreaRaw[]> => {
    try {
        const response = await axios.get(`${url}/therapeutic/${id}/subarea`,
            {
                ...header_options,
            }
        );
       
        return response.data.data as TherapeuticSubAreaRaw[];
    }
    catch (error:any) {
        await checkStatus(error.response);
    }
}



class ProfileProvider {
    public static getAllProfilesData = getAllProfilesData;
    public static getProfileData = getProfileData;
    public static getProfilePubmeds = getProfilePubmeds;
    public static updateProfileData = updateProfileData;
    public static getProfileTweetsEvolution = getProfileTweetsEvolution;
    public static getProfileFollowersEvolution = getProfileFollowersEvolution;
    public static getAllAreas =getAllAreas;
    public static getAllSubAreas =getAllSubAreas;
}


   

export {
    ProfileProvider
};
