import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import DefaultDoughnutChart from "./DoughnutCharts/DefaultDoughnutChart";
import PieChart from "./PieChart";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

class ScientificCharts extends React.Component<
  WithTranslation & {
    data: {
      first: number;
      last: number;
      third: number;
    };
    selectedChange: Function;
  },
  {
    data: {
      first: number;
      last: number;
      others: number;
      total: number;
      
    };
    selected: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: {
        first: props.data.first,
        last: props.data.last,
        others: props.data.others,
        total: props.data.first + props.data.last + props.data.others,
        
      },
      selected: 0
    };
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.forceUpdate();
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps:any, prevState:  {
    data: {
      first: number;
      last: number;
      others: number;
      total: number;
      
    };
    selected: number;
  }){
      if(prevState.selected !== this.state.selected){
        this.props.selectedChange(this.state.selected);
      }
      
  }

  render() {
    const { t } = this.props;
    const { data, selected } = this.state;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "70vw",
          maxWidth: "66rem",
          height: "fit-content",
          marginBottom: '2rem',

        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "14.625rem",
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.15)',
            borderRadius: "0.5rem",
            minHeight: '5.875rem',
            cursor: 'pointer',
            border: selected == 0? '0.5px solid var(--primary----blue)' : 'none'
          }}
          onClick={() => this.setState({selected:0})}
        >
          <Box
            sx={{
              width: "fit-content",
              margin: '0.2rem'
            }}
          >
            <PieChart
              height={"4rem"}
              width={"4rem"}
              chart={{
                labels: ["First", "Last", "Other"],
                datasets: {
                  label: "Total",
                  backgroundColors: [
                    "rgba(51, 190, 105, 1)",
                    "rgba(113, 63, 147, 1)",
                    "rgba(0, 174, 239, 1)",
                  ],
                  data: [data.first, data.last, data.others],
                },
              }}
            />
          </Box>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '1.375rem',
                fontWeight: 'bold',
                lineHeight: '1.625rem',
            }}>{data.total}</Typography>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.625rem',
            }}>
              {t("profile.scientificPublications.chart.totalArticles")}
            </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "14.625rem",
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.15)',
            borderRadius: "0.5rem",
            minHeight: '5.875rem',
            cursor: 'pointer',
            border: selected == 1? '0.5px solid var(--acent---3)' : 'none'
          }}
          onClick={() => this.setState({selected:1})}
        >
          <Box
            sx={{
              width: "fit-content",
              margin: '0.2rem'
            }}
          >
            <DefaultDoughnutChart
              height={"4rem"}
              width={"4rem"}
              chart={{
                labels: ["First", "Rest"],
                datasets: {
                  label: "First",
                  backgroundColors: [
                    "rgba(51, 190, 105, 1)",
                    "rgba(51, 190, 105, 0.1)",
                  ],
                  data: [data.first, data.total - data.first],
                },
              }}
            />
          </Box>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '1.375rem',
                fontWeight: 'bold',
                lineHeight: '1.625rem',
            }}>{((data.first / data.total)*100).toFixed(2)}% ·</Typography>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.625rem',
            }}>
              {t("profile.scientificPublications.chart.first")}
            </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "14.625rem",
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.15)',
            borderRadius: "0.5rem",
            minHeight: '5.875rem',
            cursor: 'pointer',
            border: selected == 2? '0.5px solid var(--acent---2)' : 'none'
          }}
          onClick={() => this.setState({selected:2})}
        >
          <Box
            sx={{
              width: "fit-content",
              margin: '0.2rem'
            }}
          >
            <DefaultDoughnutChart
              height={"4rem"}
              width={"4rem"}
              chart={{
                labels: ["Last", "Rest"],
                datasets: {
                  label: "Last",
                  backgroundColors: [
                    "rgba(113, 63, 147, 1)",
                    "rgba(113, 63, 147, 0.10000000149011612)",
                  ],
                  data: [data.last, data.total - data.last],
                },
              }}
            />
          </Box>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '1.375rem',
                fontWeight: 'bold',
                lineHeight: '1.625rem',
            }}>{((data.last / data.total)*100).toFixed(2)}% ·</Typography>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.625rem',
            }}>
              {t("profile.scientificPublications.chart.last")}
            </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "14.625rem",
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.15)',
            borderRadius: "0.5rem",
            minHeight: '5.875rem',
            cursor: 'pointer',
            border: selected == 3? '0.5px solid var(--secondary-2)' : 'none'
          }}
          onClick={() => this.setState({selected:3})}
        >
          <Box
            sx={{
              width: "fit-content",
              margin: '0.2rem'
            }}
          >
            <DefaultDoughnutChart
              height={"4rem"}
              width={"4rem"}
              chart={{
                labels: ["Last", "Rest"],
                datasets: {
                  label: "Last",
                  backgroundColors: [
                    "rgba(102, 206, 245, 1)",
                    "rgba(0, 174, 239, 0.10000000149011612)",
                  ],
                  data: [data.others, data.total - data.others],
                },
              }}
            />
          </Box>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '1.375rem',
                fontWeight: 'bold',
                lineHeight: '1.625rem',
            }}>{((data.others / data.total)*100).toFixed(2)}% ·</Typography>
            <Typography sx={{
                color: 'var(--primary----dark-blue)',
                marginRight: '0.5rem',
                marginY: 'auto',
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.625rem',
            }}>
              {t("profile.scientificPublications.chart.others")}
            </Typography>
        </Box>
      </Box>
    );
  }
}

export default withTranslation("common")(ScientificCharts);
