import { Box } from "@mui/system";
import React from "react";



export default function BottomBar() {

    //print a bar at the bottom of the screen
  return (
    <Box sx={{
        position: "fixed",
        bottom: "0",
        left: "0",
        display: "flex",
        flexDirection: "row",
        height: "41px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "var(--white)",
        borderTop: "0.5px solid var(--primary----blue-3)",
        zIndex: 1000,
    }}>
        <img src="/bottom-logo.svg" alt="logo" />
    </Box>
  );
}