import {
    Chip,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
  } from "@mui/material";
  import {Box} from "@mui/material";
  import React from "react";
  import { WithTranslation, withTranslation } from "react-i18next";
  import { color } from "../../helpers/ColorAcent";
  import MinimizeIcon from "@mui/icons-material/Minimize";
  import { ProfileProvider } from "app/services/ProfileProvider";
  
  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
      backgroundColor: "var(--primary--800)",
      color: "var(--white)",
    },
  });
  
  class TherapeuticArea extends React.Component<
    WithTranslation & {
      profile_id: number;
      id: number;
      therapeuticArea: string;
    },
    {
      id: number;
      title: string;
      textColor: string;
      backgroundColor: string;
      areas: string;
      areasChips: JSX.Element[];
      extra: number;
    }
  > {
    constructor(props: any) {
      super(props);
      const { textColor, backgroundColor } = color(props.id);
      this.state = {
        id: props.id,
        title: props.therapeuticArea || "",
        textColor,
        backgroundColor,
        areas: null,
        areasChips: null,
        extra: 1
      };
    }
  
  
  
    componentDidUpdate(prevProps: any, prevState: any) {
      if (
        prevProps.id !== this.props.id ||
        prevProps.therapeuticArea !== this.props.therapeuticArea
      ) {
        const { textColor, backgroundColor } = color(this.props.id);
        this.setState({
          id: this.props.id,
          title: this.props.therapeuticArea || "",
          textColor,
          backgroundColor,
        });
      }
    }
  
  
    render(): React.ReactNode {
      if (this.props.id > -1) {
        return (
          <>
          
            <NoMaxWidthTooltip
              title={
                <Box
                  sx={{
                    
                    whiteSpace: "pre-line",
                    textTransform: "capitalize",
                    color: "var(--white)",
                    backgroundColor: "var(--primary----dark-blue)",
                  }}
                >
                  {this.state.areas}
                </Box>
              }
            >
              <Box
                sx={{
                  margin: "0.05rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  maxWidth: "12rem",
                  width: "min-content",
                  zIndex: 999,
                }}
              >
                {this.props.id  > -1? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "min-content",
                    //marginX: "auto",
                    //marginRight: '0.2rem',
                    flexWrap: "wrap",
                    marginY: "0.01rem",
                  }}
                >
                  <Chip
                    sx={{
                      background: "transparent",
                      color: `var(${this.state.textColor})`,
                      border: `1px solid var(${this.state.textColor})`,
                      margin: "0.05rem",
                      maxWidth: "auto",
                      height: "2rem",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                    label={
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "0.875rem",
                        }}
                      >
                        {this.state.title}
                      </Typography>
                    }
                  />
                </Box>) : null}
              </Box>
            </NoMaxWidthTooltip>
          </>
        );
      } else {
        return (
          <Box
            sx={{
              margin: "0.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              maxWidth: "12rem",
              width: "12rem",
            }}
          >
            <Chip
              sx={{
                paddingLeft: "10px",
                margin: "0.5rem",
                background: "transparent",
                color: `var(--neutral--600)`,
                border: `1px solid var(--neutral--600)`,
                maxWidth: '4rem'
                //marginX: "auto",
              }}
              icon={
                <MinimizeIcon
                  sx={{
                    paddingLeft: "3px",
                    marginBottom: "12px",
                  }}
                />
              }
            />{" "}
          </Box>
        );
      }
    }
  }
  
  export default withTranslation("common")(TherapeuticArea);
  