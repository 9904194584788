import { Metadata } from "app/types/Metadata";

let store: { item: Metadata; state: ((metadata: Metadata) => void)[] } = {
  item: null,
  state: [],
};

export const updateMetadata = (metadata: Metadata) => {
  try {
    const { item, state } = store;

    store = { item: metadata, state };

    state.forEach((callback: (metadata: Metadata) => void) => {
      callback(metadata);
    });
  } catch (error) {
    console.error(error);
  }
};

export const loadMetadata = (): Metadata => {
  try {
    return store.item;
  } catch (error) {
    //console.error(error);
  }
  return null;
};

// observe on metadata functions, on change, call subscribed functions
export const subscribeMetadata = (callback: (metadata: Metadata) => void) => {
  try {
    const { item, state } = store;
    const newState = [...state, callback];
    store = { item, state: newState };
  } catch (error) {
    //console.error(error);
  }
};
