function configs_tooltip(labels: any, datasets: any) {
  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout: 0,
          tension: 0,
          pointRadius: 0,
          borderWidth: 0,
          fill: true,
          data: datasets.data,
        },
      ],
    },
    options: {
      borderAlign: "center",
      aspectRatio: 1,
      animation: {
        duration: 0,
      },
      responsive: true,
      maintainAspectRatio: true,
      borderWidth: 0,
      plugins: {
        legend: false,
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function (
              tooltipItem: { label: any; formattedValue: any; }) {
              let label = tooltipItem.label;
              let value = tooltipItem.formattedValue;
              return  value + " mentions";
            },
          },
          backgroundColor: 'rgba(0, 25, 69, 1)'
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs_tooltip;
