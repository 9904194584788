import {
    Alert,
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    Slide,
    Snackbar,
    Typography,
    Box,
  } from "@mui/material";
  import MDInput from "app/assets/theme/components/MDInput";
  import React from "react";
  import { WithTranslation, withTranslation } from "react-i18next";
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
  import { User } from "app/types/User";
  import UserProvider from "app/services/UserProvider";
  import CloseIcon from "@mui/icons-material/Close";
  import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
  import { TransitionProps } from "@mui/material/transitions";
  import ProfilePicture from "./ProfilePicture";
  import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  class AccountSettings extends React.Component<
    WithTranslation,
    {
      showPassword: boolean;
      showOldPassword: boolean;
      changePassword: boolean;
      password: string;
      oldPassword: string;
      user: User;
      openSnackbar: boolean;
      openErrorSnackbar: boolean;
      errorMessage: string;
      openAlert: boolean;
      validate: boolean;
      validatePassword: boolean;
      pushfile: number;
    }
  > {
    constructor(props: any) {
      super(props);
      this.state = {
        showPassword: false,
        user: null,
        openSnackbar: false,
        openErrorSnackbar: false,
        errorMessage: this.props.t("adminPanel.profileSettings.changesNotSaved"),
        openAlert: false,
        validate: false,
        validatePassword: false,
        pushfile: 0,
        changePassword: false,
        showOldPassword: false,
        password: "",
        oldPassword: "",
      };
      this.reset();
    }
  
    reset = async () => {
      const response = await UserProvider.getUser();
      const user: User = {
        email: response["email"],
        firstName: response["firstName"],
        lastName: response["lastName"],
        phone: response["phone"],
        city: response["city"],
        country: response["country"],
        address: response["address"],
        picture: response["picture"],
      };
      sessionStorage.setItem("user", JSON.stringify(user));
      this.setState({
        openAlert: false,
        showPassword: false,
        showOldPassword: false,
        errorMessage: this.props.t("adminPanel.profileSettings.changesNotSaved"),
        user: user,
        validate: false,
        validatePassword: false,
        changePassword: false,
        password: "",
        oldPassword: "",
      });
    };
  
    updateUser = async () => {
      this.setState({
        errorMessage: this.props.t("adminPanel.profileSettings.changesNotSaved"),
      });
      if (
        this.state.user.email === "" ||
        this.state.user.email.indexOf("@") === -1
      ) {
        this.setState({
          validate: true,
        });
        return;
      }
  
      await UserProvider.updateUser(this.state.user);
      if (this.state.changePassword) {
        const result = await this.changePassword();
        if (result) {
          this.setState({
            openSnackbar: true,
            pushfile: this.state.pushfile + 1,
          });
          await new Promise((resolve) => setTimeout(resolve, 1000));
          document.cookie =
            "id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          caches.delete("profile");
          window.location.href = "/";
        } else {
          this.setState({
            openErrorSnackbar: true,
            validatePassword: true,
          });
        }
      } else {
        this.setState({
          openSnackbar: true,
          validate: false,
          pushfile: this.state.pushfile + 1,
        });
      }
    };
  
    changePassword = async () => {
      if (
        (this.state.oldPassword === "" || this.state.password === "") &&
        this.state.password.length >= 8 &&
        this.state.password.length <= 20
      ) {
        return false;
      } else {
        const response =  await UserProvider.changePassword(
          this.state.oldPassword,
          this.state.password
        );
        if (response === 'success') {
          return true;
        }
        if (response === "LimitExceededException"){
          this.setState({
            errorMessage: this.props.t("adminPanel.profileSettings.toMuchAttemps"),
          });
        }
          return false;
      }
    };
  
    handleClickShowPassword = () => {
      this.setState({
        showPassword: !this.state.showPassword,
      });
    };
  
    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
  
    handleClickShowOldPassword = () => {
      this.setState({
        showOldPassword: !this.state.showOldPassword,
      });
    };
  
    handleMouseDownOldPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
  
    handleResetPassword = async () => {
      this.setState({
        changePassword: true,
      });
    };
  
    handleChange = (event: any, input: string) => {
      if (input === "password") {
        this.setState({
          password: event.target.value,
        });
      }
      if (input === "oldPassword") {
        this.setState({
          oldPassword: event.target.value,
        });
      } else {
        this.setState({
          user: {
            ...this.state.user,
            [input]: event.target.value,
          },
        });
      }
    };
  
    handleCloseSnackbar = () => {
      this.setState({
        openSnackbar: false,
      });
    };
  
    handleCloseErrorSnackbar = () => {
      this.setState({
        openErrorSnackbar: false,
      });
    };
  
    handleOpenAlert = (event: any) => {
      event.stopPropagation();
      this.setState({
        openAlert: true,
      });
    };
  
    handleCloseAlert = (event: any) => {
      event.stopPropagation();
      this.setState({
        openAlert: false,
      });
    };
  
    actionSnackbar = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={this.handleCloseSnackbar}>
          UNDO
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.handleCloseSnackbar}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    render() {
      return (
        <Box
          sx={{
            width: window.innerWidth - 400,
            height: window.innerHeight - 300,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              marginTop: "1rem",
              marginLeft: "1.2rem",
              marginRight: "auto",
              marginBottom: "1rem",
              fontSize: "28px",
              lineHeight: "38px",
              fontWeight: 600,
              color: "var(--primary----dark-blue)",
            }}
          >
            My Account
          </Box>
          <Divider
            sx={{
              color: "var(--primary----blue-2)",
              backgroundColor: "var(--primary----blue-2)",
              backgroundImage: "none",
              marginX: "-10rem",
              zIndex: -1,
            }}
          />
          <ProfilePicture
            {...{
              file_source: this.state.user?.picture ?? null,
              pushfile: this.state.pushfile,
            }}
          />
  
          <Box
            sx={{
              marginTop: "1rem",
              width: "40rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "40rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "var(--gray---3)",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "18px",
                  marginRight: "auto",
                  marginLeft: "0.1rem",
                  textTransform: "uppercase",
                }}
              >
                <span>{this.props.t("adminPanel.profileSettings.email")}</span>
              </Box>
              <MDInput
                autoComplete="off"
                error={
                  this.state.validate &&
                  (this.state.user?.email === "" ||
                    !this.state.user?.email?.includes("@"))
                }
                helperText={
                  this.state.validate &&
                  (this.state.user?.email === "" ||
                    !this.state.user?.email?.includes("@")) &&
                  this.props.t("adminPanel.profileSettings.emailRequired")
                }
                sx={{
                  display: "flex",
                  background: "transparent !important",
                  color: "var(--negative) !important",
                  borderColor: "var(--negative) !important",
                }}
                onChange={(e: any) => {
                  this.handleChange(e, "email");
                }}
                value={this.state.user?.email ?? ""}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "1rem",
              width: "50rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "18rem",
                marginRight: "4rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "var(--gray---3)",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "18px",
                  marginRight: "auto",
                  marginLeft: "0.1rem",
                  textTransform: "uppercase",
                }}
              >
                <span>
                  {this.props.t("adminPanel.profileSettings.oldPassword")}
                </span>
              </Box>
              <MDInput
                autoComplete="off"
                type={this.state.showOldPassword ? "text" : "password"}
                disabled={!this.state.changePassword}
                value={this.state.oldPassword ?? ""}
                onChange={(e: any) => {
                  this.handleChange(e, "oldPassword");
                }}
                helperText={
                  this.state.validatePassword &&
                  this.props.t("adminPanel.profileSettings.oldPasswordError")
                }
                error={this.state.validatePassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowOldPassword}
                        onMouseDown={this.handleMouseDownOldPassword}
                        edge="end"
                      >
                        {this.state.showOldPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  display: "flex",
                  backgroundImage: "none !important",
                  color: "var(--negative) !important",
                  borderColor: "var(--negative) !important",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "18rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "var(--gray---3)",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "18px",
                  marginRight: "auto",
                  marginLeft: "0.1rem",
                  textTransform: "uppercase",
                }}
              >
                <span>
                  {this.props.t("adminPanel.profileSettings.newPassword")}
                </span>
              </Box>
              <MDInput
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                disabled={!this.state.changePassword}
                onChange={(e: any) => {
                  this.handleChange(e, "password");
                }}
                value={this.state.password ?? ""}
                helperText={
                  this.state.validatePassword &&
                  this.props.t("adminPanel.profileSettings.passwordError")
                }
                error={this.state.validatePassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="start"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundImage: "none !important",
                  display: "flex",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "10rem",
                paddingTop: "1.6rem",
                //height: "100%",
                marginLeft: "0rem",
                alignItemss: "center",
                justifyContent: "baseline",
                display: "flex",
              }}
            >
              <Typography
                onClick={this.handleResetPassword}
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "var(--primary----blue-2)",
                  marginLeft: "1rem",
                  cursor: "pointer",
                }}
              >
                {this.props.t("adminPanel.profileSettings.resetPassword")}
              </Typography>
            </Box>
          </Box>
         
          <Box
            sx={{
              position: "fixed",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100vw",
                paddingRight: '3rem',
                height: "8rem",
                bottom: 0,
                left: "auto",
                right: '0rem',
                backgroundColor: "var(--back)",
                zIndex: 0,
                borderTop: "1px solid var(--primary----blue-2)",
  
            }}
          >
             <Divider
            sx={{
              marginTop: "auto",
              color: "var(--primary----blue-2)",
              backgroundColor: "var(--primary----blue-2)",
              backgroundImage: "none",
            }}
          />
            <Button
              sx={{
                height: "3rem",
                width: "7.5rem",
                color: "var(--gray---1)",
                border: "none",
              }}
              onClick={this.handleOpenAlert}
            >
              {this.props.t("adminPanel.profileSettings.cancel")}
            </Button>
            <Dialog
              sx={{
                zIndex: 10,
                border: "1px solid var(--primary----blue-ultra-light)",
                boxShadow: "0px 0px 8px rgba(37, 40, 43, 0.12)",
                boxRadius: "8px",
              }}
              open={this.state.openAlert}
              TransitionComponent={Transition}
              keepMounted
              onBackdropClick={this.handleCloseAlert}
              onClose={this.handleCloseAlert}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box onClick={(e: any) => e.stopPropagation()}>
                <CloseIcon
                  onClick={this.handleCloseAlert}
                  sx={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                    cursor: "pointer",
                    color: "var(--gray---2)",
                    backgroundColor: "var(--light-gray---1)",
                    borderRadius: "50%",
                    height: "1.5rem",
                    width: "1.5rem",
                    zIndex: 10,
                  }}
                />
                <Box
                  sx={{
                    width: "80%",
                    marginY: "2rem",
                    marginX: "auto",
                    padding: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      padding: "1rem",
                      margin: "auto",
                      width: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src="./Vectoralert.svg" alt="logo" />
                  </Box>
  
                  <DialogTitle sx={{ marginTop: "-2rem", textAlign: "center" }}>
                    {this.props.t("adminPanel.profileSettings.sureToCancel")}?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {this.props.t("adminPanel.profileSettings.cancelAdvice")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      sx={{
                        color: "var(--primary----blue)",
                        border: "1px solid var(--primary----blue)",
                      }}
                      onClick={this.handleCloseAlert}
                    >
                      {this.props.t("adminPanel.profileSettings.goBack")}
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "var(--primary----blue)",
                        color: "var(--white)",
                        border: "1px solid var(--primary----blue)",
                        "&:hover": {
                          border: "1px solid var(--primary----blue)",
                        },
                      }}
                      onClick={this.reset}
                    >
                      {this.props.t("adminPanel.profileSettings.yesCancel")}
                    </Button>
                  </DialogActions>
                </Box>
              </Box>
            </Dialog>
            <Button
              sx={{
                height: "3rem",
                width: "7.5rem",
                merginLeft: "1rem",
                backgroundColor: "var(--primary----blue-2)",
                color: "var(--white)",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor: "var(--primary----blue-2)",
                },
              }}
              onClick={this.updateUser}
            >
              {this.props.t("adminPanel.profileSettings.save")}
            </Button>
            <Snackbar
              open={this.state.openSnackbar}
              autoHideDuration={6000}
              TransitionComponent={Transition}
              onClose={this.handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Box
                sx={{
                  width: "15.75rem",
                  borderLeft: "1px solid var(--positive)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  backgroundColor: "var(--white)",
                  height: "7rem",
                  boxShadow: " 1px 1px 4px rgba(0, 0, 0, 0.15)",
                  borderRadius: "2px",
                  marginTop: "5rem",
                  padding: "2rem",
                }}
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "var(--positive)",
                    marginRight: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  {this.props.t(
                    "adminPanel.profileSettings.changesSuccessfullySaved"
                  )}
                </Typography>
                <CloseIcon
                  sx={{
                    position: "absolute",
                    right: "0.5rem",
                    height: "100%",
                    paddingBottom: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={this.handleCloseSnackbar}
                />
              </Box>
            </Snackbar>
            <Snackbar
              open={this.state.openErrorSnackbar}
              autoHideDuration={6000}
              TransitionComponent={Transition}
              onClose={this.handleCloseErrorSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Box
                sx={{
                  width: "15.75rem",
                  borderLeft: "1px solid var(--negative)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  backgroundColor: "var(--white)",
                  height: "7rem",
                  boxShadow: " 1px 1px 4px rgba(0, 0, 0, 0.15)",
                  borderRadius: "2px",
                  marginTop: "5rem",
                  padding: "2rem",
                }}
              >
                <ErrorRoundedIcon
                  sx={{
                    color: "var(--negative)",
                    marginRight: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  {this.state.errorMessage}
                </Typography>
                <CloseIcon
                  sx={{
                    position: "absolute",
                    right: "0.5rem",
                    height: "100%",
                    paddingBottom: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={this.handleCloseErrorSnackbar}
                />
              </Box>
            </Snackbar>
          </Box>
        </Box>
      );
    }
  }
  
  export default withTranslation("common")(AccountSettings);
  