import { Box, Grid, Typography } from "@mui/material";
import MDInput from "app/assets/theme/components/MDInput";
import { loadMetadata } from "app/store/metadataStore";
import { notifyTableSize } from "app/subscribers/SubscriberToTableSize";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  subscribeToRenderChip,
  subscribeToRenderSearch,
} from "./OverviewFilters";
import ProfileCard from "./ProfileCard";

class ProfileGrid extends React.Component<
  WithTranslation & {
    table: {
      columns: { [key: string]: any }[];
      rows: { [key: string]: any }[];
    };
    moreRows: Function;
    totalEntries: number;
  },
  {
    size: number;
    tableSize: number;
    renderChip: JSX.Element;
    renderSearch: JSX.Element;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      size: window.screen.height,
      tableSize: 0,
      renderChip: null,
      renderSearch: null,
    };
    window.addEventListener("resize", this.maxHeightPossible);
    subscribeToRenderChip(this.handleRenderChip);
    subscribeToRenderSearch(this.handleRenderSearch);
  }

  handleRenderChip = (chips: JSX.Element) => {
    this.setState({ renderChip: chips });
  };

  handleRenderSearch = (search: JSX.Element) => {
    this.setState({ renderSearch: search });
  };

  loadMore = (event: any) => {
    const scrollPosition = event.target.scrollTop;
    const reactTableHeight = event.target.scrollHeight;
    //if the scroll is at the end of the table, we load more rows
    
    if (scrollPosition + window.screen.height >= reactTableHeight) {
      this.props.moreRows();
    }
    
  };

  maxHeightPossible = () => {
    //size = from the start of react-table to the end of the screen minis 10px
    const size =
      this.divElement?.offsetParent?.offsetHeight +
      this.firstElement?.offsetTop +
      this.secondElement?.offsetTop -
      this.divElement?.offsetTop -
      41;
    //tableSize = calc of the number of cards per row * the width of the card (315px) + the gap between the cards (20px)
    const maxSpace = window.screen.width - 120;
    const maxCardsPerRow = (): number => {
      let result = 0;
      while (maxSpace - (result * 335 + 315) > 0) {
        result += 1;
      }
      return result;
    };

    const tableSize = Math.min(
      maxCardsPerRow() * 316 + (maxCardsPerRow() - 1) * 20,
      1655
    );

    this.setState({ size, tableSize });
  };

  firstElement: any;
  secondElement: any;
  divElement: any;
  componentDidUpdate(prevProps: any, prevState: any) {
    // if content is shorter than the screen, we load more
    if (this.props.table?.rows?.length !== prevProps.table?.rows?.length || this.state.size !== prevState.size) { 
      const reactTableHeight = this.divElement?.lastChild?.offsetTop || 1000000;
      if (reactTableHeight < window.screen.height) {
        this.props.moreRows();
      }
    }

    if (this.state.tableSize !== prevState.tableSize) {
      notifyTableSize(this.state.tableSize);
    }
  }

  componentDidMount(): void {
    this.props.moreRows();
    //div position respect the top of the navigator

    this.maxHeightPossible();
  }

  render(): React.ReactNode {
    const { t } = this.props;
    const { table } = this.props;
    const { size } = this.state;
    const text =
      this.props.totalEntries === loadMetadata().count ||
      loadMetadata().favourites
        ? t(
            "dashboard.filters.profilesFound_" +
              sessionStorage.getItem("section")
          )
        : t(
            "dashboard.filters.profilesFoundFilter_" +
              sessionStorage.getItem("section")
          );
    // render a grid of profilesCards, 4 per row as maximum
    // if the screen is too small, we will reduce the number of cards per row
    return (
      <div
        style={{
          height: size,
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: "60px",
          paddingRight: "60px",

          overflowY: "auto",
          width: "100%",
        }}
        onScroll={this.loadMore}
      >
        <Box
          sx={{
            display: this.state.renderChip != null ? "flex" : "none",
            width: this.state.tableSize,
            maxWidth: "1660px",
            paddingTop: "12px",
            paddingBottom: "8px",
            marginX: "auto",
          }}
          ref={(divElement) => {
            this.firstElement = divElement;
          }}
        >
          {this.state.renderChip}
        </Box>
        <Box
          sx={{
            paddingTop: "16px",
            paddingBottom: "16px",
            fontWeight: 400,
            lineHeight: "140%",
            fontSize: "14px",
            color: "var(--gray---2)",
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginX: "auto",
            maxWidth: "1655px",
            width: this.state.tableSize,
            height: "fit-content",
          }}
          ref={(divElement) => {
            this.secondElement = divElement;
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
              lineWeight: "19px",
              color: "var(--gray---1)",
              //textTransform: "lowercase",
              width: "fit-content",
              whiteSpace: "nowrap",
              marginLeft: "2px",
            }}
          >
            {`${this.props.totalEntries} ${text}`}
          </Typography>
          {this.state.renderSearch}
        </Box>
        <Box
          ref={(divElement) => {
            this.divElement = divElement;
          }}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            marginX: "auto",
            marginBottom: "200px",
            height: "fit-content",

            width: this.state.tableSize,
            gap: "20px",
          }}
        >
          {table
            ? table.rows.map((row: any, index: number) => (
                <Box key={index} sx={{}}>
                  <ProfileCard profile={row} favourite={row.favourite} />
                </Box>
              ))
            : null}
        </Box>
      </div>
    );
  }
}

export default withTranslation("common")(ProfileGrid);
