import { Profile } from "app/types/Profile";
import { ClinicalTrialTable } from "app/types/ClinicalTrialTable";
import { Author } from "app/types/Author";
import { ClinicalTrial } from "app/types/ClinicalTrial";


const ClinicalTrialParser =  (data : Profile): ClinicalTrialTable[] => 
{

    const pool =  data ;

    if(!pool?.authors)
    {
        return [];
    }

    //optain all pubmeds as scientific table
    return pool.authors.reduce((result: ClinicalTrialTable[], item: Author ): ClinicalTrialTable[] => {
                item.clinicalTrials.map((item: ClinicalTrial) => {
                  if(item && !result.find(res => res.nct_id == item.nct_id)){
                        
                    result.push({
                        nct_id: item.nct_id,
                        title: item.official_title,
                        //journal: item.phase,
                        date: (new Date(item.start_date) ).toString(), 
                        //therapeuticArea: '',
                        //role: item.enrollment+'',
                        //phase: item.phase,
                        //overallStatus: item.overall_status,
                        status_id: item.status_id,
                        status: item.status,
                        url:   'https://clinicaltrials.gov/ct2/show/'+item.nct_id,
                    } as ClinicalTrialTable);
                  }
                });
            return result;
        },[]).sort(
          (a: ClinicalTrialTable, b: ClinicalTrialTable) => {
            if (new Date(a.date) < new Date(b.date)) {
              return 1;
            } else if (new Date(a.date) > new Date(b.date)) {
              return -1;
            } else {
              return 0;
            }
          }
        )
        .map((item: ClinicalTrialTable) => ({...item, date: (new Date(item.date)).toLocaleDateString('es-ES')} ));
 
    

    
}
export default ClinicalTrialParser;