import { ReactNode, useMemo } from "react";

// react-chartjs-2 components
import { Pie } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

// PieChart configurations
import configs_simple from "./configs/index_simple";
import configs_tooltip from "./configs/index_tooltip";
import './piechart.css';
// Declaring props types for PieChart
interface Props {
  icon?: {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    component: ReactNode;
  };
  title?: string;
  tooltip? : boolean;
  description?: string | ReactNode;
  height?: string | number;
  width?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColors: string[];
      data: number[];
    };
  };
  [key: string]: any;
}

function PieChart({ icon, title, description, height,width, chart , tooltip}: Props): JSX.Element {
  const { data, options } = tooltip ? configs_tooltip(chart.labels || [], chart.datasets || {}) : configs_simple(chart.labels || [], chart.datasets || {});

  const renderChart = (
    <Box >
      {title || description ? (
        <Box display="flex">
          {icon.component && (
            <Box
              width="4rem"
              height="4rem"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </Box>
          )}
          <Box >
            {title && <Typography variant="h6">{title}</Typography>}
            <Box mb={2}>
              <Typography component="div" variant="button" color="text">
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {useMemo(
        () => (
          <Box width={width} height={height} marginX={'auto'}>
            <Pie  data={data} options={options} />
          </Box>
        ),
        [chart, height]
      )}
    </Box>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Declaring default props for PieChart
PieChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  width: "19.125rem",
};

export default PieChart;
