import { Box, Grid, Tabs } from "@mui/material";
import { TabPanel, TopNavbar } from "app/components";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccountSettings from "app/components/AdminPanel/AccountSettings";
class AdminPanel extends React.Component<WithTranslation, { value: number }> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: Number(localStorage.getItem('settingtab') || 0),
        };
    }

    handleChange = (newValue: number) => {
      localStorage.setItem('settingtab', newValue+'')
      this.setState({ value: newValue });
    };

  render() {
    return (
      <div>
        <TopNavbar />
        <div className="container-admin">
          <Grid className="grid-filter-admin" xs={2}>
            <Box sx={{
                marginTop: "1rem",
                marginLeft: "1.2rem",
                marginRight: "auto",
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: 'var(--gray---2)',
                width: '100%',
                height: '2rem',
            }}>
                <span>{this.props.t('adminPanel.tabs.platformConfiguration')}</span>
            </Box>
            <Box className="tab-options-admin" onClick={() => this.handleChange(0)}>
             <span>{this.props.t('adminPanel.tabs.profileSettings')}</span>
                <ArrowForwardIcon />
            </Box>
              </Grid>
          <Grid className="grid-table-admin" item xs={8}>
            <Box className="tabs">
              <TabPanel value={this.state.value} index={0}>
                <AccountSettings/>
              </TabPanel>
              <TabPanel value={this.state.value} index={1}>
              </TabPanel>
              <TabPanel value={this.state.value} index={2}>
                  
              </TabPanel>
            </Box>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(AdminPanel);
