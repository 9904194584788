/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode, useMemo } from "react";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// VerticalBarChart configurations
import configs from "./configs";

// Material Dashboard 2 PRO React TS Base Styles
import { Tooltip } from "@mui/material";
import './bar.css';
// Declaring props types for HorizontalBarChart
interface Props {
  icon?: {
    color?: string;
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  width?: string | number;
  chart: {
    labels: string[] |number[];
    datasets: {
      label: string;
      color: string;
      data: number[];
      status: string;
    }[];
  };
  [key: string]: any;
}

function VerticalBarChart({ icon, title, description, height,width, chart  }: Props): JSX.Element {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: dataset.color,
        fill: false,
        maxBarThickness: 35,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = (
    <Box py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <Box display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <Box
              width="4rem"
              height="4rem"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </Box>
          )}
          <Box mt={icon.component ? -2 : 0}>
            {title && <Typography variant="h6">{title}</Typography>}
            <Box mb={2}>
              <Typography component="div" variant="button" color="text">
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {useMemo(
        () => (
          <Box height={height} width={width} marginX={'auto'}>
            <Bar data={data} options={options} />
          </Box>
        ),
        [chart, height]
      )}
      <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          flexWrap: 'wrap',
        }}>
      {chart.datasets.map((dataset, index) => (
         <Box sx={{
           marginX: '0.2rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
         }}>
          <Box sx={{
            margin: '0.1rem',
            display: 'flex',
            width: '8px',
            height: '8px',
            backgroundColor: dataset.color,
            borderRadius: '50%',
          }}></Box>
          <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{dataset.status}</span>}>
          <Typography sx={{
            margin: '0.1rem',
            display: 'flex',
            color: 'var(--primary----dark-blue)',
            fontSize: '12px',
            fontWeight: '400',
          }}>{dataset.label}</Typography></Tooltip>
       </Box>
      ))}
       </Box>
    </Box>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Declaring default props for VerticalBarChart
VerticalBarChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  width: "100%",
};

export default VerticalBarChart;
