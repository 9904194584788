import TherapeuticSubArea from "app/components/Dashboard/TherapeuticSubArea";
import TherapeuticProvider from "app/services/TherapeuticAreaProvider";
import { Profile } from "app/types/Profile";
import { PubMed } from "app/types/PubMed";
import { ScientificTable } from "app/types/ScientificTable";
import { TFunction } from "react-i18next";

const ScientificParser = async (
  data: Profile,
  t: TFunction<"translation", undefined>
): Promise<ScientificTable[]> => {
  const pool = data;
  const therapeuticCodes = await TherapeuticProvider.getAllTherapeuticData();

  //optain all pubmeds as scientific table
  if (!pool?.pubmeds) {
    return [];
  }
  return pool.pubmeds
    .map((item: PubMed) => {
      if (
        item
      ) {
        const subareas = item.pubmedArea.map(area =>{
          const parent = therapeuticCodes.find(tc => tc.id == area.therapeutic_area_id)
          const result = parent.therapeuticSubAreas.find(tsa => tsa.id == area.therapeutic_subarea_id)
          return {
            id: area.therapeutic_area_id,
            name: result.name
          }
        })
        const role = (() => {
          switch (item.role) {
            case 1:
              return t("profile.scientificPublications.others.first");
            case -1:
              return t("profile.scientificPublications.others.last");
            default:
              return t("profile.scientificPublications.others.others");
          }
        })();
        return {
          publication_id: item.publication_id + "",
          title: item.title,
          therapeuticAreaAndSubArea:
          <TherapeuticSubArea {...{subareas:subareas}} />,
          journal: item.journal,
          date: new Date(item.date).toString(),
          url: item.url,
          abstract: item.abstract,
          role: role,
        } as ScientificTable;
    }})
    .sort(
      (a: ScientificTable, b: ScientificTable) => {
        if (new Date(a.date) < new Date(b.date)) {
          return 1;
        } else if (new Date(a.date) > new Date(b.date)) {
          return -1;
        } else {
          return 0;
        }
      }
    ).map((item: ScientificTable) => ({...item, date: (new Date(item.date)).toLocaleDateString('es-ES')} ));
};
export default ScientificParser;
