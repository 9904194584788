
import { setupCache } from 'axios-cache-adapter'
import axios from 'axios';

const url = process.env.REACT_APP_API_URI;

const local_token = () => {
    if (document.cookie.includes("id_token"))
        return document.cookie.split("id_token=")[1].split(";")[0];
    window.location.href = '/signin';
}

const header_options = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization': `Bearer ${local_token()}`,
        }
    };



const checkStatus = async (response: any) => {
       
        if (response.status === 401) {

            window.location.href = '/signin';
        }
        if (response.status !== 200) {
            //window.location.href = '/404';
        }
        return response.data.data;
    }


export {
    axios,
    url,
    header_options,
    checkStatus
};