import {Box} from "@mui/material";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";


class Loading extends React.Component<
WithTranslation & {
    active: boolean;
}, {}> {
  constructor(props: any) {
      super(props);
  }  

  render() {
      if(this.props.active){
          return (
              <Box sx={{
                  zIndex: 19999,
                  backgroundColor: 'var(--primary----blue-3)',
                  opacity: 0.9,
                  width: '100vw',
                  height: '100vh',
                  position: 'fixed',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  top:0,
                  left:0
              }}
              >
                  <img src="./load-close.svg" alt="logo" />
                  <Box sx={{
                      textAlign: 'center',
                      color: "var(--primary----dark-blue)"
                  }}>
                    {this.props.t('loading.infoWillLoad')}
                    <br/>
                    <b>{this.props.t('loading.blinkEye')}</b>

                  </Box>

              </Box>
          )
      }
      return null;
  }
}

export default withTranslation("common")(Loading);