import { Box } from "@mui/material";
import { TabPanel, TopNavbar } from "app/components";
import BottomBar from "app/components/BottomBar";
import Overview from "app/components/Dashboard/Overview";
import OverviewFilters from "app/components/Dashboard/OverviewFilters";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

class Dashboard extends React.Component<
  WithTranslation & { following?: boolean },
  { value: number; filters: any; following: boolean }
> {
  constructor(props: any) {
    super(props);
    const value = Number(sessionStorage.getItem("section"));
    if (!value) {
      sessionStorage.setItem("section", "0");
    }
    this.state = {
      value: value || 0,
      filters: props.following ? { favourite: true } : {},
      following: props.following || false,
    };
    window.onunload = () => {
      sessionStorage.clear();
    };
  }

  componentDidMount(): void {
    const value = Number(sessionStorage.getItem("section"));
    if (!value) {
      sessionStorage.setItem("section", "0");
    }
    this.setState({
      value: value || 0,
      following: this.props.following || false,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<WithTranslation & { following?: boolean }>,
    prevState: Readonly<{ value: number; filters: any, following: boolean  }>,
    snapshot?: any
  ): void {
    if (prevState.value !== this.state.value) {
      sessionStorage.setItem("section", String(this.state.value));
    }
    if (prevProps.following !== this.props.following) {
      this.setState({ following: this.props.following });
    }
  }

  render() {
    return (
      <div>
        <TopNavbar />
        <BottomBar />
        <div className="container">
          <Box className="grid-table">
            <Box
              sx={{
                position: "absolute",
                top: "-2rem",
                left: "60px",
                display: "flex",
                flexDirection: "row",
                height: "7.5rem",
                width: "100vw",
                justifyContent: "flex-start",
                alignItems: "center",
                background: "inherit",
              }}
            >
              <Box
                sx={{
                  height: "60px",
                  width: "112px",
                  paddingBottom: "0.5rem",
                  borderRadius: this.state.value !== 0 ? 0 : "0px 0px 4px 4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  cursor: this.state.value !== 0 ? "pointer" : "default",
                  fontWeight: "600",
                  fontSize: "16px",
                  marginX: "0.5rem",
                  transition: "all 0.3s ease",
                  borderBottom: "1px solid var(--primary----dark-blue)",
                  color:
                    this.state.value === 0
                      ? "var(--white)"
                      : "var(--primary----dark-blue)",
                  backgroundColor:
                    this.state.value === 0
                      ? "var(--primary----dark-blue)"
                      : "var(--white)",
                  "&:hover": {
                    backgroundColor:
                      this.state.value === 0
                        ? "var(--primary----blue-1)"
                        : "var(--white)",
                    color:
                      this.state.value === 0
                        ? "var(--white)"
                        : "var(--primary----blue-1)",
                    borderColor:
                      this.state.value === 0
                        ? "var(--primary----blue-1)"
                        : "var(--primary----blue-1)",
                  },
                }}
                onClick={() => this.setState({ value: 0 })}
              >
                {this.props.t("dashboard.options.academic")}{" "}
              </Box>

              <Box
                sx={{
                  height: "60px",
                  cursor: this.state.value !== 1 ? "pointer" : "default",
                  width: "112px",
                  paddingBottom: "0.5rem",
                  borderRadius: this.state.value !== 1 ? 0 : "0px 0px 4px 4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  fontWeight: "600",
                  fontSize: "16px",
                  marginX: "0.5rem",
                  transition: "all 0.3s ease",
                  borderBottom: "1px solid var(--primary----dark-blue)",
                  color:
                    this.state.value === 1
                      ? "var(--white)"
                      : "var(--primary----dark-blue)",
                  backgroundColor:
                    this.state.value === 1
                      ? "var(--primary----dark-blue)"
                      : "var(--white)",
                  "&:hover": {
                    backgroundColor:
                      this.state.value === 1
                        ? "var(--primary----blue-1)"
                        : "var(--white)",
                    color:
                      this.state.value === 1
                        ? "var(--white)"
                        : "var(--primary----blue-1)",
                    borderColor:
                      this.state.value === 1
                        ? "var(--primary----blue-1)"
                        : "var(--primary----blue-1)",
                  },
                }}
                onClick={() => this.setState({ value: 1 })}
              >
                {" "}
                {this.props.t("dashboard.options.influencer")}{" "}
              </Box>
            </Box>
            <Box className="grid-filter">
                <OverviewFilters
                  hideFilters={this.state.following}
                  applyFilters={(event: any) =>
                    this.setState({ filters: {...event, favourite: this.state.following} })
                  }
                  tab={this.state.value}
                />
            </Box>
            <Box
              className="tabs"
              sx={{
                marginTop: "0rem",
                background: "var(--back)",
              }}
            >
              <TabPanel value={this.state.value} index={0}>
                <Overview
                  setFilters={{...this.state.filters, favourite: this.state.following} }
                  score_tab={"academic_score"}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={1}>
                <Overview
                  setFilters={{...this.state.filters, favourite: this.state.following} }
                  score_tab={"influencer_score"}
                />
              </TabPanel>
            </Box>
          </Box>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Dashboard);
