import { Avatar, Box, Typography } from "@mui/material";
import FileProvider from "app/services/FileProvider";
import { url as url_api } from "app/services/ProviderHelpers";
import UserProvider from "app/services/UserProvider";
import { User } from "app/types/User";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
class ProfilePicture extends React.Component<
  WithTranslation & {
    file_source: string;
    pushfile: number;
  },
  {
    file: string;
    selected_file: File;
    user: User;
  }
> {
  constructor(props: any) {
    super(props);
    const user = JSON.parse(sessionStorage.getItem("user"));
    this.state = {
      file: `${url_api}/${user.email.replace('.','_').replace('@','_')}.jpeg`,
      selected_file: null,
      user: user,
    };
    this.inputFile = React.createRef();
  }
  inputFile: any;

  componentDidUpdate(prevProps: Readonly<WithTranslation<"translation"> & { file_source: string; pushfile: number; }>, prevState: Readonly<{ file: string; selected_file: File; user: User; }>, snapshot?: any): void {
    if (this.props.pushfile !== prevProps.pushfile) {
      this.uploadFile();
    }
    if (this.props.file_source !== prevProps.file_source) {
      this.setState({
        file: `${url_api}/${this.state.user.email.replace('.','_').replace('@','_')}.jpeg`,
      });
    }
  }

  uploadFile = async () => {
    const response = await FileProvider.updateFile(`/${this.state.user.email.replace('.','_').replace('@','_')}.jepg` , this.state.selected_file);
  };

  handleInputFile = (e: any) => {
    const file = e.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    this.setState({
      selected_file: file,
      file: objectUrl,
    });
  };

  handleSelectPhoto = (event: any) => {
    event.stopPropagation();
    this.inputFile.current.click();
  };

  render() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "baseline",
          flexDirection: "row",
        }}
      >
        <Avatar
          className="account-icon"
          alt={this.state.user.email.toUpperCase()}
          src={
            this.state.file
          }
          sx={{
            fontSize: "1rem",
            marginTop: "auto",
            marginBottom: "auto",
            width: "5rem",
            height: "5rem",
          }}
        ></Avatar>
        <Box className="fallback">
          <input
            type="file"
            id="file"
            ref={this.inputFile}
            onChange={this.handleInputFile}
            style={{ display: "none" }}
          />
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              color: "var(--secondary-2)",
              marginLeft: "1rem",
              cursor: "pointer",
            }}
            onClick={this.handleSelectPhoto}
          >
            {this.props.t("adminPanel.profileSettings.addAccountPhoto")}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default withTranslation("common")(ProfilePicture);
