import { ReactNode } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import { Box } from "@mui/material";

// Material Dashboard 2 PRO React TS contexts

// Declaring props types for DataTableHeadCell
interface Props {
  width?: string | number;
  children: ReactNode;
  sorted?: false | "none" | "asce" | "desc";
  align?: "left" | "right" | "center";
  type?: "text" | "numeric" | "date";
}

function DataTableHeadCell({ width, children, sorted, align, ...rest }: Props): JSX.Element {
  return (
    <Box
      component="th"
      width={ width?? 'max-content' }
      py={0}
      px={0}
    >
      <Box
        sx={{
          paddingLeft: '1rem',
          position: "relative",
          fontSize: '0.6125rem',
          fontWeight: 400,
          textTransform: "uppercase",
          textAlign: "center",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
          wordBreak: 'break-word',
          width: 'max-content',
          //maxWidth: width?? '6vw'
        }}
      >
        <div dangerouslySetInnerHTML={{ __html:children.toString()}}></div> 
        
        {sorted && (
          <Box
            position="absolute"
            top={0}
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }: any) => ({
              fontSize: size.lg,
            })}
          >
            <Box
             sx={{ 
              position: "absolute",
              top: -6,
              color: sorted === "asce" ? "text" : "secondary",
              opacity: sorted === "asce" ? 1 : 0.5
            }}
            >
              <Icon>arrow_drop_up</Icon>
            </Box>
            <Box
               sx={{ 
                position: "absolute",
                top: 0,
                color: sorted === "asce" ? "text" : "secondary",
                opacity: sorted === "asce" ? 1 : 0.5
              }}
            >
              <Icon>arrow_drop_down</Icon>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

// Declaring default props for DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

export default DataTableHeadCell;
