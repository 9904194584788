import { useState } from "react";
// react-router components
import { Link, useLocation } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 PRO React TS components
import { Box, Divider, MenuItem, Typography } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components

// Material Dashboard 2 PRO React context
import { Avatar } from "@mui/material";
import { url as url_api } from "app/services/ProviderHelpers";
import { User } from "app/types/User";

import { useTranslation } from "react-i18next";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}


function TopNavbar({ absolute, light, isMini }: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();

  const [openMenu, setOpenMenu] = useState<any>(false);
  const route = useLocation().pathname.split("/").slice(1);

  const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  /*to do*/
  const deleteLog = () => {
    document.cookie =
      "id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    caches.delete("profile");
    window.location.reload();
  };

  const adminPanel = () => {
    window.location.href = "/admin-panel";
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      
    >
      <MenuItem onClick={() => adminPanel()} >
        <Box
          py={0.5}
          display="flex"
          alignItems="center"
          lineHeight={1}
        >
          <Typography variant="body1" color="secondary" lineHeight={0.75}>
            <img src="./settings.svg"/>
          </Typography>
          <Typography  sx={{ ml: 1, color: "var(--primary----dark-blue)", fontSize: 14, lineHeight: "20px", fontWeight: 600 }}>
          Admin panel
          </Typography>
        </Box>
      </MenuItem>
      <Divider sx={{
        width: "100%",
        border: "1px solid var(--gray---5)",
        height: "1px",
      }}/>
      <MenuItem onClick={() => deleteLog()} >
        <Box
          py={0.5}
          display="flex"
          alignItems="center"
          lineHeight={1}
          
        >
          <Typography variant="body1" color="secondary" lineHeight={0.75}>
          <img src="./log-out.svg"/>
          </Typography>
          <Typography  sx={{ ml: 1, color: "var(--primary----dark-blue)", fontSize: 14, lineHeight: "20px", fontWeight: 600 }}>
            Log Out
          </Typography>
        </Box>
      </MenuItem>
    </Menu>
  );

  // Styles for the navbar icons

  const user: User = JSON.parse(sessionStorage.getItem("user"));

  const { t } = useTranslation("common");

  const routesMenu = [
    {
      path: "/",
      name: t("menu.home"),
    },
    {
      path: "/following",
      name: t("menu.following"),
    },
  ];

  return (
    <AppBar className="top-bar" color="inherit">
      <Toolbar>
        <Box
          color="white"
          mb={{ xs: 1, md: 0 }}
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Roboto",
            fontWeight: "bold",
            marginLeft: "10px",
            marginTop: "5px",
          }}
        >
          <img src={process.env.REACT_APP_HOME_ICON} alt="logo" width="100px" />
        </Box>

        <Box color="white" className="menu" mb={{ xs: 1, md: 0 }}>
          {routesMenu.map((route, index) => {
            if (index === routesMenu.length - 1) {
              return (
                <div>
                  <Link color="white" to={route.path}>
                    <span className="span" color="white">
                      {" "}
                      {window.location.pathname === route.path ? (
                        <b>{route.name}</b>
                      ) : (
                        route.name
                      )}{" "}
                    </span>
                  </Link>
                </div>
              );
            } else {
              return (
                <div>
                  <Link color="white" to={route.path}>
                    <span className="span" color="white">
                      {" "}
                      {window.location.pathname === route.path ? (
                        <b>{route.name}</b>
                      ) : (
                        route.name
                      )}
                    </span>
                  </Link>
                  <span> | </span>
                </div>
              );
            }
          })}
        </Box>

        {isMini ? null : (
          <Box>
            <Box className="" color={light ? "white" : "inherit"}>
              <IconButton size="small" disableRipple>
                <Avatar
                  className="account-icon"
                  alt={user.email.toUpperCase()}
                  src={`${url_api}/${user.email
                    .replace(".", "_")
                    .replace("@", "_")}.jpeg`}
                  onClick={handleOpenMenu}
                ></Avatar>
              </IconButton>
              {renderMenu()}
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
TopNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default TopNavbar;
