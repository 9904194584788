
import { Component } from "react";



class SignIn extends Component<{location: string},{prevPath: string} > {
  getInitialState() {
    return {
      prevPath: ""
    };
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.location !== this.props.location) {
      this.setState({
        prevPath: this.props.location
      });
    }
  }

  constructor( props: any ) {
    super( props );
    this.state = this.getInitialState();
  }

  componentWillMount(){
    const url = process.env.REACT_APP_REDIRECT_URI;
    sessionStorage.setItem("redirect", this.state.prevPath);
    window.location.href = `${process.env.REACT_APP_LOGIN_URI}?redirect_uri=${url}`;
  }
  render(){
    return (<section>Redirecting...</section>);
  }
}

export default SignIn;
