
// @mui material components
import LinkIcon from "@mui/icons-material/Link";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Divider, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { Conference } from "app/types/Coference";
import { useEffect, useRef, useState } from "react";

import "./card.css";
// Declaring props types for SimpleBlogCard
interface Props {
  image: string;
  title: string;
  location: string;
  date: string;
  url: string;
  conferences?: Conference[];
}

function SimpleBlogCard({
  image,
  title,
  location,
  date,
  url,
  conferences,
}: Props): JSX.Element {
  const day = () => {
    switch (new Date(date).getDay()) {
      case 0:
        return "SUN";
      case 1:
        return "MON";
      case 2:
        return "TUE";
      case 3:
        return "WED";
      case 4:
        return "THU";
      case 5:
        return "FRI";
      case 6:
        return "SAT";
    }
  };
  const localDay = date? new Date(date).toLocaleDateString("es-ES", {
    dateStyle: "short",
  }) : "";
  const [style, setStyle] = useState({ display: "none" });
  const [isOverflowed1, setIsOverflow1] = useState(false);
  const [isOverflowed2, setIsOverflow2] = useState(false);
  const textElementRef1 = useRef<HTMLInputElement | null>(null);
  const textElementRef2 = useRef<HTMLInputElement | null>(null);

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      backgroundColor: 'var(--primary----dark-blue)',
    },
  });

  useEffect(() => {
    setIsOverflow1(
      textElementRef1.current.scrollWidth < textElementRef1.current.offsetWidth
    );
  }, []);

  useEffect(() => {
    setIsOverflow2(
      textElementRef2.current.scrollWidth < textElementRef2.current.offsetWidth
    );
  }, []);

  return (
    <Card
      className="card"
      sx={{
        height: "100%",
        display: "flex",
      }}
    >
      <Box
        sx={{
          margin: "1rem",
          minWidth: "20rem",
        }}
        position="relative"
        borderRadius="lg"
        mt={1}
        mx={2}
      >
        <Box
          borderRadius="8px"
          height="100%"
          position="relative"
          zIndex={1}
          sx={{
            width: "16rem",
            cursor: "default",
          }}
        ><img src={image ? image : "/evangeline.jpg"}
        alt={title} /> </Box>

          
      </Box>

      <Box
        p={2}
        pt={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Tooltip title={title} disableHoverListener={!isOverflowed1}>
          <Box
            mt={1}
            mb={1}
            height="fit-content"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              lineHeight: "1.1",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={(e) => {
              const src  = url.match(/^https?:/) ? url : '//' + url;
              window.open(src, "_blank", "noopener,noreferrer");
            }}
          >
            <Typography
              display="inline"
              sx={{
                color: "var(--primary----dark-blue)",
                fontSize: "1rem",
                lineHeight: "1.2",
              }}
              textTransform="capitalize"
              fontWeight="bold"
              ref={textElementRef1}
            >
              {title}
            </Typography>
          </Box>
        </Tooltip>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <LinkIcon
            sx={{
              position: "relative",
              marginTop: "0.3rem",
              marginRight: "0.5rem",
              zIndex: 2,
              bottom: "0.4rem",
              right: "0rem",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {conferences && conferences.length > 0
              ? conferences.slice(0, 3).map((conference, index) => (
                  <Tooltip
                    
                    title={conference.name}
                    disableHoverListener={isOverflowed2}
                  >
                    <Box
                      mt={0}
                      mb={1}
                      height="1.2rem"
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        lineHeight: "1",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={(e) => {
                        const src  = url.match(/^https?:/) ? conference.url : '//' + url;
                        window.open(
                          src,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--primary----blue)",
                          fontSize: "0.8rem",
                        }}
                        variant="body2"
                        component="p"
                        color="text"
                        ref={textElementRef2}
                      >
                        {conference.name}
                      </Typography>
                    </Box>
                  </Tooltip>
                ))
              : null}
          </Box>
          <Box
            sx={{
              display: conferences && conferences.length > 3? "block" : "none",
              backgroundColor: "var(--light-gray---1)",
              borderRadius: "50%",
              cursor: "pointer",
              marginTop: "0.3rem",
              marginBottom: "auto",
              textAlign: "center",
              justifyContent: "center",
              fontSize: "0.8rem",
              padding: "0.7rem",
            }}
          >
            <NoMaxWidthTooltip 
             title={
               conferences.slice(3).map(conference => <Box sx={{
                 cursor: "pointer",
                 color: "var(--white)",
               }} 
                onClick={(e) => {
                window.open(conference.url, "_blank", "noopener,noreferrer");
              }} >{conference.name}</Box>)
              
              }>
             <span>+{conferences.length - 3}</span>
            </NoMaxWidthTooltip>
            </Box>
        </Box>
        <Divider
          sx={{
            marginTop: "auto",
            color: "var(--light-gray---1)",
            backgroundColor: "var(--light-gray---1)",
            backgroundImage: "none",
          }}
        />
        <Box
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            color: "var(--primary----blue)",
            marginTop: "0.1rem",
          }}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <CalendarMonthIcon
            sx={{ marginRight: "0.2rem", width: " 1rem", marginTop: "-4px" }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              marginX: "0.2rem",
              fontWeight: 800,
            }}
          >
            {day()}
          </Typography>
          {localDay}
        </Box>
        <Box
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            color: "var(--primary----blue)",
            marginTop: "0.2rem",
          }}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <LocationOnIcon
            sx={{ marginRight: "0.2rem", width: " 1rem", marginTop: "-3px" }}
          />
          {location}
        </Box>
      </Box>
    </Card>
  );
}

export default SimpleBlogCard;
