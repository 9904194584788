/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import borders from "app/assets/theme/base/borders";
import colors from "app/assets/theme/base/colors";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "app/assets/theme/functions/pxToRem";
import linearGradient from "app/assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, info } = colors;

// types
type Types = any;

const radio: Types = {
  styleOverrides: {
    root: {
      color: "var(--primary----blue)",
      border: "none",
      
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
        color: "transparent",
        border: `1px solid var(--primary----blue)`,
        borderRadius: "50%",
      },
      '&$checked': {
        color: "var(--primary----blue)",
        backgroundColor: "var(--primary----blue)",
      },


      "&:after": {
        color: "var(--primary----blue)",
        //transition: "opacity 250ms ease-in-out",
        content: `""`,
        position: "absolute",
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: "var(--primary----blue)",
        //backgroundImage: linearGradient(info.main, info.main),
        opacity: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },

    },

    colorPrimary: {
      color: "var(--primary----blue)",
      '&$checked': {
        color: "var(--primary----blue)",
      },
      "&.Mui-checked": {
        color: "var(--primary----blue)",

        "& .MuiSvgIcon-root": {

        },

        "&:after": {
          opacity: 1,
        },
      },
    },

    colorSecondary: {
      color: "var(--primary----blue)",
      '&$checked': {
        color: "var(--primary----blue)",
      },
      "&.Mui-checked": {
        color: "var(--primary----blue)",

        "& .MuiSvgIcon-root": {
        },

        "&:after": {
          opacity: 1,
        },
      },
    },
  },
};

export default radio;
