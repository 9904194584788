import { TherapeuticArea } from "app/types/TherapeuticArea";
import { axios, url,  checkStatus, header_options } from "./ProviderHelpers";



const getAllTherapeuticData = async (): Promise<TherapeuticArea[]> => {
    try {
        const response = await axios.get(`${url}/therapeutic`,
            {
                ...header_options,
            }
        );
       
        return response.data.data as TherapeuticArea[];
    }
    catch (error:any) {
        await checkStatus(error.response);
    }
}

class TherapeuticProvider {
    public static getAllTherapeuticData = getAllTherapeuticData;
}

export default TherapeuticProvider;