import { Profile } from "app/types";
import { ActivitiesEvents } from "app/types/ActivitiesEvents";
import { Author } from "app/types/Author";



const ActivitiesEventsParser =  (data : Profile): ActivitiesEvents[] =>
{
    const pool = data ;
    //optain all pubmeds as scientific table
    return pool.events?.map((item: ActivitiesEvents) => {
                    return{
                        title: item.title,
                        url: item.url,
                        conferences: item.conferences,
                        location: item.location,
                        date: item.date,
                    }
                  }).sort((a:ActivitiesEvents, b:ActivitiesEvents) => {
                    return new Date(b.date).getTime() - new Date(a.date).getTime();
                  });
} 

export default ActivitiesEventsParser;