import { useRef, useEffect, useState, useMemo, ReactNode } from "react";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import { Box, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 PRO React TS Helper Functions
import gradientChartLine from "app/assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
import configs from "./configs";

// Material Dashboard 2 PRO React TS Base Styles
import colors from "app/assets/theme/base/colors";

const arrow_up = `
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 25, 69, 1);
`;

const arrow_down = `  
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 25, 69, 1);
`;

// Declaring props types for GradientLineChart
interface Props {
  icon?: {
    color?: string;
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  width?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      color: string;
      data: number[];
      yearSelected?: number;
      extradata?: {
        [key: string]: {
          likes: number;
          reposts: number;
          comments: number;
          newfollowers: number;
          engagement: number;
        };
      };
      backgroundColor: string;
    }[];
  };
  [key: string]: any;
}

function GradientLineChart({
  icon,
  title,
  description,
  height,
  chart,
  width
}: Props): JSX.Element {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const { data, options }: any = chartData;

  const externalTooltip = (context: any) => {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = `<table></table>`;
      document.body.appendChild(tooltipEl);
    }

    if (context.tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
  }
    
    const month = context.tooltip.title[0];
    const year = chart.datasets[0].yearSelected;
    const month_number = new Date(month + `1, 2022`).getMonth();
    const key = `${year}-${month_number}`;
    const extradata = chart.datasets[0].extradata[key];

    const tooltipModel = context.tooltip;

    const arrow_class =  arrow_up;

    if (tooltipModel.body) {
      let innerHtml = '<thead>';

      
      innerHtml += '<tr><th style="justify-content: flex-start;display: flex;font-size: 14px"><b >' + (Math.round(Number((extradata?.engagement||0)*10000))/100).toFixed(2) + '%</b></th></tr>';
      
      innerHtml += '</thead><tbody>';
      const lines = [
        `<div style="display: flex; align-items: center; font-size: 12px">
            <img src="/tooltip/like.svg"  />
            <b style="margin-left: 5px">${extradata?.likes || 0 } likes</b>
          </div>`,
        `<div style="display: flex; align-items: center; font-size: 12px">
            <img src="/tooltip/comment.svg"  />
            <b style="margin-left: 5px">${extradata?.comments || 0 } comments</b>
          </div>`,
        `<div style="display: flex; align-items: center; font-size: 12px">
            <img src="/tooltip/share.svg"  />
            <b style="margin-left: 5px">${extradata?.reposts || 0 } repost</b>
          </div>`,
        `<div style="display: flex; align-items: center; font-size: 12px">
            <img src="/tooltip/user.svg"  />
            <b style="margin-left: 5px">${extradata?.newfollowers || 0 } new followers</b>
          </div>`,
      ];
      lines.forEach((body) => {
        let style = "background: var(--primary----dark-blue)";
        style += "; border-width: 132px";
        style += "; border-radius: 2px";
        style += "; color: var(--white)";
        style += "; font-size: 12px";
        style += "; font-weight: 600";
        style += "; padding: 10px 8px";
        style +=
          "; box-shadow: 0px 1px 8px rgba(27, 36, 50, 0.1), 0px 4px 16px rgba(27, 36, 50, 0.1)";
        innerHtml += "<tr><td>" + body + "</td></tr>";
      });
      innerHtml += "</tbody>";

      innerHtml += `<div  style="${arrow_class} position: absolute; left:8px; top: -4px"></div>`;

      let tableRoot = tooltipEl.querySelector("table");
      tableRoot.innerHTML = innerHtml;
    }
    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + "px";
    tooltipEl.style.padding = "4px 8px";
    tooltipEl.style.fontWeight = "600";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.opacity = "1";
    tooltipEl.style.backgroundColor = "var(--primary----dark-blue)";
    tooltipEl.style.color = "var(--white)";
    tooltipEl.style.borderRadius = "2px";
    tooltipEl.style.transform = "translate(-9%, 8%)";
    tooltipEl.style.transition = "all .5s ease";
    tooltipEl.style.minWidth = "132px";
  };

  useEffect(() => {
    const chartDatasets = chart.datasets
      ? chart.datasets.map((dataset) => ({
          ...dataset,
          tension: 0,
          pointRadius: 3,
          pointColor: dataset.color,
          fillColor: dataset.color,
          strokeColor: dataset.color,
          pointStrokeColor: dataset.color,
          pointHighlightFill: dataset.color,
          pointHighlightStroke: dataset.color,
          pointBackgroundColor: dataset.color,
          borderWidth: 1,
          borderColor: dataset.color,
          fill: true,
          maxBarThickness: 3,
          backgroundColor: gradientChartLine(
            chartRef.current.children[0],
            dataset.backgroundColor
          ),
        }))
      : [];

    setChartData(configs(chart.labels || [], chartDatasets, externalTooltip));
  }, [chart]);

  const renderChart = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: height || "100%",
        width: width || "100%",
      }}
      pl={icon.component ? 1 : 2}
    >
      {title || description ? (
        <Box display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <Box
              width="4rem"
              height="4rem"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </Box>
          )}
          <Box mt={icon.component ? -2 : 0}>
            {title && <Typography variant="h6">{title}</Typography>}
            <Box mb={2}>
              <Typography component="div" variant="button" color="text">
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {useMemo(
        () => (
          <Box
            ref={chartRef}
            sx={{
              height,
              width,
              marginX: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Line data={data} options={options} />
          </Box>
        ),
        [chartData, height, width]
      )}
    </Box>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Declaring default props for GradientLineChart
GradientLineChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

export default GradientLineChart;
