import {Box} from "@mui/material";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

class NoData extends React.Component<
  WithTranslation & {
    active: boolean;
  },
  {}
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Box
        sx={{
          zIndex: 9999,
          backgroundColor: "none",
          opacity: 0.9,
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          top: 0,
          left: 0,
          color: "var(--gray---3)",
          paddingTop: '2.5rem',
          lineHeight: 1.5
        }}
      >
        <Box sx={{

        }}>
        <img src="./load-search.svg" width={'100%'}  alt="logo" />
        </Box>
        
        <Box
          sx={{
            textAlign: "center",
            color: "var(--gray---3)",
            maxWidth: "250px"
          }}
        >
          {this.props.t("loading.lookingFor")}
          <br />
          <b>{this.props.t("loading.keepAnEye")}</b>
        </Box>
      </Box>
    );
  }
}

export default withTranslation("common")(NoData);
